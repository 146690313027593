import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControl,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { Link, useLocation, useNavigate } from "react-router-dom";
import banner from "../../../../assets/images/banner/smart-b-banner-primary.png";
import bannerIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import AdvertisingBannner from "../../advertisingBanner";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import Loader from "src/components/Loader";
import { Config, fetchFromStorage } from "src/helpers/context";
import OurPartner from "../../ourPartners";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DefaultImg from "../../../../assets/images/smartb_default.png";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Datepicker } from "src/assets/images/eventcalendar/Datepicker.svg";
import { ReactComponent as Timepicker } from "src/assets/images/eventcalendar/Timepicker.svg";
import { ReactComponent as Unchecked } from "../../../../assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Checked } from "../../../../assets/images/icons/smartb-check.svg";
import { ReactComponent as Share } from "../../../../assets/images/icons/share.svg";
import { ReactComponent as Football } from "src/assets/images/SportIcon/blueSportIcon/AmericanFootball.svg";
import { ReactComponent as Baseball } from "src/assets/images/SportIcon/blueSportIcon/Baseball.svg";
import { ReactComponent as Basketball } from "src/assets/images/SportIcon/blueSportIcon/Basketball.svg";
import { ReactComponent as Boxing } from "src/assets/images/SportIcon/blueSportIcon/Boxing.svg";
import { ReactComponent as Cricket } from "src/assets/images/SportIcon/blueSportIcon/Cricket.svg";
import { ReactComponent as Golf } from "src/assets/images/SportIcon/blueSportIcon/Golf.svg";
import { ReactComponent as IceHockey } from "src/assets/images/SportIcon/blueSportIcon/IceHockey.svg";
import { ReactComponent as MMA } from "src/assets/images/SportIcon/blueSportIcon/MixedMartialArts.svg";
import { ReactComponent as Rugby } from "src/assets/images/SportIcon/blueSportIcon/RugbyLeague.svg";
import { ReactComponent as Soccer } from "src/assets/images/SportIcon/blueSportIcon/Soccer.svg";
import { ReactComponent as Tennis } from "src/assets/images/SportIcon/blueSportIcon/Tennis.svg";
import { ReactComponent as AR } from "src/assets/images/SportIcon/blueSportIcon/AustralianRules.svg";
import { ReactComponent as RU } from "src/assets/images/SportIcon/blueSportIcon/RugbyUnion.svg";
import { ReactComponent as TooltipArrow } from "src/assets/images/icons/tooltip_arrow.svg";
import { ReactComponent as TickIcon } from "src/assets/images/icons/tick.svg";
import { ToastContainer, toast } from "react-toastify";
import NotepadBig from "../../../../assets/images/notepadBig.png";
import Facebook from "../../../../assets/images/social/fb-link.svg";
import Linkedin from "../../../../assets/images/social/li-link.svg";
import Twitter_X from "../../../../assets/images/social/x-link.svg";
import Snapchat from "../../../../assets/images/social/sc-link.svg";
import Copy from "../../../../assets/images/social/copy-link.svg";
import Messenger from "../../../../assets/images/social/mess-link.svg";
import Wp from "../../../../assets/images/social/wp-link.svg";
import { ReactComponent as Search } from "../../../../assets/images/icons/search.svg";
import Message from "../../../../assets/images/social/messages.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import moment from "moment";
import CompHeader from "../../../../assets/images/comp-header.png";
import AdBannner from "../../AdBanner/AdBanner";
import NoDataComp from "src/views/component/UI/NoData";
import { Formik } from "formik";
import Select, { components } from "react-select";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import DateFnsUtils from "@date-io/date-fns";
// import { parseISO } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
  KeyboardTimePicker,
  TimePicker,
} from "@material-ui/pickers";
import "./publicComps.scss";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import DialogBox from "../../UI/dialogBox";
import { release } from "src/helpers/context/release";
import CompTable from "../../Home/HomeComponents/CompTable";
import JoinCompTable from "./JoinCompTable";
import { check } from "prettier";

const compData = [
  {
    id: 1,
    tournament: "Australian rules",
    league: "AFL",
    leagueName: "AFL Super League",
    startTime: "2023-12-19T06:45:00.000Z",
    SportId: 9,
  },
  {
    id: 2,
    tournament: "Rugby League",
    league: "NFL Divisions",
    leagueName: "NFL Super League",
    startTime: "2023-12-19T06:45:00.000Z",
    SportId: 12,
  },
];

const compsTypeData = [
  { value: null, label: "All" },
  { value: "my", label: "My" },
  { value: "joined", label: "Joined" },
  { value: "public", label: "Public" },
];

const tippingTypeRadio = [
  {
    label: "Winner tipping",
    value: "winning",
    icon: null,
    id: 1
  },
  {
    label: "Odds tipping",
    value: "odds",

    id: 2
  },
  {
    label: "Spread/Line tipping",
    value: "spread",
    id: 3
  }
];

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const PublicCompsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const redux_userData = useSelector(
    (state) => state?.reduxData?.SubscripitionData
  );
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [clickToolTipTeamId, setClickToolTipTeamId] = useState(null);
  const [sports, setSports] = useState([]);
  const [selectedSports, setSelectedSports] = useState(null);
  const [teamsAll, setTeamsAll] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [OrgAll, setOrgAll] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [isOrgSearch, setIsOrgSearch] = useState("");
  const [countOrg, setcountOrg] = useState(0);
  const [isOrgSelectOpen, setisOrgSelectOpen] = useState(false);
  const [searchOrg, setsearchOrg] = useState([]);
  const [searchOrgCount, setsearchOrgCount] = useState(0);
  const [SearchOrgpage, setSearchOrgpage] = useState(0);
  const [pageOrg, setpageOrg] = useState(0);
  const [selectedCompsType, setSelectedCompsType] = useState(0);
  const [isCompsLoading, setIsCompsLoading] = useState(false);
  const [allComps, setAllComps] = useState([]);
  const [compCount, setCompCount] = useState(0);
  const [compPage, setCompPage] = useState(1);
  const [compApiListCount, setCompApiListCount] = useState(0);
  const [compById, setCompsById] = useState({});
  const [isJoined, setIsJoined] = useState(false);
  const [joinOpen, setJoinOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedTipTypes, setSelectedTipTypes] = useState(["winning", "odds", "spread"]);

  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const rowperPage = 10;

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const handleJoin = async (e, item, id) => {
    if (handleRestrictedUser()) {
      setSelectedItem(item);
      handleDeleteModal();
      setSelectedId(id);
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };
  const handleDeleteModal = () => {
    setJoinOpen(true);
  };
  const handleJoinClose = () => {
    setJoinOpen(false);
    setSelectedItem("");
    setSelectedId("");
  };

  useEffect(() => {
    if (code) {
      fetchCompsByCode();
    } else {
      fetchPublicComp(0, 0, null, 1, "", [], selectedTipTypes);
    }

    fetchSportData();
  }, []);

  useEffect(() => {
    // Load the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "708883073941381",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v18.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const fetchPublicComp = async (
    sports,
    tournament,
    type,
    page,
    search,
    allCompsData,
    tipType,
    signal,
  ) => {
    setCompPage(page);
    try {
      setIsCompsLoading(true);
      const {
        status,
        data,
      } = await axiosInstance.get(
        `tipping/getAll?SportId=${sports
          ? sports
          : Config.release === "IN"
            ? release[Config.release]?.sportId?.toString()
            : ""
        }&tournamentId=${tournament ? tournament : ""
        }&privacy=public&limit=${rowperPage}&page=${page}&search=${search}&tippingType=${tipType?.toString()}`,
        { signal }
      );
      if (status === 200) {
        setAllComps([...allCompsData, ...data?.result]);
        setCompCount(data?.count);
        setCompApiListCount(data?.result?.length)
        setIsCompsLoading(false);
      } else {
        setIsCompsLoading(false);
      }
    } catch (err) {
      setIsCompsLoading(false);
      console.log(err);
    }
  };

  const fetchCompsByCode = async () => {
    try {
      setIsCompsLoading(true);
      const { status, data } = await axiosInstance.get(
        `tipping/getByCode/${code}`
      );
      if (status === 200) {
        // setAllComps(data?.result);
        // setCompCount(data?.count);
        setCompsById(data?.result);
        setIsJoined(data?.isJoined);
        setIsCompsLoading(false);
      } else {
        setIsCompsLoading(false);
      }
    } catch (err) {
      setIsCompsLoading(false);
      console.log(err);
    }
  };
  const handleAddComps = async (e, item) => {
    e.stopPropagation();
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    const tournamentSlug = item?.Tournament?.name
      ? item?.Tournament?.name
        .trim()
        .toLowerCase()
        .replaceAll(" ", "-")
      : "tournament";
    const sportSlug = item?.Sport?.sportName
      ? item?.Sport?.sportName
        .trim()
        .toLowerCase()
        .replaceAll(" ", "-")
      : "sports";
    if (isLogin) {
      try {
        const { status, data } = await axiosInstance.put(
          `/tipping/addLadder/${item?.code}`
        );
        if (status === 200) {
          toast.success(data?.message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
          setTimeout(() => {
            navigate(
              `/tipscompetition/my-comps/${sportSlug}/${tournamentSlug}/${item?.SportId}/${item?.Tournament?.id}/${item?.id}`
            );
          }, 2000);
        } else {
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem(
        "prev_authroute",
        window.location.pathname + window.location.search
      );
    }
  };

  const fetchDayName = (date) => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  const fetchSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport/?sportTypeId=${2}`
      );
      if (status === 200) {
        const FilteredSportData = data?.result?.filter(
          (item) => item?.id == 12 || item?.id == 9 || item?.id == 4
        );
        const releaseFilterSportData = FilteredSportData?.filter((item) =>
          release[Config.release]?.sportId?.includes(item?.id)
        );
        var sportsdata = releaseFilterSportData?.map((s) => {
          return { ...s, label: s?.sportName, value: s?.id };
        });
        // var sportsdata = data?.result.map((s) => {
        //   return { ...s, label: s?.sportName, value: s?.id };
        // });
        var sdata = _.orderBy(sportsdata, ["label"], ["asc"]);
        var mergedArray = _.concat(
          [{ label: "All Sports", value: null, isAll: true }],
          sdata
        );
        setSports(mergedArray);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchOrgData = async (page, sID, OrgAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/tournament?SportId=${sID}&offset=${page}&limit=${20}`
      );
      if (status === 200) {
        setOrgApiCount(OrgApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          newdata.push({
            label: item?.name,
            value: item?.id,
          });
        });
        let filterData = _.unionBy(OrgAll, newdata);
        // let allteamdatas = filterData?.unshift({
        //   label: "All",
        //   value: 0,
        // });
        var mergedArray = _.concat([{ label: "All", value: 0 }], filterData);
        setOrgAll(
          _.uniqBy(mergedArray, function (e) {
            return e.value;
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOrgInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `public/tournament?SportId=${sid}&limit=${20}&offset=${page}&search=${value}`
      )
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchOrgCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.name,
              value: item?.id,
            });
          });
          const mergeData = _.unionBy(searchOrg, newdata);
          const filterData = _.uniqBy(mergeData, function (e) {
            return e.value;
          });
          setsearchOrg(filterData);
          setIsOrgSearch(value);
        }
      });
  };

  const handleOnScrollBottomOrg = () => {
    if (
      isOrgSearch !== "" &&
      searchOrgCount !== Math.ceil(SearchOrgpage / 20)
    ) {
      handleOrgInputChange(SearchOrgpage + 20, isOrgSearch, selectedSports);

      setSearchOrgpage(SearchOrgpage + 20);
    } else {
      if (countOrg !== 0 && countOrg !== Math.ceil(pageOrg / 20 + 1)) {
        fetchOrgData(pageOrg + 20, selectedSports, OrgAll);
        setpageOrg(pageOrg + 20);
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  // <Box style={{ display: "flex", alignItems: "center" }}>
  //   {sportIcon(item?.SportId)}

  //   <p>
  //     <span className="title-txt">{sportName(item?.SportId)}</span>
  //   </p>
  // </Box>;

  const handleTooltipContentClick = (event, teamId, objindex) => {
    event.stopPropagation();
    setTooltipOpen(!tooltipOpen);
    setHoveredIndex(objindex);
    setClickToolTipTeamId(teamId);
  };
  const handleCopy = () => {
    toast.success("Link Copied Successfully", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    });
  };

  const shareOnMessenger = (link) => {
    if (window.FB) {
      window.FB.ui({
        method: "send", // share in facebook post and send in facebook messenger
        link: link, // link in facebook messenger and href in facebook post
      });
    } else {
      console.error("Facebook SDK not loaded.");
    }
  };

  const handleOutsideClick = (item, teamId, index) => {
    if (hoveredIndex === index && clickToolTipTeamId === teamId) {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    }
  };
  const handleOpenTooltip = (item, teamId, index) => {
    const link =
      Config.baseURL.replace("/api", "") +
      `tipscompetition/private-comps?code=${item?.code}`;
    return (
      <Box
        className="comp-custom-tooltip right-sec"
        onClick={(e) => handleTooltipContentClick(e, teamId, index)}
      >
        {/* <Box> */}
        <Button className="share-btn" endIcon={<Share />}>
          Share
        </Button>
        {/* </Box> */}
        {hoveredIndex === index && clickToolTipTeamId === teamId ? (
          tooltipOpen && (
            <Box className="tooltip">
              <Box style={{ position: "relative" }}>
                <TooltipArrow className="tooltip-arrow" />
              </Box>
              <Box className="share-option">
                <Box className="social-option-box">
                  <Box className="option">
                    <CopyToClipboard text={link} onCopy={handleCopy}>
                      <Box className="cursor-pointer">
                        <img src={Copy} alt="Copy" />
                        <Typography className="option-name">
                          Copy Link
                        </Typography>
                      </Box>
                    </CopyToClipboard>
                  </Box>
                  <Box className="option">
                    <WhatsappShareButton url={link}>
                      <img src={Wp} alt="Whatsapp" />
                    </WhatsappShareButton>
                    <Typography className="option-name">Whatsapp</Typography>
                  </Box>
                  <Box className="option">
                    <FacebookShareButton url={link}>
                      <img src={Facebook} alt="Facebook" />
                    </FacebookShareButton>
                    <Typography className="option-name">Facebook</Typography>
                  </Box>
                  <Box className="option cursor-pointer">
                    <img
                      src={Messenger}
                      alt="Messenger"
                      className="social-icon"
                      onClick={() => shareOnMessenger(link)}
                    />
                    <Typography className="option-name">Messenger</Typography>
                  </Box>
                  <Box className="option">
                    <TwitterShareButton url={link}>
                      <img src={Twitter_X} alt="Twitter_X" />
                    </TwitterShareButton>
                    <Typography className="option-name">Twitter</Typography>
                  </Box>
                  <Box className="option">
                    <LinkedinShareButton url={link}>
                      <img src={Linkedin} alt="Linkedin" />
                    </LinkedinShareButton>
                    <Typography className="option-name">Linkedin</Typography>
                  </Box>
                  {/* <Box className="option">
                    <img src={Snapchat} alt="Snapchat" />
                    <Typography className="option-name">Snapchat</Typography>
                  </Box>
                  <Box className="option">
                    {" "}
                    <img src={Message} alt="Message" />
                    <Typography className="option-name">Message</Typography>
                  </Box> */}
                </Box>
              </Box>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const sportIcon = (sportId) => {
    if (sportId === 15) {
      return <Football className="sport-icon" />;
    } else if (sportId === 9) {
      return <AR className="sport-icon" />;
    } else if (sportId === 11) {
      return <Baseball className="sport-icon" />;
    } else if (sportId === 10) {
      return <Basketball className="sport-icon" />;
    } else if (sportId === 6) {
      return <Boxing className="sport-icon" />;
    } else if (sportId === 4) {
      return <Cricket className="sport-icon" />;
    } else if (sportId === 16) {
      return <Golf className="sport-icon" />;
    } else if (sportId === 17) {
      return <IceHockey className="storke-svg sport-icon" />;
    } else if (sportId === 5) {
      return <MMA className="storke-svg sport-icon" />;
    } else if (sportId === 12) {
      return <Rugby className="sport-icon" />;
    } else if (sportId === 13) {
      return <RU className="storke-svg sport-icon" />;
    } else if (sportId === 8) {
      return <Soccer className="storke-svg sport-icon" />;
    } else if (sportId === 7) {
      return <Tennis />;
    }
  };

  const sportName = (sportId) => {
    if (sportId === 15) {
      return "American Football";
    } else if (sportId === 9) {
      return "Australian Rules";
    } else if (sportId === 11) {
      return "Baseball";
    } else if (sportId === 10) {
      return "Basketball";
    } else if (sportId === 6) {
      return "Boxing";
    } else if (sportId === 4) {
      return "Cricket";
    } else if (sportId === 16) {
      return "Golf";
    } else if (sportId === 17) {
      return "Ice Hockey";
    } else if (sportId === 5) {
      return "Mixed Martial Arts";
    } else if (sportId === 12) {
      return "Rugby League";
    } else if (sportId === 13) {
      return "Rugby Union";
    } else if (sportId === 8) {
      return "Soccer";
    } else if (sportId === 7) {
      return "Tennis";
    }
  };
  const handlePaginationClick = (event, page) => {
    fetchPublicComp(
      selectedSports,
      selectedOrg,
      selectedCompsType,
      Number(page),
      searchInput,
      allComps,
      selectedTipTypes
    );
  };
  const pageNumbers = [];

  if (compCount > 0) {
    for (let i = 1; i <= Math.ceil(compCount / rowperPage); i++) {
      pageNumbers.push(i);
    }
  }
  const handleCalculateLadder = async (compsId) => {
    try {
      const { status, data } = await axiosInstance.put(
        `/tipping/calculate/ladder/${compsId}`
      );
      if (status === 200) {
      }
    } catch (err) {
      console.log(err);
    }
  };
  const navigateToComp = (
    sports,
    leagueName,
    SportId,
    tournamentId,
    compsId
  ) => {
    if (location.pathname.includes("rankings")) {
      navigate(
        `/tipscompetition/public/rankings/${sports}/${leagueName}/${SportId}/${tournamentId}/${compsId}`
      );
      // handleCalculateLadder(compsId);
    } else {
      navigate(
        `/tipscompetition/public/tips/${sports}/${leagueName}/${SportId}/${tournamentId}/${compsId}`
      );
    }
  };

  const fetchDayandTime = (item) => {
    if (item?.cutOffTime) {
      const dayWithTime = moment
        .utc(`${item?.cutOffWeek} ${item?.cutOffTime}`, "dddd HH:mm:ss")
        .tz(timezone)
        .format("h:mm A");

      return item?.cutOffWeek + " | " + dayWithTime;
    }
  };

  const hadleInputChange = (value) => {
    setSearchInput(value);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    fetchPublicComp(
      selectedSports,
      selectedOrg,
      selectedCompsType,
      1,
      searchInput,
      [],
      selectedTipTypes,
      signal
    );

    return () => {
      abortController.abort();
    };
  }, [searchInput]);

  const handleMoreData = () => {

    fetchPublicComp(
      selectedSports,
      selectedOrg,
      selectedCompsType,
      compPage + 1,
      searchInput,
      allComps,
      selectedTipTypes
    );
  };

  const handleLessData = () => {

    setCompPage(compPage - 1);
    if (allComps?.length % 10 === 0) {
      setAllComps(prevArray => prevArray.slice(0, -10));
    } else {
      setAllComps(prevArray => prevArray.slice(0, -compApiListCount));
    }
  };

  const handleTipTypeChange = (e) => {

    if (e.target.checked) {
      const checked = [...selectedTipTypes, e.target.value];

      setSelectedTipTypes(checked)
      fetchPublicComp(
        selectedSports,
        selectedOrg,
        selectedCompsType,
        1,
        searchInput,
        [],
        checked,
      );
    } else {
      const unChecked = selectedTipTypes?.length > 1 ? selectedTipTypes?.filter((ele) => ele != e.target.value) : selectedTipTypes
      setSelectedTipTypes(unChecked)
      fetchPublicComp(
        selectedSports,
        selectedOrg,
        selectedCompsType,
        1,
        searchInput,
        [],
        unChecked,
      );
    }
  }

  console.log("Tipping", selectedTipTypes)

  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", Config?.release == "IN" ? bannerIndia : banner)}
        </Box>
        <Box className="public-comp-wrap">
          <Box className="my-comp-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  HOME
                </Link>

                <Link
                  underline="hover"
                  color="inherit"
                  to={location?.pathname + location?.search}
                  className="cursor-default"
                >
                  Tipping Competition
                </Link>
                {/* {code && (
                  <Link underline="hover" color="inherit" to="">
                    My Comps
                  </Link>
                )} */}
                <Typography>
                  {location.pathname.includes("rankings")
                    ? "Rankings"
                    : "Join Comps"}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">
              {location.pathname.includes("rankings")
                ? "Rankings"
                : "Join Comps"}
            </Typography>
          </Box>
          {!code && (
            <Box className="tab-search-section">
              <Box className="search-filter-wrap">
                <TextField
                  placeholder="Search (by comp name)"
                  variant="outlined"
                  type="text"
                  name="search"
                  className="search_input"
                  onChange={(e) => {
                    hadleInputChange(e?.target?.value);
                  }}
                  value={searchInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box className="filter-section">
                <Select
                  className="React sort-select"
                  value={sports?.find((item) => {
                    return item?.value === selectedSports;
                  })}
                  onChange={(e) => {
                    setSelectedSports(e?.value == 0 ? "" : e?.value);
                    setCurrentPage(0);
                    setSelectedOrg(null);
                    setOrgAll([]);
                    fetchPublicComp(
                      e?.value,
                      null,
                      selectedCompsType,
                      1,
                      searchInput,
                      [],
                      selectedTipTypes
                    );
                    if (e?.value) {
                      fetchOrgData(0, e?.value, []);
                    }
                  }}
                  // onMenuScrollToBottom={e => handleOnScrollBottomSports(e)}
                  options={sports}
                  classNamePrefix="select"
                  placeholder="Select sport"
                  // isSearchable={false}
                  // onFocus={() => setTournamentSelect(true)}
                  // onBlur={() => setTournamentSelect(false)}
                  components={{ DropdownIndicator }}
                />
                <Select
                  className={
                    selectedSports
                      ? `React sort-select`
                      : `React sort-select disable-state`
                  }
                  onMenuScrollToBottom={(e) => handleOnScrollBottomOrg(e)}
                  onInputChange={(e) =>
                    handleOrgInputChange(0, e, selectedSports)
                  }
                  onChange={(e) => {
                    setSelectedOrg(e?.value);
                    setOrgApiCount(0);
                    setpageOrg(0);
                    setisOrgSelectOpen(false);
                    setCurrentPage(0);
                    fetchPublicComp(
                      selectedSports,
                      e?.value,
                      selectedCompsType,
                      1,
                      searchInput,
                      [], selectedTipTypes
                    );
                  }}
                  onFocus={() => setisOrgSelectOpen(true)}
                  onBlur={() => setisOrgSelectOpen(false)}
                  value={
                    selectedOrg &&
                    (isOrgSearch
                      ? searchOrg?.find((item) => {
                        return item?.value == selectedOrg;
                      })
                      : OrgAll?.find((item) => {
                        return item?.value == selectedOrg;
                      }))
                  }
                  options={isOrgSearch ? searchOrg : OrgAll}
                  classNamePrefix="select"
                  placeholder="Select league"
                  isDisabled={selectedSports ? false : true}
                  components={{ DropdownIndicator }}
                />
                {/* <Select
                  className="React sort-select"
                  value={compsTypeData?.find((item) => {
                    return item?.value === selectedCompsType;
                  })}
                  onChange={(e) => {
                    setSelectedCompsType(e?.value);
                    fetchPublicComp(selectedSports, selectedOrg, e?.value, 1);
                  }}
                  options={compsTypeData}
                  classNamePrefix="select"
                  placeholder="All"
                  components={{ DropdownIndicator }}
                /> */}
              </Box>
              <Box className="tipping-type-checkbox">
                {tippingTypeRadio?.map((obj, i) => (
                  <FormControl key={obj?.id}>
                    <label>
                      <Checkbox
                        icon={<Unchecked />}
                        checkedIcon={<Checked />}
                        name="obj"
                        value={obj?.value}
                        checked={selectedTipTypes?.includes(obj?.value)}
                        onChange={(e) =>
                          handleTipTypeChange(e)
                        }
                      />
                      {obj?.label}
                    </label>
                  </FormControl>
                ))}
              </Box>
            </Box>
          )}

          {isCompsLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : allComps?.length > 0 ? (
            <JoinCompTable
              tableData={allComps}
              isLoading={isCompsLoading}
              compCount={compCount}
              compPage={compPage}
              handleMoreData={handleMoreData}
              handleLessData={handleLessData}
            />

            // <Box className="page-deatils-wrap">
            //   {allComps?.map((item, index) => {
            //     const aboutLines = item?.about?.split("\n");
            //     const tournamentSlug = item?.Tournament?.name
            //       ? item?.Tournament?.name
            //         ?.trim()
            //         ?.toLowerCase()
            //         ?.replaceAll(" ", "-")
            //       : "tournament";
            //     const sportSlug = item?.Sport?.sportName
            //       ? item?.Sport?.sportName
            //         ?.trim()
            //         ?.toLowerCase()
            //         ?.replaceAll(" ", "-")
            //       : "sports";
            //     return (
            //       <Box
            //         className={
            //           item?.isJoined || redux_userData?.id == item?.UserId
            //             ? "my-comp-sec cursor-pointer"
            //             : "my-comp-sec cursor-text"
            //         }
            //       // onClick={() =>
            //       //   navigateToComp(
            //       //     sportSlug,
            //       //     tournamentSlug,
            //       //     item?.SportId,
            //       //     item?.Tournament?.id,
            //       //     item?.id
            //       //   )
            //       // }
            //       >
            //         <Box
            //           className="top-sec"
            //           onClick={() => {
            //             return item?.isJoined ||
            //               redux_userData?.id == item?.UserId
            //               ? navigateToComp(
            //                 sportSlug,
            //                 tournamentSlug,
            //                 item?.SportId,
            //                 item?.Tournament?.id,
            //                 item?.id
            //               )
            //               : "";
            //           }}
            //         >
            //           <Typography className="tournament-txt">
            //             {item?.Sport?.sportName}
            //           </Typography>
            //           <Box className="chip-sec">
            //             <Typography className="chip-txt">
            //               {item?.Tournament?.name}
            //             </Typography>
            //           </Box>
            //         </Box>
            //         <Box className="bottom-sec">
            //           <Box
            //             className="left-sec"
            //             onClick={() => {
            //               return item?.isJoined ||
            //                 redux_userData?.id == item?.UserId
            //                 ? navigateToComp(
            //                   sportSlug,
            //                   tournamentSlug,
            //                   item?.SportId,
            //                   item?.Tournament?.id,
            //                   item?.id
            //                 )
            //                 : "";
            //             }}
            //           >
            //             <Box className="left-sec-image-des">
            //               <Box>
            //                 <Box className="profile-img-wrap">
            //                   <img
            //                     src={
            //                       item?.Media?.filePath
            //                         ? Config.countryMediaURL +
            //                         item?.Media?.filePath
            //                         : DefaultImg
            //                     }
            //                     alt="profile"
            //                   />
            //                 </Box>
            //               </Box>
            //               <Box
            //                 className="name-time"
            //                 style={{ marginLeft: "5px" }}
            //               >
            //                 <Box className="my-comp-icon-name-wrap">
            //                   <Box className="sport-icon-sec">
            //                     {sportIcon(item?.SportId)}
            //                   </Box>
            //                   <Typography className="sport-name">
            //                     {/* {sportName(item?.SportId)} */}
            //                     {item?.competitionName}
            //                   </Typography>
            //                 </Box>
            //                 {item?.about && (
            //                   <Typography className="about-comp-dec">
            //                     <span>About This Comp: </span>
            //                     {/* {item?.about} */}
            //                     {aboutLines?.map((line, index) => (
            //                       <p key={index} className="about-line">
            //                         {line} <br />
            //                       </p>
            //                     ))}
            //                   </Typography>
            //                 )}
            //                 <Typography className="final-txt">
            //                   {item?.SportId == 4 ? "Day" : "Round"} {item?.startingRound}{" "}
            //                   {item?.tipDeadline == "game"
            //                     ? " Game Based"
            //                     : `closes at:`}
            //                   {item?.tipDeadline == "round" && (
            //                     <span>
            //                       {" "}
            //                       <Box className="comp-header-details">
            //                         <Typography className="day-time-details">
            //                           <span>{fetchDayandTime(item)}</span>
            //                         </Typography>
            //                       </Box>
            //                     </span>
            //                   )}
            //                 </Typography>
            //                 {item?.User?.firstName && (
            //                   <Typography className="final-txt">
            //                     Created By:{" "}
            //                     <span className="day-time-details">
            //                       {item?.User?.firstName +
            //                         " " +
            //                         item?.User?.lastName}{" "}
            //                     </span>
            //                   </Typography>
            //                 )}
            //               </Box>
            //             </Box>
            //           </Box>

            //           <Box className="right-btn-wrap">
            //             <Button
            //               className={
            //                 item?.isJoined || redux_userData?.id == item?.UserId
            //                   ? `join-btn disable-join-btn`
            //                   : "join-btn"
            //               }
            //               onClick={(e) => {
            //                 handleJoin(e, item, item?.id);
            //                 // handleAddComps(e, item);
            //               }}
            //               startIcon={
            //                 item?.isJoined ||
            //                   redux_userData?.id == item?.UserId ? (
            //                   <TickIcon />
            //                 ) : (
            //                   <></>
            //                 )
            //               }
            //               disabled={
            //                 item?.isJoined || redux_userData?.id == item?.UserId
            //               }
            //             >
            //               {item?.isJoined || redux_userData?.id == item?.UserId
            //                 ? "Joined"
            //                 : "Join"}
            //             </Button>
            //             {item?.isShare ? (
            //               <ClickAwayListener
            //                 onClickAway={() =>
            //                   handleOutsideClick(item, item?.id, index)
            //                 }
            //               >
            //                 <Box className="share-tooltip-icon">
            //                   {handleOpenTooltip(item, item?.id, index)}
            //                 </Box>
            //               </ClickAwayListener>
            //             ) : (
            //               <></>
            //             )}
            //           </Box>
            //         </Box>
            //       </Box>
            //     );
            //   })}
            //   {!isCompsLoading && allComps?.length > 0 ? (
            //     <Box className="pagination-section">
            //       <Pagination
            //         disabled={compCount / rowperPage > 1 ? false : true}
            //         page={compPage}
            //         onChange={(e, value) => handlePaginationClick(e, value)}
            //         count={pageNumbers[pageNumbers?.length - 1]}
            //         siblingCount={2}
            //         boundaryCount={1}
            //         size="small"
            //       />
            //     </Box>
            //   ) : (
            //     <></>
            //   )}
            // </Box>
          ) : (
            <NoDataComp />
          )}
          <OurPartner />
        </Box>
        <DialogBox
          open={joinOpen}
          onClose={handleJoinClose}
          handleClose={handleJoinClose}
          title={false}
          content={
            <>
              <Box className="delete-dialogbox">
                <Typography className="delete-title">
                  Are you sure you want to Join?
                </Typography>
                <Box className="delete-btn">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="btn-cancel blackbook-delete"
                    onClick={(e) => {
                      handleAddComps(e, selectedItem);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="btn-save"
                    onClick={() => handleJoinClose()}
                  >
                    No
                  </Button>
                </Box>
              </Box>
            </>
          }
          closeIcon={false}
        />
      </Box>
    </>
  );
};

export default PublicCompsList;
