import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { IntlContext } from "src/App";
import { Config } from "../../../../../helpers/context/config";
import DefaultTeam from "src/assets/images/defaultTeam.png";
import { useParams } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import Loader from "src/components/Loader";
import NoDataComp from "src/views/component/UI/NoData";

const SportinfoData = [
  {
    id: 1,
    name: "Event",
    nameInfo: "Brisbane Lions VS Melbourne"
  },
  {
    id: 2,
    name: "Date/Time",
    nameInfo: "June 23 @ 07:50 PM - 10:00 PM"
  },
  {
    id: 3,
    name: "Venue",
    nameInfo: "Venue - Sunshine Coast Stadium (Australia)"
  }
];

const CricketinfoData = [
  {
    id: 1,
    name: "Event",
    nameInfo: "West Indies v England Twenty20 Internationals"
  },
  {
    id: 2,
    name: "This Match",
    nameInfo: "1st T20I"
  },
  {
    id: 3,
    name: "Starts (venue)",
    nameInfo: "18:00, 12 December 2023"
  },
  {
    id: 4,
    name: "Starts (GMT)",
    nameInfo: "22:00, 12 December 2023"
  },
  {
    id: 5,
    name: "Toss",
    nameInfo: "West Indies won the toss and chose to field"
  },
  {
    id: 6,
    name: "Country",
    nameInfo: "Barbados"
  },
  {
    id: 7,
    name: "City",
    nameInfo: "Bridgetown"
  },
  {
    id: 8,
    name: "Venue",
    nameInfo: "Kensington Oval"
  },
  {
    id: 9,
    name: "Umpire 1",
    nameInfo: "Nigel Duguid"
  },
  {
    id: 7,
    name: "Umpire 2",
    nameInfo: "Patrick Gustard"
  },
  {
    id: 7,
    name: "TV Umpire",
    nameInfo: "Gregory Brathwaite"
  },
  {
    id: 7,
    name: "Referee",
    nameInfo: "Richard Richardson"
  }
];

const InfoFixture = ({ fixtureInfoData, singleMatchData }) => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [matchInfo, setMatchInfo] = useState([]);
  const [isInfoLoading, setIsInfoLoading] = useState(true);
  useEffect(() => {
    fetchMatchInfo();
  }, []);

  const fetchMatchInfo = async () => {
    setIsInfoLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/rl/info/${params?.id}`
      );
      if (status === 200) {
        setIsInfoLoading(false);
        setMatchInfo(data?.result);
      } else {
        setIsInfoLoading(false);
      }
    } catch (err) {
      setIsInfoLoading(false);
    }
  };
  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={DefaultTeam} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={DefaultTeam} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={DefaultTeam} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={DefaultTeam} alt="Odds Icon" />;
      }
    }
  };

  const fetchRowData = (label, value) => {
    return (
      <Box className="d-flex match-info-wrap">
        <Typography className="match-info-text bold w-15">{label}</Typography>
        <span className="match-info-text">-</span>
        <Typography className="match-info-text light w-60">{value}</Typography>
      </Box>
    );
  };
  const fetchTossInfo = matchInfo => {
    const winTeam = matchInfo?.ScoreBoard?.TPa;
    const chooseType = matchInfo?.ScoreBoard?.TCho;
    const combinedValue = `${winTeam}_${chooseType}`;
    switch (combinedValue) {
      case "1_1":
        return `${matchInfo?.homeTeam?.name} won the toss and chose to bat`;

      case "1_2":
        return `${matchInfo?.homeTeam?.name} won the toss and chose to field`;

      case "2_1":
        return `${matchInfo?.awayTeam?.name} won the toss and chose to bat`;
      case "2_2":
        return `${matchInfo?.awayTeam?.name} won the toss and chose to field`;
      default:
        break;
    }
  };
  const infoData =
    Number(params?.SportId) === 4 ? CricketinfoData : SportinfoData;
  let UmpireData = matchInfo && matchInfo?.CricketEventReferees;
  let RefreeData = UmpireData?.filter(obj => obj?.Kn == 0)?.[0]?.CricketReferee
    ?.Nm;
  let TVUpmpireData = UmpireData?.filter(obj => obj?.Kn == 5)?.[0]
    ?.CricketReferee?.Nm;
  const Upmpire1 = UmpireData?.[0]?.CricketReferee?.Nm;
  const Upmpire2 = UmpireData?.[1]?.CricketReferee?.Nm;
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return (
    <>
      {isInfoLoading ? (
        <div className="allsport-loader-center">
          <Loader />
        </div>
      ) : (
        <>
          {Object.keys(matchInfo)?.length > 0 ? (
            <>
              <Box className="fixture-wrap">
                <Typography variant="h4" className="fixture-heading">
                  {localesData?.FIXTURE_INFO?.MATCH_INFO}
                </Typography>
                {fetchRowData(
                  localesData?.SPORT_INFO?.EVENT,
                  matchInfo?.eventName
                )}

                {fetchRowData(
                  "Date/Time",
                  matchInfo?.startTime
                    ? moment(matchInfo?.startTime)
                        .tz(timezone)
                        .format("MMMM DD @ hh:mm A")
                    : ""
                )}

                {fetchRowData(
                  localesData?.SPORT_INFO?.COUNTRY,
                  matchInfo?.RLStadium?.Country?.country
                    ? matchInfo?.RLStadium?.Country?.country
                    : ""
                )}
                {fetchRowData(
                  localesData?.SPORT_INFO?.CITY,
                  matchInfo?.RLStadium?.City?.cityName
                    ? matchInfo?.RLStadium?.City?.cityName
                    : ""
                )}
                {fetchRowData(
                  localesData?.SPORT_INFO?.VENUE,
                  matchInfo?.RLStadium?.name ? matchInfo?.RLStadium?.name : ""
                )}

                {/* <Box className="d-flex match-info-wrap">
          <Typography className="match-info-text bold w-15">Event</Typography>
          <span className="match-info-text">-</span>
          <Typography className="match-info-text light w-60">
            {fixtureInfoData?.eventName}
          </Typography>
        </Box>
        <Box className="d-flex match-info-wrap">
          <Typography className="match-info-text bold w-15">
            Date/Time
          </Typography>
          <span className="match-info-text">-</span>
          <Typography className="match-info-text light w-60">
            <span className="medium">June 23</span> @ <br /> 07:50 PM - 10:00 PM
          </Typography>
        </Box>
        <Box className="d-flex match-info-wrap">
          <Typography className="match-info-text bold w-15">Venue</Typography>
          <span className="match-info-text">-</span>
          <Typography className="match-info-text light w-60">
            Venue - Sunshine Coast Stadium (Australia)
          </Typography>
        </Box> */}
              </Box>
              {/* {Number(params?.SportId) !== 4 && (
                <Box className="fixture-wrap">
                  <Typography variant="h4" className="fixture-heading">
                    {localesData?.FIXTURE_INFO?.WINNING_PROBABILITY}
                  </Typography>
                  <Box className="winning-probability-wrap">
                    <Box className="d-flex align-center team-wrap">
                      <span className="team-img-wrap">
                        {fetchTeamlogo(fixtureInfoData, "hometeam")}
                      </span>
                      <span className="team-name medium">
                        {fixtureInfoData?.homeTeam?.name
                          ? fixtureInfoData?.homeTeam?.name
                          : ""}
                      </span>
                    </Box>
                    <Box>
                      <Box className="d-flex align-center win-value">
                        <span
                          style={{
                            width: "70%",
                            color: "#003764"
                          }}
                          className="value-text"
                        >
                          70%
                        </span>
                        <span
                          style={{
                            width: "30%",
                            color: "#5D5D5D"
                          }}
                          className="value-text"
                        >
                          30%
                        </span>
                      </Box>
                      <Box className="win-progress d-flex align-center">
                        <span
                          style={{
                            width: "70%",
                            backgroundColor: "#003764",
                            borderRadius: "4px"
                          }}
                        ></span>
                        <span
                          style={{
                            width: "30%"
                          }}
                        ></span>
                      </Box>
                    </Box>
                    <Box className="d-flex align-center team-wrap">
                      <span className="team-img-wrap">
                        {fetchTeamlogo(fixtureInfoData, "awayteam")}
                      </span>
                      <span className="team-name medium">
                        {fixtureInfoData?.awayTeam?.name
                          ? fixtureInfoData?.awayTeam?.name
                          : ""}
                      </span>
                    </Box>
                  </Box>
                </Box>
              )} */}
            </>
          ) : (
            <NoDataComp />
          )}
        </>
      )}
    </>
  );
};

export default InfoFixture;
