import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Tooltip } from "@material-ui/core";
import { ReactComponent as Watch } from "src/assets/images/watch.svg";
import { NavLink, useNavigate } from "react-router-dom";
import DefaultImg from "../../../../assets/images/smartb_default.png";
import Loader from "src/components/Loader";
import moment from "moment-timezone";
import he from "he";
import { Config, fetchFromStorage } from "src/helpers/context";
import { ReactComponent as Delete } from "src/assets/images/icons/delete.svg";
import { ReactComponent as ChatIcon } from "src/assets/images/icons/chat-icon.svg";
import { setApiMessage } from "src/helpers/commonFunctions";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch } from "react-redux";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LeftSaveArticle = ({
  categoryList,
  savedArticleData,
  isArticleLoading,
  fetchSavedArticle,
  categoryCount,
  currentPage
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [articleData, setArticleData] = useState(savedArticleData);
  console.log("savedArticleData123", savedArticleData);
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    setArticleData(savedArticleData);
  }, [savedArticleData]);

  const fetchNewsSubtitle = data => {
    const parseHTMLContent = body => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(body, "text/html");
      const firstPTag = doc.querySelector("p");
      return firstPTag ? firstPTag.innerHTML : "";
    };
    const decodeHTMLContent = content => {
      return he.decode(content);
    };
    const removeHtmlTags = htmlString => {
      return htmlString.replace(/<[^>]*>/g, "");
    };
    const renderSubtitle = content => {
      return (
        <Typography className="sub-details">
          <span
            dangerouslySetInnerHTML={{
              __html:
                screenWidth >= 1023
                  ? content?.slice(0, 75)
                  : content?.slice(0, 50)
            }}
          ></span>
          {/* {screenWidth >= 1023 ? content?.slice(0, 100) : content?.slice(0, 50)} */}
          <span className="read-more">...read more</span>
        </Typography>
      );
    };
    if (data?.NewsProviderId === 1) {
      const editorBlockData = data?.body?.find(
        item =>
          item?.type === "editor_block" && item?.data?.type === "paragraph"
      );
      const content = parseHTMLContent(editorBlockData?.data?.content);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    } else {
      const content = parseHTMLContent(data?.body);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    }
  };

  const fetchNewsTime = newsdate => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks == 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} ${timeDiffHours == 1 ? "hour" : "hours"} ago`;
      }
    } else {
      return `${timeDiffMinutes} ${
        timeDiffMinutes == 1 ? "minute" : "minutes"
      } ago`;
    }
  };

  const handleDelete = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    if (handleRestrictedUser()) {
      let payload = {
        status: "deleted"
      };
      try {
        const { status, data } = await axiosInstance.post(
          `/v2/news/saved/${item?.id}`,
          payload
        );
        if (status === 200) {
          setApiMessage(
            "success",

            "News removed from saved!"
          );

          // const updateNews = articleData?.filter(
          //   article => article.id !== item?.id
          // );
          // setArticleData(updateNews);
          fetchSavedArticle();
        } else {
          setApiMessage("error", data?.message);
        }
      } catch (err) {
        setApiMessage("error", err?.response?.data?.message);
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
    }
  };
  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const handleNavigate = item => {
    navigate(`/news/${item?.NewsCategoryId}/${item?.id}`);
  };
  return (
    <>
      {isArticleLoading ? (
        <div className="allsport-loader-center news-loader">
          <Loader />
        </div>
      ) : articleData?.length > 0 ? (
        screenWidth > 1279 ? (
          <Box className="grid-container">
            {articleData?.map((item, index) => (
              <Box key={index} className={`grid-item`}>
                <Box
                  onClick={() => {
                    handleNavigate(item);
                  }}
                  className={`${"responsive-news-view"}`}
                >
                  <Box
                    className="background-overlay"
                    style={{
                      content: "''",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage: `url('${
                        item?.mainMedia?.gallery
                          ? item?.mainMedia?.gallery?.url &&
                            item?.mainMedia?.gallery?.url !== ""
                            ? item?.mainMedia?.gallery?.url
                            : DefaultImg
                          : item?.mainMedia?.[0]?.gallery
                          ? item?.mainMedia?.[0]?.gallery?.url &&
                            item?.mainMedia?.[0]?.gallery?.url !== ""
                            ? item?.mainMedia?.[0]?.gallery?.url
                            : DefaultImg
                          : DefaultImg
                      }')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center top",
                      filter: "blur(6px)",
                      zIndex: -1,
                      borderRadius: "8px"
                    }}
                  />
                  <Box className="news-img">
                    <LazyLoadImage
                      src={
                        item?.mainMedia?.gallery
                          ? item?.mainMedia?.gallery?.url &&
                            item?.mainMedia?.gallery?.url !== ""
                            ? item?.mainMedia?.gallery?.url
                            : DefaultImg
                          : item?.mainMedia?.[0]?.gallery
                          ? item?.mainMedia?.[0]?.gallery?.url &&
                            item?.mainMedia?.[0]?.gallery?.url !== ""
                            ? item?.mainMedia?.[0]?.gallery?.url
                            : DefaultImg
                          : DefaultImg
                      }
                      alt="news-img"
                    />
                  </Box>
                  <Box className="details-wrapper">
                    <span className="news-tag">
                      {item?.NewsCategory?.initialTitle}
                    </span>
                    <Box className="news-deatils">
                      <Typography
                        className="title-news"
                        dangerouslySetInnerHTML={{
                          __html: item?.title ? item?.title : item?.subTitle
                        }}
                      />

                      <Box className="sub-details-wrap">
                        {fetchNewsSubtitle(item)}
                      </Box>

                      <Box className="news-time-comment-wrapper">
                        <Typography className="news-time">
                          {fetchNewsTime(item?.rapidCreatedAt)}
                        </Typography>
                        <Box className="bookmark-chat-wrapper">
                          <Box onClick={e => handleDelete(e, item)}>
                            {/* <Tooltip
                            placement="bottom"
                            arrow
                            title='Save to "Saved articles"'
                            classes={{
                              tooltip: "news-tooltip"
                            }}
                          > */}
                            <Delete className={"delete-icon"} />
                            {/* </Tooltip> */}
                          </Box>
                          <Tooltip
                            placement="bottom"
                            arrow
                            title="Comments"
                            classes={{
                              tooltip: "news-tooltip"
                            }}
                          >
                            <Box className="chat-details">
                              <ChatIcon />
                              <Typography className="news-time">
                                {item?.totalComments}
                              </Typography>
                            </Box>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Grid
            container
            className={`individual-news-cont comman-news-comp ${
              articleData.length === 1 ? `single-news-comp` : ""
            }`}
          >
            {articleData?.map(item => (
              <Grid
                item
                xs={12}
                className="individual-single-news"
                key={item?.id}
              >
                <NavLink to={`/news/${item?.NewsCategoryId}/${item?.id}`}>
                  <Box className="news-box">
                    <Box className="news-tag-relative">
                      <Box className="news-img-container">
                        <Box
                          className="background-overlay"
                          style={{
                            content: "''",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundImage: `url('${
                              item?.mainMedia?.gallery
                                ? item?.mainMedia?.gallery?.url &&
                                  item?.mainMedia?.gallery?.url !== ""
                                  ? item?.mainMedia?.gallery?.url
                                  : DefaultImg
                                : item?.mainMedia?.[0]?.gallery
                                ? item?.mainMedia?.[0]?.gallery?.url &&
                                  item?.mainMedia?.[0]?.gallery?.url !== ""
                                  ? item?.mainMedia?.[0]?.gallery?.url
                                  : DefaultImg
                                : DefaultImg
                            }')`,
                            backgroundSize: "cover",
                            backgroundPosition: "center top",
                            filter: "blur(10px)",
                            zIndex: 0,
                            borderRadius: "8px"
                          }}
                        ></Box>
                        <Box className="news-img">
                          <img
                            src={
                              item?.mainMedia?.gallery
                                ? item?.mainMedia?.gallery?.url &&
                                  item?.mainMedia?.gallery?.url !== ""
                                  ? item?.mainMedia?.gallery?.url
                                  : DefaultImg
                                : item?.mainMedia?.[0]?.gallery
                                ? item?.mainMedia?.[0]?.gallery?.url &&
                                  item?.mainMedia?.[0]?.gallery?.url !== ""
                                  ? item?.mainMedia?.[0]?.gallery?.url
                                  : DefaultImg
                                : DefaultImg
                            }
                            alt="placeholder"
                          />
                        </Box>
                        <span className="news-tag">
                          {item?.NewsCategory?.initialTitle}
                        </span>
                      </Box>
                    </Box>
                    <Box className="individual-news-details">
                      <Typography
                        className="details"
                        dangerouslySetInnerHTML={{
                          __html: item?.title ? item?.title : item?.subTitle
                        }}
                      ></Typography>

                      <Box className="sub-details-wrap">
                        {fetchNewsSubtitle(item)}
                      </Box>

                      {/* <Box className="tag-time-details">
                  <Typography className="stories-time">
                    <span className="watch">
                      <Watch />
                    </span>
                    {fetchNewsTime(item?.rapidCreatedAt)}
                  </Typography>
                </Box> */}
                      <Box className="news-time-comment-wrapper">
                        <Typography className="news-time">
                          {fetchNewsTime(item?.rapidCreatedAt)}
                        </Typography>
                        <Box className="bookmark-chat-wrapper">
                          <Box onClick={e => handleDelete(e, item)}>
                            <Delete className={"delete-icon"} />
                          </Box>
                          <Tooltip
                            placement="bottom"
                            arrow
                            title="Comments"
                            classes={{
                              tooltip: "news-tooltip"
                            }}
                          >
                            <Box className="chat-details">
                              <ChatIcon />
                              <Typography className="news-time">
                                {item?.totalComments}
                              </Typography>
                            </Box>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </NavLink>
              </Grid>
            ))}
          </Grid>
        )
      ) : (
        <>
          <Box className="no-data-wrapper f-16">
            <Typography className="no-data-text">No data Available</Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default LeftSaveArticle;
