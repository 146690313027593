import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { IntlContext } from "src/App";
import FooterLogo from "../../../assets/images/footer-log.webp"
import { ReactComponent as EmailIcon } from "../../../assets/images/icons/email-icon.svg";
import { ReactComponent as Call } from "../../../assets/images/icons/call.svg";
import playstore from "../../../assets/images/playstore.webp";
import AppleStore from "../../../assets/images/appstore.webp";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { Config } from "src/helpers/context";
import { ReactComponent as GamblingHelp } from "../../../assets/images/icons/gambling-help.svg";
import FooterLogoWhite from "../../../assets/images/smartB-white.png"
import { ReactComponent as Facebook } from "../../../assets/images/icons/facebook.svg";
import { ReactComponent as Snapchat } from "../../../assets/images/icons/snapchat-logo.svg";
import { ReactComponent as Instagram } from "../../../assets/images/icons/instagram.svg";
import { ReactComponent as Linkedin } from "../../../assets/images/icons/linkedin.svg";
import { ReactComponent as Twitter } from "../../../assets/images/icons/twitter.svg";
import { ReactComponent as TwitterX } from "../../../assets/images/icons/TwitterX.svg";
import { ReactComponent as Youtube } from "../../../assets/images/youtube.svg";
import { ReactComponent as Rumble } from "../../../assets/images/icons/rumble.svg";
import TikTok from "../../../assets/images/tiktok.png";
import "./footer.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";


const FooterV2 = () => {
    const intlContext = useContext(IntlContext);
    const localesData = intlContext?.messages;
    const location = useLocation()
    const version = process.env.REACT_APP_VERSION;
    const [tagLine, setTagLine] = useState(
        JSON.parse(window.localStorage.getItem("tagline"))
    );
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);


    useEffect(() => {
        let taglineOption = JSON.parse(window.localStorage.getItem("tagline"));
        taglineOption === 6 ? setTagLine(1) : setTagLine(tagLine + 1);
        window.addEventListener("resize", function () {
            setScreenWidth(window.innerWidth);
        });
    }, []);

    useEffect(() => {
        window.localStorage.setItem("tagline", tagLine);
    }, [tagLine]);

    const fetTagline = () => {
        switch (tagLine) {
            case 1:
                return "Chances are you’re about to lose.";
            case 2:
                return "Think. Is this a bet you really want to place?";
            case 3:
                return "What’s gambling really costing you?";
            case 4:
                return "Imagine what you could be buying instead.";
            case 5:
                return "You win some. You lose more.";
            case 6:
                return "What are you really gambling with?";
            default:
                return "Chances are you’re about to lose.";
        }
    };
    return (
        <>
            <Box className="footer-wrap odds-footer-wrap">
                <Box className="upper-footer">
                    {/* <LazyLoadImage src={FooterLogo} alt="logo" /> */}
                    <Box className="responsible-gambling-wrap">
                        <Typography className="tag-line"> {fetTagline()}</Typography>
                        <Typography>
                            <span className="responsible-gambling-text">

                                For free and confidential support call{" "}
                                <a
                                    href="tel:1800 858 858"
                                    className="responsible-gambling-helpline-no"
                                >
                                    {" "}
                                    1800 858 858{" "}
                                </a>{" "}
                                or visit{" "}
                            </span>
                        </Typography>
                        <Typography>
                            <a
                                href="https://www.gamblinghelponline.org.au/"
                                className="responsible-gambling-logo"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <GamblingHelp />
                            </a>
                        </Typography>
                    </Box>
                </Box>
                <Box className="footer-content">
                    <Link to="/" >
                        <LazyLoadImage src={FooterLogoWhite} alt="logo" />
                    </Link>
                    <Grid container className="footer-links-wrap">
                        <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
                            <h4>{localesData?.footer?.CONTACT_US}</h4>
                            <Box className="contact">
                                <EmailIcon />
                                <span>
                                    <a
                                        href={
                                            Config?.release == "IN"
                                                ? "mailto:info@smartb.co.in"
                                                : "mailto:info@smartb.com.au"
                                        }
                                    >
                                        {Config?.release == "IN"
                                            ? "info@smartb.co.in"
                                            : "info@smartb.com.au"}
                                    </a>
                                </span>
                            </Box>
                            {/* <Box className="contact">
                                <Call />
                                <span>
                                    <a href="tel:1300065591">1300 065 591</a>
                                </span>
                            </Box> */}

                            {screenWidth > 1279 ?
                                <Box className="social-media-wrap">
                                    <h4>{localesData?.footer?.FOLLOW_US}</h4>
                                    <Box className="social-icon-wrap">
                                        <a
                                            href="https://www.instagram.com/smartbapp/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            aria-label="instagram"
                                        >
                                            <Instagram className="sidebar-icon" />
                                        </a>
                                        <Box className="tiktok">
                                            <a
                                                href="https://www.tiktok.com/@smartbapp"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label="tiktok"
                                            >
                                                <LazyLoadImage src={TikTok} alt="tiktok" />
                                            </a>
                                        </Box>
                                        <Box className="tiktok youtube">
                                            <a
                                                href="https://www.youtube.com/@smartbapp"
                                                className="youtube"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label="youtube"
                                            >
                                                <Youtube />
                                            </a>
                                        </Box>
                                        <Box className="tiktok youtube">
                                            <a
                                                href="https://www.facebook.com/smartbapp"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label="facebook"
                                            >
                                                <Facebook className="sidebar-icon" />
                                            </a>
                                        </Box>
                                        {/* <Box className="tiktok youtube snapChat">
              <a
                href="https://t.snapchat.com/m2Cu44hr"
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="snapchat"
              >
                <Snapchat />
              </a>
            </Box> */}
                                        {/* <Box className="tiktok youtube snapChat">
              <a
                href=" https://rumble.com/c/c-4732998"
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="youtube"
              >
                <Rumble />
              </a>
            </Box> */}
                                        <Box className="tiktok linkedin">
                                            <a
                                                href="https://www.linkedin.com/company/smartbapp/"
                                                className="youtube"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label="youtube"
                                            >
                                                <Linkedin />
                                            </a>
                                        </Box>
                                        <Box className="tiktok">
                                            <a
                                                href="https://twitter.com/backawinneraus"
                                                className="twitter"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label="twitter"
                                            >
                                                <TwitterX className="sidebar-icon" />
                                            </a>
                                        </Box>
                                    </Box>
                                </Box>
                                : <Box className="link-wrap mobile-policy">
                                    <Link to="/privacy-policy" className={location.pathname === "/privacy-policy" ? "active" : ""}>
                                        {localesData?.MENU?.PRIVACY_POLICY}
                                    </Link>
                                    <Link to="/responsible-gambling" className={location.pathname === "/responsible-gambling" ? "active" : ""}>
                                        {localesData?.MENU?.RESPONSIBLE_GAMBLING}
                                    </Link>

                                    <Link to="/terms-and-conditions" className={location.pathname === "/terms-and-conditions" ? "active" : ""}>
                                        {localesData?.MENU?.TERMS_AND_CONDITIONS}
                                    </Link>
                                    <Link to="/subscription-terms-and-conditions" className={location.pathname === "/subscription-terms-and-conditions" ? "active" : ""}>
                                        {localesData?.MENU?.SUBSCRIPTION_TERMS_AND_CONDITIONS}
                                    </Link>
                                    {/* {Config.release == "AU" && (
                                    <a
                                        href={`${Config?.siteBaseURL?.replace(
                                            "/api/",
                                            "/"
                                        )}frequently-asked-questions/`}
                                        target="_blank"
                                    >
                                        FAQ
                                    </a>
                                )} */}
                                </Box>
                            }
                        </Grid>
                        <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
                            {/* <h4>{localesData?.footer?.navigate}</h4> */}
                            <Box className="link-wrap">
                                <Link to="/" className={location.pathname === "/" ? "active" : ""}>{localesData?.MENU?.HOME}</Link>
                                <Link to="/racing" className={location.pathname === "/racing" ? "active" : ""}>{localesData?.MENU?.RACING}</Link>
                                {/* <Link to="/comingsoon">{localesData?.MENU?.SPORTS}</Link> */}
                                <Link to="/podcast" className={location.pathname === "/podcast" ? "active" : ""}>
                                    {localesData?.MENU?.PODCASTS}
                                </Link>
                                <Link to="/tipscompetition/public/tips" className={location.pathname === "/tipscompetition/public/tips" ? "active" : ""}>{localesData?.MENU?.TIPPING_COMP}</Link>
                            </Box>
                        </Grid>
                        <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
                            {/* <h4>{localesData?.MENU?.POLICIES}</h4> */}
                            {screenWidth > 1279 ?
                                <Box className="link-wrap">
                                    <Link to="/privacy-policy" className={location.pathname === "/privacy-policy" ? "active" : ""}>
                                        {localesData?.MENU?.PRIVACY_POLICY}
                                    </Link>
                                    <Link to="/responsible-gambling" className={location.pathname === "/responsible-gambling" ? "active" : ""}>
                                        {localesData?.MENU?.RESPONSIBLE_GAMBLING}
                                    </Link>

                                    <Link to="/terms-and-conditions" className={location.pathname === "/terms-and-conditions" ? "active" : ""}>
                                        {localesData?.MENU?.TERMS_AND_CONDITIONS}
                                    </Link>
                                    <Link to="/subscription-terms-and-conditions" className={location.pathname === "/subscription-terms-and-conditions" ? "active" : ""}>
                                        {localesData?.MENU?.SUBSCRIPTION_TERMS_AND_CONDITIONS}
                                    </Link>
                                    {/* {Config.release == "AU" && (
                                    <a
                                        href={`${Config?.siteBaseURL?.replace(
                                            "/api/",
                                            "/"
                                        )}frequently-asked-questions/`}
                                        target="_blank"
                                    >
                                        FAQ
                                    </a>
                                )} */}
                                </Box>
                                : <Box className="social-media-wrap">
                                    <h4>{localesData?.footer?.FOLLOW_US}</h4>
                                    <Box className="social-icon-wrap">
                                        <a
                                            href="https://www.instagram.com/smartbapp/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            aria-label="instagram"
                                        >
                                            <Instagram className="sidebar-icon" />
                                        </a>
                                        <Box className="tiktok">
                                            <a
                                                href="https://www.tiktok.com/@smartbapp"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label="tiktok"
                                            >
                                                <LazyLoadImage src={TikTok} alt="tiktok" />
                                            </a>
                                        </Box>
                                        <Box className="tiktok youtube">
                                            <a
                                                href="https://www.youtube.com/@smartbapp"
                                                className="youtube"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label="youtube"
                                            >
                                                <Youtube />
                                            </a>
                                        </Box>
                                        <Box className="tiktok youtube">
                                            <a
                                                href="https://www.facebook.com/smartbapp"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label="facebook"
                                            >
                                                <Facebook className="sidebar-icon" />
                                            </a>
                                        </Box>
                                        {/* <Box className="tiktok youtube snapChat">
      <a
        href="https://t.snapchat.com/m2Cu44hr"
        className="youtube"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="snapchat"
      >
        <Snapchat />
      </a>
    </Box> */}
                                        {/* <Box className="tiktok youtube snapChat">
      <a
        href=" https://rumble.com/c/c-4732998"
        className="youtube"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="youtube"
      >
        <Rumble />
      </a>
    </Box> */}
                                        <Box className="tiktok linkedin">
                                            <a
                                                href="https://www.linkedin.com/company/smartbapp/"
                                                className="youtube"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label="youtube"
                                            >
                                                <Linkedin />
                                            </a>
                                        </Box>
                                        <Box className="tiktok">
                                            <a
                                                href="https://twitter.com/backawinneraus"
                                                className="twitter"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label="twitter"
                                            >
                                                <TwitterX className="sidebar-icon" />
                                            </a>
                                        </Box>
                                    </Box>
                                </Box>}
                        </Grid>
                        <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
                            <h4>Our App</h4>
                            <Box className="app-logo">
                                <Box className="link-wrap playstore-logo">
                                    <a
                                        href={
                                            Config.release == "AU"
                                                ? "https://play.google.com/store/apps/details?id=au.com.smartb"
                                                : "https://play.google.com/store/apps/details?id=in.com.smartb"
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <LazyLoadImage
                                            src={playstore}
                                            alt="playstore"
                                            className="playstore-img"
                                        />
                                    </a>
                                </Box>
                                <Box className="link-wrap">
                                    <a
                                        href={
                                            Config.release == "AU"
                                                ? "https://apps.apple.com/au/app/smartb-app/id1672189237"
                                                : "https://apps.apple.com/us/app/smartb-india/id6479695004"
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <LazyLoadImage
                                            src={AppleStore}
                                            alt="Apple-store"
                                            className="playstore-img"
                                        />
                                    </a>
                                </Box>
                            </Box>
                        </Grid>
                        {/* <Grid item xl={2} lg={2} md={6} sm={6} xs={6}>
            <h4>{localesData?.MENU?.HELP}</h4>
            <Box className="link-wrap">
              <Link to="/comingsoon">{localesData?.MENU?.LIVE_CHAT}</Link>
              <Link to="/comingsoon">{localesData?.MENU?.HELP_CENTER}</Link>
            </Box>
          </Grid> */}
                    </Grid>
                </Box>
                <p className="copyright">
                    {localesData?.footer?.copyright} © SmartB Pty Ltd {moment()?.year()}
                    <span style={{ display: "block", paddingTop: "5px" }}>{version}</span>
                </p>
            </Box>
        </>
    );
};

export default FooterV2;
