import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Switch,
  Typography
} from "@material-ui/core";
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import { ReactComponent as Unchecked } from "../../../assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Checked } from "../../../assets/images/icons/smartb-check.svg";
import OurPartner from "../ourPartners";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "src/helpers/store/Actions/auth";
import AdBannner from "../AdBanner/AdBanner";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { release } from "src/helpers/context/release";
import { Config } from "src/helpers/context";
import Loader from "src/components/Loader";
import "./manageEmailSubscriptions.scss";
import { setApiMessage } from "src/helpers/commonFunctions";

const ManageEmailSubscriptionsPage = () => {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const location = useLocation();
  const cartKey = location.search?.replace("?cartkey=", "");
  const cartkeyDecode = decodeURIComponent(cartKey);
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [isLoading, setLoading] = useState(false);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [sports, setSports] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);

  useEffect(() => {
    getEmailConfigurationData();
  }, []);

  const getEmailConfigurationData = async () => {
    setLoading(true);
    const isEmail = encodeURIComponent(cartkeyDecode)
      ? `?cartkey=${encodeURIComponent(cartkeyDecode)}`
      : "";
    try {
      const { status, data } = await axiosInstance.get(
        `/user/emailConfiguration${isEmail}`
      );
      if (status === 200) {
        fetchSportData(data?.result);
        // setLoading(false);
      } else if (status === 403) {
        setLoading(false);
        localStorage.removeItem("auth_token");
        navigate("/sign-in");
        dispatch(loginUser(""));
      } else if (status === 404) {
        setLoading(false);
        localStorage.removeItem("auth_token");
        navigate("/");
        dispatch(loginUser(""));
      } else {
        fetchSportData(data?.result);
        setLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 400 || error?.response?.status === 404) {
        setLoading(false);
        localStorage.removeItem("auth_token");
        navigate("/");
        dispatch(loginUser(""));
      }
      setLoading(false);
    }
  };

  const fetchSportData = async newData => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport?sportTypeId=${2}`
      );
      if (status === 200) {
        const releaseFilterSportData = data?.result?.filter(item =>
          release[Config.release]?.sportId?.includes(item?.id)
        );
        const SportsData = await releaseFilterSportData?.map(item => {
          return {
            ...item,
            notificationData: [
              { id: 1, subName: "Tips" },
              { id: 2, subName: "News" },
              { id: 3, subName: "Fixtures & Results" }
            ]
          };
        });
        const sortedData = SportsData?.sort((a, b) => {
          return a?.sportName.localeCompare(b?.sportName);
        });
        const mergeData =
          release[Config.release]?.raceSportId?.length > 0
            ? [
                {
                  id: "dailyBestBet",
                  sportName: "Daily Best  Bets",
                  SportId: "dailyBestBet",
                  otherText: "(Racing)",
                  notificationData: []
                },
                {
                  id: "tipOfTheDay",
                  sportName: "Tip of the day",
                  SportId: "tipOfTheDay",
                  otherText: "(Racing)",
                  notificationData: []
                },
                {
                  id: "blackBook",
                  sportName: "BlackBook",
                  SportId: "blackBook",
                  otherText: "Daily Upcoming Races updates",
                  notificationData: []
                },
                {
                  id: "smartBNewsLetter",
                  sportName: "SmartB - NewsLetter",
                  SportId: "smartBNewsLetter",
                  notificationData: []
                },
                {
                  id: "weeklyNewsLetter",
                  sportName: "Back a Winner - Newsletters",
                  SportId: "weeklyNewsLetter",
                  notificationData: [
                    { id: "Wednesday", subName: "Wednesday Racing Tips" },
                    { id: "Friday", subName: "Weekly Tips + Previews " }
                  ]
                },
                {
                  id: "smartBook",
                  sportName: "SmartBook",
                  SportId: "smartBook",
                  otherText: "Daily Upcoming Fixtures updates",
                  notificationData: []
                },
                {
                  id: "tippingCompetition",
                  sportName: "Tipping Competition Updates",
                  SportId: "tippingCompetition",
                  notificationData: [
                    { id: "enterTip", subName: "Enter Tips" },
                    { id: "currentLadder", subName: "Current Ladder" },
                    { id: "endOfTournament", subName: "End of Tournament" }
                  ]
                },
                ...sortedData,
                {
                  id: 1,
                  sportName: "Racing",
                  SportId: 1,
                  notificationData: [
                    { id: 1, subName: "Tips" },
                    { id: 2, subName: "News" },
                    { id: 3, subName: "Fixtures & Results" }
                  ]
                }
              ]
            : [...sortedData];
        const SelectedData = await mergeData?.map(item => {
          const filteredSubscription =
            newData?.NotificationPreference?.[item?.id];
          const filteredSports =
            newData?.NotificationPreference?.sportsValue?.length > 0 &&
            newData?.NotificationPreference?.sportsValue?.filter(
              ele => ele?.SportId === item?.id
            );
          const allChecked =
            filteredSports?.[0]?.SportId == 1
              ? filteredSports?.[0]?.tips &&
                filteredSports?.[0]?.news &&
                filteredSports?.[0]?.fixtures
              : filteredSports?.[0]?.tips &&
                filteredSports?.[0]?.news &&
                filteredSports?.[0]?.fixtures &&
                !!filteredSubscription;
          const someChecked =
            filteredSports?.[0]?.SportId == 1
              ? filteredSports?.[0]?.tips ||
                filteredSports?.[0]?.news ||
                filteredSports?.[0]?.fixtures
              : filteredSports?.[0]?.tips ||
                filteredSports?.[0]?.news ||
                filteredSports?.[0]?.fixtures ||
                !!filteredSubscription;
          const isChecked = allChecked || (!allChecked && someChecked);

          return {
            ...item,
            isChecked:
              item?.id === "weeklyNewsLetter" ||
              item?.id === "tippingCompetition"
                ? newData?.NotificationPreference?.[item?.id]?.length === 0 ||
                  newData?.NotificationPreference?.[item?.id] === null ||
                  newData?.NotificationPreference === null
                  ? false
                  : true
                : isChecked,
            notificationData: item?.notificationData?.map((ele, index) => {
              return {
                ...ele,
                isChecked:
                  item?.id == 1
                    ? index === 0
                      ? filteredSports?.[0]?.tips
                      : index === 1
                      ? filteredSports?.[0]?.news
                      : index === 2
                      ? filteredSports?.[0]?.fixtures
                      : false
                    : index === 0
                    ? filteredSports?.[0]?.tips ||
                      filteredSubscription?.includes("Wednesday") ||
                      filteredSubscription?.includes("enterTip")
                    : index === 1
                    ? filteredSports?.[0]?.news ||
                      filteredSubscription?.includes("Friday") ||
                      filteredSubscription?.includes("currentLadder")
                    : index === 2
                    ? filteredSports?.[0]?.fixtures ||
                      filteredSubscription?.includes("endOfTournament")
                    : false
              };
            })
          };
        });
        setSports(SelectedData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ReduxAdsData) {
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let AdvtData = ReduxAdsData?.filter(item => item?.page_id === 1);
    setAdData(AdvtData);
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleSubscriptionChange = (sportId, subId) => {
    const updatedSports = sports?.map(sport => {
      if (sport?.id === sportId) {
        const updatedSubscriptions = sport?.notificationData?.map(sub => {
          if (sub?.id === subId) {
            return {
              ...sub,
              isChecked: !sub?.isChecked
            };
          }
          return sub;
        });

        const allChecked = updatedSubscriptions?.every(sub => sub?.isChecked);
        const someChecked = updatedSubscriptions?.some(sub => sub?.isChecked);
        const isChecked = allChecked || (!allChecked && someChecked);

        return {
          ...sport,
          notificationData: updatedSubscriptions,
          isChecked
        };
      }
      return sport;
    });
    setSports(updatedSports);
  };

  const handleSportSwitchChange = sportId => {
    const updatedSports = sports?.map(sport => {
      if (Array.isArray(sport?.id) && sport?.id.includes(sportId)) {
        const updatedSubscriptions = sport?.notificationData?.map(sub => ({
          ...sub,
          isChecked: !sub?.isChecked
        }));
        return {
          ...sport,
          notificationData: updatedSubscriptions
        };
      } else if (sport?.id === sportId) {
        return {
          ...sport,
          isChecked: !sport?.isChecked,
          notificationData: sport?.notificationData?.map(sub => ({
            ...sub,
            isChecked: !sport?.isChecked
          }))
        };
      }
      return sport;
    });
    setSports(updatedSports);
  };

  const handleSelectAllSwitch = () => {
    const updatedSports = sports?.map(sport => {
      if (sport?.notificationData?.length === 0) {
        return {
          ...sport,
          isChecked: !isSelectAll
        };
      } else {
        return {
          ...sport,
          isChecked: !isSelectAll,
          notificationData: sport?.notificationData?.map(sub => ({
            ...sub,
            isChecked: !isSelectAll
          }))
        };
      }
    });
    setSports(updatedSports);
    setIsSelectAll(prev => !prev);
  };

  const handleSave = async () => {
    setIsLoader(true);
    const notificationData =
      (await sports?.length) > 0 &&
      sports?.map(item => {
        if (item?.id != 1) {
          return {
            ...item,
            SportId: item?.id,
            tips: item?.notificationData?.[0]?.isChecked
              ? item?.notificationData?.[0]?.isChecked
              : false,
            news: item?.notificationData?.[1]?.isChecked
              ? item?.notificationData?.[1]?.isChecked
              : false,
            fixtures: item?.notificationData?.[2]?.isChecked
              ? item?.notificationData?.[2]?.isChecked
              : false
          };
        } else {
          return {
            ...item,
            SportId: item?.id,
            tips: item?.notificationData?.[0]?.isChecked
              ? item?.notificationData?.[0]?.isChecked
              : false,
            news: item?.notificationData?.[1]?.isChecked
              ? item?.notificationData?.[1]?.isChecked
              : false,
            fixtures: item?.notificationData?.[2]?.isChecked
              ? item?.notificationData?.[2]?.isChecked
              : false
          };
        }
      });
    let sportId1 =
      (await notificationData?.length) > 0 &&
      notificationData?.find(item => item.SportId === 1);
    if (sportId1) {
      var allSportsNotificationData = [
        ...notificationData,
        { ...sportId1, SportId: 2 },
        { ...sportId1, SportId: 3 }
      ];
    }

    const subscriptionData =
      release[Config.release]?.raceSportId?.length > 0
        ? allSportsNotificationData
        : notificationData;

    const result = {
      dailyBestBet: false,
      tipOfTheDay: false,
      blackBook: false,
      smartBook: false,
      smartBNewsLetter: false,
      weeklyNewsLetter: null,
      tippingCompetition: null,
      sportsValue: []
    };

    subscriptionData.forEach(item => {
      if (
        item.SportId === "dailyBestBet" ||
        item.SportId === "tipOfTheDay" ||
        item.SportId === "blackBook" ||
        item.SportId === "smartBook" ||
        item.SportId === "smartBNewsLetter"
      ) {
        result[item.SportId] =
          item.tips || item.news || item.fixtures || item.isChecked;
      } else if (
        item.SportId === "weeklyNewsLetter" ||
        item.SportId === "tippingCompetition"
      ) {
        result[item.SportId] = item?.notificationData
          ?.filter(item => item?.isChecked)
          ?.map(item => item?.id);
      } else {
        const sport = {
          SportId: item.SportId,
          tips: item.tips || false,
          news: item.news || false
        };
        if ("fixtures" in item) {
          sport.fixtures = item.fixtures;
        }
        if ("dailyBestBet" in item) {
          sport.dailyBestBet = item.dailyBestBet;
        }
        if ("weeklyNewsLetter" in item) {
          sport.weeklyNewsLetter = item.weeklyNewsLetter?.length > 0;
        }
        result.sportsValue.push(sport);
      }
    });

    const payload = result;
    const isEmail = encodeURIComponent(cartkeyDecode)
      ? `?cartkey=${encodeURIComponent(cartkeyDecode)}`
      : "";
    try {
      const { status, data } = await axiosInstance.post(
        `/user/emailConfiguration${isEmail}`,
        payload
      );
      if (status === 200) {
        setApiMessage("success", data?.message);
        setIsLoader(false);
      } else if (status === 403) {
        setIsLoader(false);
        localStorage.removeItem("auth_token");
        navigate("/sign-in");
        dispatch(loginUser(""));
      } else {
        setIsLoader(false);
      }
    } catch (error) {
      setIsLoader(false);
    }
  };

  const handleCancelSubscription = () => {
    navigate("/");
  };
  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", banner)}
        </Box>

        <Box className="manage-email-subscription-wrap">
          <Box className="manage-email-subscription-header">
            <Typography variant="h1">Manage Email Notifications</Typography>
            <Box className="header-selectall-wrap">
              <Typography variant="h6" className="email-header-sub-text">
                Current Email Subscriptions
              </Typography>
              <Box className="select-all-switch">
                <Typography> Select All</Typography>
                <Box className="switch-box">
                  <FormControlLabel
                    value=""
                    control={
                      <Switch
                        color="primary"
                        checked={isSelectAll}
                        onChange={() => handleSelectAllSwitch()}
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="page-deatils-wrap">
            {isLoading ? (
              <div className="loader_center">
                <Loader />
              </div>
            ) : (
              <>
                <Box className="manage-subscription-contant">
                  {sports?.map(item => {
                    return (
                      <Box className="subscription-contant">
                        <Box className="subscription-contant-header">
                          <Typography
                            variant="h6"
                            className="subscription-title"
                          >
                            {item?.sportName}{" "}
                            <span className="subscription-title-other-text">
                              {item?.otherText && `- ${item?.otherText}`}
                            </span>
                          </Typography>

                          <Box className="switch-box">
                            <FormControlLabel
                              value=""
                              control={
                                <Switch
                                  color="primary"
                                  checked={item?.isChecked == true}
                                  onChange={() =>
                                    handleSportSwitchChange(item?.id)
                                  }
                                />
                              }
                            />
                          </Box>
                        </Box>
                        {item?.notificationData?.length > 0 && (
                          <Box className="subscription-checkbox">
                            {item?.notificationData?.map((obj, i) => (
                              <FormControl key={obj?.id}>
                                <label>
                                  <Checkbox
                                    icon={<Unchecked />}
                                    checkedIcon={<Checked />}
                                    name="obj"
                                    value={obj?.id}
                                    checked={obj?.isChecked == true}
                                    onChange={() =>
                                      handleSubscriptionChange(
                                        item?.id,
                                        obj?.id
                                      )
                                    }
                                  />
                                  {obj?.subName}
                                </label>
                              </FormControl>
                            ))}
                          </Box>
                        )}
                      </Box>
                    );
                  })}
                </Box>
                {sports?.length === 0 && (
                  <Box>
                    <Typography className="allsport-loader-center">
                      No email subscription
                    </Typography>
                  </Box>
                )}
                {sports?.length !== 0 && (
                  <Box className="subscription-update-btn">
                    <Button
                      className="update-btn"
                      onClick={() => handleSave()}
                      disabled={isLoader}
                    >
                      {isLoader ? "Loading..." : "Update"}
                    </Button>
                    <Typography
                      className="cancel-btn"
                      onClick={() => handleCancelSubscription()}
                    >
                      Cancel
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
          <OurPartner />
        </Box>
      </Box>
    </>
  );
};

export default ManageEmailSubscriptionsPage;
