import React, { useState, useEffect, useContext } from "react";
import { Box, Breadcrumbs, Typography, Tab, Tabs } from "@material-ui/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IntlContext } from "src/App";
import { useSelector } from "react-redux";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import banner from "../../../../../assets/images/banner/smart-b-banner-secodary.webp";
import { ReactComponent as Plus } from "../../../../../assets/images/icons/plus.svg";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import InfoFixture from "./infoFixture";
import OurPartner from "src/views/component/ourPartners";
import InfoSummary from "./infoSummary";
import MatchStats from "./matchStats";
import LineUpInfo from "./lineUpInfo";
import InfoTable from "./infoTable";
import InfoNews from "./infoNews";
import InfoH2H from "./infoH2H";
import InfoOddsComparison from "./infoOddsComparison";
import InfoOddsFluctuation from "./infoOddsFluctuation";
import SportMatchup from "src/views/component/sportMatchupsPage/sportsDetails/nbaMatchup";
import moment from "moment";
import SoccerCard from "src/views/component/teamSportData/soccerSportData/soccerCard";
import { Config } from "src/helpers/context";
import "./SoccerFixtureInfoSport.scss";

const SoccerFixtureInfoSport = () => {
  const params = useParams();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isEventLoading, setIsEventLoading] = useState(false);
  const [fixtureInfoData, setFixtureInfoData] = useState({});
  const [MarketName, setMarketName] = useState("");
  const [MarketType, setMarketType] = useState("");
  const [isMultipleTeam, setIsMultipleTeam] = useState(false);
  const [individualTeamData, setindividualTeamData] = useState({});
  const [providerOption, setProviderOption] = useState([]);
  const [pageHeadingData, setPageHeadingData] = useState([]);
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [singleMatchData, setSingleMatchData] = useState({});
  const [matchLoading, setMatchLoading] = useState(false);
  const [tabvalue, setTabValue] = useState(0);

  const fixturesData = [
    {
      id: 0,
      name: "Info"
    },
    {
      id: 1,
      name: "Summary"
    },
    {
      id: 2,
      name: "Stats"
    },
    {
      id: 3,
      name: "Line-Ups"
    },
    {
      id: 4,
      name: "Table"
    },
    {
      id: 5,
      name: "News"
    },
    {
      id: 6,
      name: "H2H info"
    },
    {
      id: 7,
      name: "Odds Comparison"
    },
    {
      id: 8,
      name: "Odds fluctuation"
    }
  ];
  const soccerLiveTab = [
    {
      id: 0,
      name: "Info"
    },
    {
      id: 1,
      name: "Summary"
    },
    {
      id: 2,
      name: "Stats"
    },
    {
      id: 3,
      name: "Line-Ups"
    },
    {
      id: 4,
      name: "Table"
    },
    {
      id: 5,
      name: "News"
    },
    {
      id: 6,
      name: "H2H info"
    },
    {
      id: 7,
      name: "Odds Comparison"
    },
    {
      id: 8,
      name: "Odds fluctuation"
    }
  ];
  const soccerTabData = [
    {
      id: 0,
      name: "Info"
    },
    // {
    //   id: 3,
    //   name: "Line-Ups"
    // },
    {
      id: 4,
      name: "Table"
    },
    {
      id: 5,
      name: "News"
    },
    {
      id: 6,
      name: "H2H info"
    },
    {
      id: 7,
      name: "Odds Comparison"
    },
    {
      id: 8,
      name: "Odds fluctuation"
    }
  ];

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchBookKeeper();
  }, []);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    fetchIndividualMarket();
    fetchSingleMatchData();
  }, [params]);

  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const fetchIndividualMarket = async () => {
    setIsEventLoading(true);
    try {
      let SportsType =
        params?.type === "cricket"
          ? `crickets`
          : params?.type === "rugbyleague"
          ? `rls`
          : params?.type === "rugbyunion"
          ? `rls`
          : params?.type === "basketball"
          ? `nba`
          : params?.type === "americanfootball"
          ? `afl`
          : params?.type === "australianrules"
          ? `ar`
          : params?.type === "golf"
          ? `golf`
          : params?.type === "tennis"
          ? `tennis`
          : params?.type === "baseball"
          ? `baseball`
          : params?.type === "icehockey"
          ? `icehockey`
          : params?.type === "boxing"
          ? `boxing`
          : params?.type === "mma"
          ? `mma`
          : params?.type === "soccer"
          ? `soccer`
          : `rls`;
      const { status, data } = await axiosInstance.get(
        `public/soccer/event/${params?.id}?marketId=&SportId=&smartBook=true`
      );
      if (status === 200) {
        let newdata = [];
        let dropdownData = data?.result?.SoccerBetOffers?.map(item => {
          let Market = item?.SoccerMarket;

          if (Market) {
            newdata.push({
              label: item?.replacer
                ? Market?.displayName
                    .replace("{homeTeam}", item?.replacer)
                    .replace("{awayTeam}", item?.replacer)
                : Market?.displayName,
              value: Market?.id
            });
          }
        });
        const marketId = newdata?.[0]?.value ? newdata?.[0]?.value : 1;
        fetchIndividualTeamdata(marketId);
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const fetchIndividualTeamdata = async marketId => {
    try {
      setIsEventLoading(true);
      const passApi = `public/soccer/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false`;
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        setFixtureInfoData(data?.result);
        let fullData = [];
        let teamdata = [data?.result];
        let datas = teamdata?.map(item => {
          let newData =
            teamdata?.length > 0 ? teamdata?.[0]?.SoccerBetOffers : [];
          let SportsOdds = newData?.[0]?.SoccerOdds;
          let SportMarket = newData?.[0]?.SoccerMarket;
          setMarketName(
            SportMarket?.displayName
              ? SportMarket?.displayName
              : SportMarket?.name
          );
          setMarketType(SportMarket?.type);
          const teamKey = "SoccerTeamId";
          const isTeamdata = SportsOdds?.some(item => item?.[teamKey] !== null);
          const LabelName = SportsOdds?.[0]?.SoccerOddLabel?.displayName
            ? SportsOdds?.[0]?.SoccerOddLabel?.displayName
            : SportsOdds?.[0]?.SoccerOddLabel?.name;
          const name = LabelName ? LabelName : SportsOdds?.[0]?.label;
          setIsMultipleTeam(
            SportsOdds
              ? !isTeamdata &&
                  !(
                    name?.toLowerCase()?.includes("over") ||
                    name?.toLowerCase()?.includes("under") ||
                    name?.toLowerCase()?.includes("totalover") ||
                    name?.toLowerCase()?.includes("totalunder") ||
                    name?.toLowerCase()?.includes("total")
                  )
              : false
          );
          let Bookkeeper = [];
          let BookkeeperList = SportsOdds?.map(element => {
            return Bookkeeper?.push(element?.BookKeeperId);
          });
          fetchTableHeading([...new Set(Bookkeeper)]);
          setindividualTeamData(item);
          fullData.push(item);
          setTimeout(() => {
            setIsEventLoading(false);
          }, 4000);
        });
      }
    } catch {
      setIsEventLoading(false);
    }
  };

  const fetchTableHeading = async Bookkeeper => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders?SportId=8`
      );
      if (status === 200) {
        let filteredHeading = data?.result?.filter(item =>
          Bookkeeper?.includes(item?.BookKeeperId)
        );
        let providerList = [];
        const list = data?.result?.map(item => {
          return providerList?.push({
            label: item?.BookKeeper?.name,
            value: item?.BookKeeperId
          });
        });
        let alldatas = providerList?.unshift(
          {
            label: "Current Best",
            value: -1
          },
          {
            label: "Best At Open",
            value: -2
          }
        );
        setProviderOption(providerList);
        setPageHeadingData(filteredHeading);
      } else {
      }
    } catch (err) {}
  };

  const fetchSingleMatchData = async () => {
    setMatchLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/event/${params?.id}`
      );
      if (status === 200) {
        setSingleMatchData(data?.result);
        setTabValue(data?.result?.ScoreBoard?.Epr !== 0 ? 1 : 0);
        setMatchLoading(false);
      } else {
        setMatchLoading(false);
      }
    } catch (err) {
      setMatchLoading(false);
    }
  };

  const teamScore = singleMatchData?.ScoreBoard;

  const NewTabData =
    Number(params?.SportId) === 8
      ? teamScore?.Epr === 0
        ? singleMatchData?.tableExists
          ? soccerTabData
          : soccerTabData?.filter(x => x?.name !== "Table")
        : singleMatchData?.tableExists
        ? soccerLiveTab
        : soccerLiveTab?.filter(x => x?.name !== "Table")
      : fixturesData;

  const getMatchStatus = status => {
    if (status == "FT") {
      return "Full Time";
    } else if (status == "HT") {
      return "Half Time";
    } else return status;
  };
  const handleNavigate = (data, type) => {
    const teamSlug =
      type === "homeTeam"
        ? data?.homeTeam
          ? data?.homeTeam?.name
              .trim()
              .toLowerCase()
              .replaceAll(" ", "-")
              .replaceAll("/", "-")
          : "team"
        : data?.awayTeam
        ? data?.awayTeam?.name
            .trim()
            .toLowerCase()
            .replaceAll(" ", "-")
            .replaceAll("/", "-")
        : "team";
    navigate(
      `/teamsports/${params?.type}/statistics/team/${teamSlug}/${
        params?.SportId
      }/${params?.tournamentId}/${
        type === "homeTeam" ? data?.homeTeamId : data?.awayTeamId
      }`
    );
  };
  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        {matchLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <Box className="soccer-fixture-info-wrap">
            <Box className="fixture-info-header">
              <Box className="page-bredcrumn-wrap">
                <Box className="bredcrumn-wrap">
                  <Breadcrumbs
                    separator="/"
                    aria-label="breadcrumb"
                    className="breadcrumb"
                  >
                    <Link underline="hover" color="inherit" to="/">
                      {localesData?.MENU?.HOME}
                    </Link>
                    <Link underline="hover" color="inherit" to="#">
                      {localesData?.MENU?.SPORTS}
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      to={`/teamsports/soccer/odds/0/false`}
                    >
                      soccer
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      to={`/teamsports/soccer/statistics/${
                        singleMatchData?.SoccerTournament?.Scd
                          ? singleMatchData?.SoccerTournament?.Scd
                          : "tournament"
                      }/info/${singleMatchData?.SportId}/${
                        singleMatchData?.SoccerTournamentId
                      }   `}
                    >
                      {singleMatchData?.SoccerTournament?.name}
                    </Link>
                    <Typography>{singleMatchData?.eventName}</Typography>
                  </Breadcrumbs>
                </Box>
              </Box>
              <Box className="d-flex align-center header-wrap">
                <Box className="d-flex align-center header-text">
                  <Typography variant="h1">
                    {singleMatchData?.eventName}
                  </Typography>
                  {teamScore?.Epr === 1 && (
                    <span className="live-indicator">LIVE</span>
                  )}
                </Box>

                {/* <Box className="samrtBook-button-wrap">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="samrtBook-button"
                  >
                    <span style={{ display: "flex" }}>
                      <Plus />{" "}
                    </span>{" "}
                    <span style={{ paddingLeft: "8px" }}>
                      {localesData?.SMARTBOOK?.ADD_TO_MY_SMARTBOOK}
                    </span>
                  </Button>
                </Box> */}
              </Box>
            </Box>

            <Box className="match-point-Score-board">
              {/* <SoccerCard theme="dark-theme" /> */}
              <Box className="dark-theme match-details-wrap">
                {teamScore?.Epr === 1 && (
                  <Box className="divider-indicator"></Box>
                )}
                <Box className="card-wrap">
                  <Box className="d-flex align-center justify-space-around">
                    <Box className="sport-team-name d-flex align-center">
                      <Box className="sport-team-img">
                        <img
                          src={
                            singleMatchData?.homeTeam?.flag
                              ? Config.mediaURL +
                                singleMatchData?.homeTeam?.flag
                              : Brisbane
                          }
                          alt="img"
                        />
                      </Box>
                      <Box>
                        <Typography
                          className="sport-team-name-text fs-16 cursor-pointer"
                          onClick={() =>
                            handleNavigate(singleMatchData, "homeTeam")
                          }
                        >
                          {singleMatchData?.homeTeam?.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="sport-score-wrap">
                      <Box>
                        <Typography className="fs-18">
                          {teamScore?.Epr
                            ? teamScore?.Epr === 0
                              ? moment(teamScore?.Esd).format("HH:mm")
                              : teamScore?.Tr1 + " - " + teamScore?.Tr2
                            : moment(singleMatchData?.startTime).format(
                                "HH:mm"
                              )}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="fs-16">
                          {teamScore?.Epr
                            ? teamScore?.Epr === 0
                              ? moment(teamScore?.Esd)?.format("DD MMM")
                              : getMatchStatus(teamScore?.Eps)
                            : moment(singleMatchData?.startTime)?.format(
                                "DD MMM"
                              )}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="sport-team-name d-flex align-center">
                      <Box className="sport-team-img">
                        <img
                          src={
                            singleMatchData?.awayTeam?.flag
                              ? Config.mediaURL +
                                singleMatchData?.awayTeam?.flag
                              : Melbourne
                          }
                          alt="img"
                        />
                      </Box>
                      <Box>
                        <Typography
                          className="sport-team-name-text fs-16 cursor-pointer"
                          onClick={() =>
                            handleNavigate(singleMatchData, "awayTeam")
                          }
                        >
                          {singleMatchData?.awayTeam?.name}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="team-sports-tab">
              <Tabs
                value={tabvalue}
                variant="scrollable"
                scrollButtons="off"
                indicatorColor="primary"
                textColor="primary"
                className="teamsport-tab-detail"
              >
                {NewTabData?.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Tab
                        label={
                          <Box className="tab-label">
                            <span className="label-name">{item?.name}</span>
                            <span className="label-icon">{item?.icon}</span>
                          </Box>
                        }
                        value={item?.id}
                        className={item?.id === tabvalue ? "active " : ""}
                        onChange={(event, newValue) =>
                          handleTabChange(event, item?.id)
                        }
                      />
                    </Box>
                  );
                })}
              </Tabs>
            </Box>
            {tabvalue === 0 ? (
              <InfoFixture
                fixtureInfoData={fixtureInfoData}
                singleMatchData={singleMatchData}
              />
            ) : tabvalue === 1 ? (
              <InfoSummary
                fixtureInfoData={fixtureInfoData}
                singleMatchData={singleMatchData}
              />
            ) : tabvalue === 2 ? (
              <MatchStats
                fixtureInfoData={fixtureInfoData}
                singleMatchData={singleMatchData}
              />
            ) : tabvalue === 3 ? (
              <LineUpInfo
                fixtureInfoData={fixtureInfoData}
                singleMatchData={singleMatchData}
              />
            ) : tabvalue === 4 ? (
              <InfoTable singleMatchData={singleMatchData} />
            ) : tabvalue === 5 ? (
              <InfoNews individualTeamData={individualTeamData} />
            ) : tabvalue === 6 ? (
              <InfoH2H
                fixtureInfoData={fixtureInfoData}
                singleMatchData={singleMatchData}
              />
            ) : tabvalue === 7 ? (
              <InfoOddsComparison
                pageHeadingData={pageHeadingData}
                individualTeamData={individualTeamData}
                MarketName={MarketName}
                BookkeeperData={BookkeeperData}
                fetchIndividualMarket={fetchIndividualMarket}
              />
            ) : tabvalue === 8 ? (
              <InfoOddsFluctuation
                fixtureInfoData={fixtureInfoData}
                individualTeamData={individualTeamData}
                teamSportType={params?.type}
                BookkeeperData={BookkeeperData}
              />
            ) : (
              ""
            )}
            <OurPartner />
          </Box>
        )}
      </Box>
    </>
  );
};

export default SoccerFixtureInfoSport;
