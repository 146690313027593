import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@material-ui/core";
import { Tooltip as MuiTooltip } from "@material-ui/core";
import { Config } from "../../../../../helpers/context/config";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { ToastContainer, toast } from "react-toastify";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import Bet365 from "../../../../../assets/images/bookmakers/bet365-thumb.svg";

const TeamData = [
  {
    id: 1,
    team: "hometeam"
  },
  {
    id: 2,
    team: "awayteam"
  }
];

const InfoOddsFluctuation = ({
  fixtureInfoData,
  individualTeamData,
  teamSportType,
  BookkeeperData
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const BetslipData = useSelector(state => state?.reduxData?.BetSlipData);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tabvalue, setTabValue] = useState(1);

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const data = [
    {
      odds: 3.0
    },
    {
      odds: 3.5
    },
    {
      odds: 3.85
    },
    {
      odds: 4.0
    },
    {
      odds: 4.5
    },
    {
      odds: 4.3
    },
    {
      odds: 3.98
    }
  ];

  const data1 = [
    {
      odds: 1.0
    },
    {
      odds: 0
    },
    {
      odds: 3.85
    },
    {
      odds: 4.0
    },
    {
      odds: 4.5
    },
    {
      odds: 2.5
    },
    {
      odds: 3.98
    }
  ];
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Brisbane} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Brisbane} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Melbourne} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Melbourne} alt="Odds Icon" />;
      }
    }
  };

  const customTooltipContent = ({ payload }) => {
    if (payload && payload[0]) {
      const value = payload[0].value;
      return <Box className="tool-tip-wrap">{` ${value}`}</Box>;
    }
    return null;
  };

  const infoLineChart = (
    graphData,
    oddsValue,
    BookKeeperId,
    fullData,
    teamData
  ) => {
    return (
      <>
        <LineChart
          width={500}
          height={300}
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis />
          <Tooltip content={customTooltipContent} />
          <Line type="monotone" dataKey="odds" stroke="#1F78B4" />
        </LineChart>
        <Box className="d-flex align-center odds-value-wrap">
          <Box className="odds-value">
            <span>
              {fetchClickableOdds(
                oddsValue,
                BookKeeperId,
                "header",
                teamData,
                fullData,
                "betslip"
              )}
            </span>
          </Box>
          {oddsicon(BookKeeperId, "header", teamData, fullData)}
        </Box>
      </>
    );
  };

  const fetchFluctuationGraph = (data, team) => {
    let selectedId = null;
    let allTeamOdds =
      teamSportType === "cricket"
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : teamSportType === "basketball"
        ? data?.NBABetOffers?.[0]?.NBAOdds
        : teamSportType === "americanfootball"
        ? data?.AFLBetOffers?.[0]?.AFLOdds
        : teamSportType === "australianrules"
        ? data?.ARBetOffers?.[0]?.AROdds
        : teamSportType === "golf"
        ? data?.GolfBetOffers?.[0]?.GolfOdds
        : teamSportType === "tennis"
        ? data?.TennisBetOffers?.[0]?.TennisOdds
        : teamSportType === "baseball"
        ? data?.BaseballBetOffers?.[0]?.BaseballOdds
        : teamSportType === "icehockey"
        ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
        : teamSportType === "boxing"
        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
        : teamSportType === "mma"
        ? data?.MMABetOffers?.[0]?.MMAOdds
        : teamSportType === "soccer"
        ? data?.SoccerBetOffers?.[0]?.SoccerOdds
        : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter(item => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.homeTeamId
        : teamSportType === "basketball"
        ? item?.NBATeamId == data?.homeTeamId
        : teamSportType === "americanfootball"
        ? item?.AFLTeamId == data?.homeTeamId
        : teamSportType === "australianrules"
        ? item?.ARTeamId == data?.homeTeamId
        : teamSportType === "golf"
        ? item?.GolfTeamId == data?.homeTeamId
        : teamSportType === "tennis"
        ? item?.TennisTeamId == data?.homeTeamId
        : teamSportType === "baseball"
        ? item?.BaseballTeamId == data?.homeTeamId
        : teamSportType === "icehockey"
        ? item?.IceHockeyTeamId == data?.homeTeamId
        : teamSportType === "boxing"
        ? item?.BoxingTeamId == data?.homeTeamId
        : teamSportType === "mma"
        ? item?.MMATeamId == data?.homeTeamId
        : teamSportType === "soccer"
        ? item?.SoccerTeamId == data?.homeTeamId
        : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter(item => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.awayTeamId
        : teamSportType === "basketball"
        ? item?.NBATeamId == data?.awayTeamId
        : teamSportType === "americanfootball"
        ? item?.AFLTeamId == data?.awayTeamId
        : teamSportType === "australianrules"
        ? item?.ARTeamId == data?.awayTeamId
        : teamSportType === "golf"
        ? item?.GolfTeamId == data?.awayTeamId
        : teamSportType === "tennis"
        ? item?.TennisTeamId == data?.awayTeamId
        : teamSportType === "baseball"
        ? item?.BaseballTeamId == data?.awayTeamId
        : teamSportType === "icehockey"
        ? item?.IceHockeyTeamId == data?.awayTeamId
        : teamSportType === "boxing"
        ? item?.BoxingTeamId == data?.awayTeamId
        : teamSportType === "mma"
        ? item?.MMATeamId == data?.awayTeamId
        : teamSportType === "soccer"
        ? item?.SoccerTeamId == data?.awayTeamId
        : item?.RLTeamId == data?.awayTeamId;
    });

    let teamInfo =
      team === "hometeam"
        ? HomeTeamOdds
        : team === "awayteam"
        ? AwayTeamOdds
        : [];
    // let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;
    const teamData = teamInfo;
    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);

    if (maxno !== -1) {
      if (maxno !== 0) {
        let bookkeeperid = teamData
          ?.map(obj => {
            if (obj?.odd === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter(x => x !== undefined);
        selectedId = bookkeeperid?.[0];
      } else {
        selectedId = null;
      }
    } else {
      selectedId = null;
    }

    if (selectedId) {
      const selectedFilterData = teamData?.filter(item => {
        return item?.BookKeeperId === selectedId;
      });
      let FlucData = [];
      const selectedGraphIdData = selectedFilterData?.[0]?.oddFlucs?.map(
        odds => {
          return FlucData?.push({
            odds: odds?.odd
          });
        }
      );

      return FlucData?.length > 0 ? (
        <>{infoLineChart(FlucData, maxno, selectedId, data, teamData)}</>
      ) : (
        <Box className="nodata"> No Data Available</Box>
      );
    } else {
      return <Box className="nodata"> No Data Available</Box>;
    }
  };

  const oddsicon = (BookKeeperId, type, item, fullData) => {
    const Identifiers =
      params?.type === "cricket"
        ? fullData?.CricketIdentifiers
        : params?.type === "basketball"
        ? fullData?.NBAIdentifiers
        : params?.type === "americanfootball"
        ? fullData?.AFLIdentifiers
        : params?.type === "australianrules"
        ? fullData?.ARIdentifiers
        : params?.type === "golf"
        ? fullData?.GolfIdentifiers
        : params?.type === "tennis"
        ? fullData?.TennisIdentifiers
        : params?.type === "baseball"
        ? fullData?.BaseballIdentifiers
        : params?.type === "icehockey"
        ? fullData?.IceHockeyIdentifiers
        : params?.type === "boxing"
        ? fullData?.BoxingIdentifiers
        : params?.type === "mma"
        ? fullData?.MMAIdentifiers
        : params?.type === "soccer"
        ? fullData?.SoccerIdentifiers
        : fullData?.RLIdentifiers;
    const newData = Identifiers?.map(obj => {
      const BookkeeperObj = obj?.ApiProvider?.BookKeeperProviders?.filter(
        item => {
          return item?.BookKeeperId === BookKeeperId;
        }
      );
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null
      };
    });
    const filteredData = newData
      ?.filter?.(obj => obj?.BookKeeperId === BookKeeperId)
      ?.filter(obj => obj?.ApiProviderId !== 17);

    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    if (BookKeeperId === 8) {
      const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.filter(
        ele => ele?.providerMarketId && ele?.providerParticipantId
      );
      return (
        <a
          href={
            Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link bookmaker-wrap"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
            alt="Odds Icon"
          />
        </a>
      );
    } else {
      return (
        <a
          href={
            filteredData?.length > 0 && filteredData?.[0]?.url
              ? filteredData?.[0]?.url + `?Referrer=SmartB`
              : iconData?.affiliate_link
          }
          target="_blank"
          rel="noopener noreferrer"
          className="bookmaker-wrap"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
            alt="Odds Icon"
          />
        </a>
      );
    }
  };

  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    Isscore
  ) => {
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    const oddsInfo = BookKeeperData?.[0];
    let isAdded = BetslipData?.some(el => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      )
        return true;
      else return false;
    });
    // if (BookKeeperId === 8) {
    //   const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

    //   const Odds = Bet365Data?.filter(
    //     ele => ele?.providerMarketId && ele?.providerParticipantId
    //   );
    //   return (
    //     <a
    //       href={
    //         Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
    //           ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
    //           : `${iconData?.affiliate_link}`
    //       }
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       className="odds-link"
    //     >
    //       {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
    //     </a>
    //   );
    // } else {
    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      <>
        {odds ? (
          <span
            className="odds-btn cursor-pointer"
            onClick={() => {
              IsBetslip === "nobetslip"
                ? handleBookkeeperCounter(BookKeeperId, type)
                : isAdded
                ? handleDeleteBetslip(BookKeeperId, item, fulldata)
                : handleAddToBetSlip(
                    BookKeeperId,
                    type,
                    item,
                    fulldata,
                    Isscore
                  );
            }}
          >
            <span
              className={
                isAdded && IsBetslip === "betslip"
                  ? "betslip-added odds-link"
                  : "odds-link"
              }
            >
              {IsBetslip === "betslip" && !Isscore ? (
                <MuiTooltip title="Bet" className="bet-tooltip" placement="top">
                  <span className="bet-now">
                    {" "}
                    {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
                  </span>
                </MuiTooltip>
              ) : (
                <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
              )}
            </span>
          </span>
        ) : (
          <span className="odds-btn">NOA</span>
        )}
      </>
      // {/* <span
      //   className="odds-link"
      //   onClick={() => handleAddToBetSlip(BookKeeperId, type, item, fulldata)}
      //   //  onClick={() => { IsBetslip === "nobetslip" ? handleBookkeeperCounter(BookKeeperId, type) : handleAddToBetSlip(BookKeeperId, type, item, fulldata) }}
      // >
      //   {Number(odds).toFixed(2)}
      // </span> */}

      // </a>
    );
    // }
  };

  const handleBookkeeperCounter = async (BookKeeperId, type) => {
    let SportId =
      params?.type === "cricket"
        ? 4
        : params?.type === "rugbyleague"
        ? 12
        : params?.type === "rugbyunion"
        ? 13
        : params?.type === "basketball"
        ? 10
        : params?.type === "americanfootball"
        ? 15
        : params?.type === "australianrules"
        ? 9
        : params?.type === "golf"
        ? 16
        : params?.type === "tennis"
        ? 7
        : params?.type === "baseball"
        ? 11
        : params?.type === "icehockey"
        ? 17
        : params?.type === "boxing"
        ? 6
        : params?.type === "mma"
        ? 5
        : params?.type === "soccer"
        ? 8
        : 14;
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(SportId)
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleDeleteBetslip = async (BookKeeperId, item, fulldata) => {
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    const oddsInfo = BookKeeperData?.[0];
    let delslip = BetslipData?.filter(el => {
      return (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      );
    })?.[0];

    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        let chageData = BetslipData?.filter(obj => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };

  const handleAddToBetSlip = async (
    BookKeeperId,
    type,
    item,
    fulldata,
    Isscore
  ) => {
    handleBookkeeperCounter(BookKeeperId, type);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    if (isLogin) {
      if (!Isscore) {
        const BookKeeperData = item?.filter(
          obj => obj?.BookKeeperId === BookKeeperId
        );
        const oddsInfo = BookKeeperData?.[0];

        let passTeamId =
          params?.type === "cricket"
            ? oddsInfo?.CricketTeamId
              ? oddsInfo?.CricketTeamId
              : oddsInfo?.CricketOddLabelId
              ? oddsInfo?.CricketOddLabelId
              : oddsInfo?.CricketPlayerId
            : params?.type === "basketball"
            ? oddsInfo?.NBATeamId
              ? oddsInfo?.NBATeamId
              : oddsInfo?.NBAOddLabelId
              ? oddsInfo?.NBAOddLabelId
              : oddsInfo?.NBAPlayerId
            : params?.type === "americanfootball"
            ? oddsInfo?.AFLTeamId
              ? oddsInfo?.AFLTeamId
              : oddsInfo?.AFLOddLabelId
              ? oddsInfo?.AFLOddLabelId
              : oddsInfo?.AFLPlayerId
            : params?.type === "australianrules"
            ? oddsInfo?.ARTeamId
              ? oddsInfo?.ARTeamId
              : oddsInfo?.AROddLabelId
              ? oddsInfo?.AROddLabelId
              : oddsInfo?.ARPlayerId
            : params?.type === "golf"
            ? oddsInfo?.GolfTeamId
              ? oddsInfo?.GolfTeamId
              : oddsInfo?.GolfOddLabelId
              ? oddsInfo?.GolfOddLabelId
              : oddsInfo?.GolfPlayerId
            : params?.type === "tennis"
            ? oddsInfo?.TennisTeamId
              ? oddsInfo?.TennisTeamId
              : oddsInfo?.TennisOddLabelId
              ? oddsInfo?.TennisOddLabelId
              : oddsInfo?.TennisPlayerId
            : params?.type === "baseball"
            ? oddsInfo?.BaseballTeamId
              ? oddsInfo?.BaseballTeamId
              : oddsInfo?.BaseballOddLabelId
              ? oddsInfo?.BaseballOddLabelId
              : oddsInfo?.BaseballPlayerId
            : params?.type === "icehockey"
            ? oddsInfo?.IceHockeyTeamId
              ? oddsInfo?.IceHockeyTeamId
              : oddsInfo?.IceHockeyOddLabelId
              ? oddsInfo?.IceHockeyOddLabelId
              : oddsInfo?.IceHockeyPlayerId
            : params?.type === "boxing"
            ? oddsInfo?.BoxingTeamId
              ? oddsInfo?.BoxingTeamId
              : oddsInfo?.BoxingOddLabelId
              ? oddsInfo?.BoxingOddLabelId
              : oddsInfo?.BoxingPlayerId
            : params?.type === "mma"
            ? oddsInfo?.MMATeamId
              ? oddsInfo?.MMATeamId
              : oddsInfo?.MMAOddLabelId
              ? oddsInfo?.MMAOddLabelId
              : oddsInfo?.MMAPlayerId
            : params?.type === "soccer"
            ? oddsInfo?.SoccerTeamId
              ? oddsInfo?.SoccerTeamId
              : oddsInfo?.SoccerOddLabelId
              ? oddsInfo?.SoccerOddLabelId
              : oddsInfo?.SoccerPlayerId
            : oddsInfo?.RLTeamId
            ? oddsInfo?.RLTeamId
            : oddsInfo?.RLOddLabelId
            ? oddsInfo?.RLOddLabelId
            : oddsInfo?.RLPlayerId;

        let payload = {
          sportId: fulldata?.SportId,
          eventId: fulldata?.id,
          teamId: passTeamId,
          bookKeeperId: BookKeeperId,
          betOfferId: oddsInfo?.id
        };
        try {
          const { status, data } = await axiosInstance.post(
            `betSlipCard/BetSlipCard`,
            payload
          );
          if (status === 200) {
            toast.success(data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored"
            });

            const addedData = [...BetslipData, data?.result];
            dispatch(fetchBetSlipData(addedData));
            dispatch(fetchBetSlipCount(addedData?.length));
          } else {
          }
        } catch (err) {
          console.log("error", err);
        }
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };
  return (
    <>
      <Box className="fluctuation-wrap">
        {screenWidth >= 1023 ? (
          <>
            <Box className="d-flex align-center card-heading">
              <Box className="fluctuation-card-heading">
                <Box className="d-flex align-center team-wrap">
                  <span className="team-img-wrap">
                    {fetchTeamlogo(fixtureInfoData, "hometeam")}
                  </span>
                  <span className="team-name medium fluctuation-team ">
                    {fixtureInfoData?.homeTeam?.name
                      ? fixtureInfoData?.homeTeam?.name
                      : ""}
                  </span>
                </Box>
              </Box>
              <Box className="fluctuation-card-heading">
                <Box className="d-flex align-center team-wrap">
                  <span className="team-img-wrap">
                    {fetchTeamlogo(fixtureInfoData, "awayteam")}
                  </span>
                  <span className="team-name medium fluctuation-team ">
                    {fixtureInfoData?.awayTeam?.name
                      ? fixtureInfoData?.awayTeam?.name
                      : ""}
                  </span>
                </Box>
              </Box>
            </Box>
            <Box className="d-flex align-center fluctuation-graph-wrap">
              <Box className="fluctuation-graph">
                {fetchFluctuationGraph(fixtureInfoData, "hometeam")}
              </Box>
              <Box className="fluctuation-graph">
                {fetchFluctuationGraph(fixtureInfoData, "awayteam")}
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Tabs
              value={tabvalue}
              variant="scrollable"
              scrollButtons="off"
              indicatorColor="primary"
              textColor="primary"
              className="lineup-tab-detail"
            >
              {TeamData?.map((item, index) => {
                return (
                  <Box key={index}>
                    <Tab
                      label={
                        <Box className="d-flex align-center team-wrap">
                          <span className="team-img-wrap">
                            {fetchTeamlogo(fixtureInfoData, item?.team)}
                          </span>
                          <span className="team-name medium lineup-team ">
                            {item?.team === "hometeam"
                              ? fixtureInfoData?.homeTeam?.name
                                ? fixtureInfoData?.homeTeam?.name
                                : ""
                              : fixtureInfoData?.awayTeam?.name
                              ? fixtureInfoData?.awayTeam?.name
                              : ""}
                          </span>
                        </Box>
                      }
                      value={item?.id}
                      className={item?.id === tabvalue ? "active " : ""}
                      onChange={(event, newValue) =>
                        handleTabChange(event, item?.id)
                      }
                    />
                  </Box>
                );
              })}
            </Tabs>
            {tabvalue === 1 ? (
              <Box className="d-flex align-center fluctuation-graph-wrap">
                <Box className="fluctuation-graph">
                  {fetchFluctuationGraph(fixtureInfoData, "hometeam")}
                </Box>
              </Box>
            ) : (
              <Box className="d-flex align-center fluctuation-graph-wrap">
                <Box className="fluctuation-graph">
                  {fetchFluctuationGraph(fixtureInfoData, "awayteam")}
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default InfoOddsFluctuation;
