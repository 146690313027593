import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Typography } from "@material-ui/core";
import { fetchFromStorage } from "src/helpers/context";
import { useSelector } from "react-redux";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import { ReactComponent as Plus } from "../../../assets/images/icons/plus.svg";
import { ReactComponent as Minus } from "../../../assets/images/icons/minus.svg";
import AdBannner from "../AdBanner/AdBanner";
import "./tippingFAQs.scss";
import { Link } from "react-router-dom";
import OurPartner from "../ourPartners";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

const FAQsTippingPage = () => {
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tippingFAQsList, setTippingFAQs] = useState([]);
  const [tippingFAQsCount, setTippingFAQsCount] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const [offset, setOffset] = useState(0);
  const rowPerPage = 20;

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  useEffect(() => {
    if (ReduxAdsData) {
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  useEffect(() => {
    fetchTippingFAQsDetails(0);
  }, []);

  const fetchTippingFAQsDetails = async offset => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tippingFaqs/faqs?limit=${rowPerPage}&offset=${offset}`
      );
      if (status === 200) {
        console.log("objectDatadata", data?.data);
        let count = data?.count / rowPerPage;
        setLoading(false);
        setTippingFAQs(data?.data);
        setTippingFAQsCount(Math.ceil(count));
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleOnScrollBottomMatch = async offset => {
    try {
      const { status, data } = await axiosInstance.get(
        `/tippingFaqs/faqs?limit=${rowPerPage}&offset=${offset}`
      );
      if (status === 200) {
        setOffset(offset);
        setTippingFAQs([...tippingFAQsList, ...data?.data]);
      }
    } catch (error) {}
  };

  const handleToggle = index => {
    setActiveIndex(prevIndex => (prevIndex === index ? null : index));
  };

  return (
    <>
      <Box className="banner-img-fix">
        {fetchPageHeaderAds("218px", banner)}
      </Box>
      <Box className="tipping-FAQs-wrap">
        <Box className="tipping-FAQs-data-header">
          <Box className="bredcrumn-details">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  Home
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  to=""
                  className="cursor-default"
                >
                  Tipping Competition
                </Link>
                <Typography>Tipping FAQs</Typography>
              </Breadcrumbs>
            </Box>
          </Box>
          <Typography variant="h1">Tipping FAQs</Typography>
        </Box>
        <Box className="tipping-FAQs-details-wrap">
          {loading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : (
            <>
              <InfiniteScroll
                dataLength={tippingFAQsList?.length}
                next={() => handleOnScrollBottomMatch(offset + rowPerPage)}
                hasMore={
                  tippingFAQsCount !== 0 &&
                  tippingFAQsCount !== Math.ceil(offset / rowPerPage + 1)
                }
                loader={
                  <div className="allsport-loader-center ">
                    <Loader />
                  </div>
                }
              >
                <Box>
                  {tippingFAQsList?.length > 0 ? (
                    tippingFAQsList?.map((item, index) => {
                      return (
                        <>
                          <Box
                            className={
                              activeIndex === index
                                ? "FAQs-que-qns-wrap border-left-dark"
                                : "FAQs-que-qns-wrap border-left-light"
                            }
                          >
                            <Box
                              className="question-wrap"
                              onClick={() => handleToggle(index)}
                            >
                              <span className="icon-wrap">
                                {activeIndex === index ? (
                                  <Minus />
                                ) : (
                                  <Plus className="plus-icon" />
                                )}
                              </span>
                              <span
                                className={
                                  activeIndex === index
                                    ? "question-test dark-color"
                                    : "question-test light-color"
                                }
                              >
                                {item?.questions}
                              </span>
                            </Box>
                            {activeIndex === index && (
                              <Box className="answer-wrap">
                                <Typography className="answer-text">
                                  {item?.answer}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Box>
              </InfiniteScroll>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default FAQsTippingPage;
