import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tabs,
  Tab
} from "@material-ui/core";
import moment from "moment-timezone";
import Bucks from "../../../../assets/images/Sport/Bucks.png";
import Pacers from "../../../../assets/images/Sport/Pacers.png";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import NoDataComp from "../../UI/NoData";

const betsMoney = [
  {
    id: 0,
    name: "bets"
  },
  { id: 1, name: "Money" }
];

const SportMatchup = ({ matchUpData, matchupLoading, teamSportType }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [value, setValue] = useState(0);
  const [Matchup, setMatchup] = useState(
    teamSportType === "basketball"
      ? "NBAMatchups"
      : teamSportType === "americanfootball"
      ? "AFLMatchups"
      : teamSportType === "baseball"
      ? "BaseballMatchups"
      : "IceHockeyMatchups"
  );
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleChange = (event, value) => {
    setValue(value);
  };

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  return (
    <>
      {matchupLoading ? (
        <div className="allsport-loader-center ">
          <Loader />
        </div>
      ) : matchUpData?.length > 0 ? (
        screenWidth > 1023 ? (
          matchUpData?.map((obj, index) => {
            return (
              <>
                {obj?.[Matchup]?.map(item => (
                  <Box className="sport-match-table-wrap">
                    <Box className="table-header-wrap">
                      <Box className="herder-border">
                        <Typography variant="h1">
                          {fetchDayName(obj?.startTime)}{" "}
                          {moment
                            .utc(obj?.startTime)
                            .local()
                            .format("DD/MM/YYYY")}
                        </Typography>
                      </Box>
                    </Box>
                    <Table className="sport-match-table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="pl-33 table-head-left">
                            {/* {item?.isLive ? (
                          <span className="live-btn">Live</span>
                        ) : (
                          <span className="time">10:00 PM</span>
                        )} */}
                            <Box className="time-indicator">
                              {moment
                                .utc(obj?.startTime)
                                .local()
                                .format("hh:mm A")}
                              <span className="sport-indicator-btn">
                                {teamSportType === "americanfootball"
                                  ? obj?.AFLTournament?.name
                                  : teamSportType === "baseball"
                                  ? obj?.BaseballTournament?.name
                                  : teamSportType === "icehockey"
                                  ? obj?.IceHockeyTournament?.name
                                  : teamSportType === "basketball"
                                  ? obj?.NBATournament?.name
                                  : ""}
                              </span>
                            </Box>
                          </TableCell>
                          <TableCell>Spread</TableCell>
                          <TableCell>Bets</TableCell>
                          <TableCell className="border-right">Money</TableCell>
                          <TableCell>Total</TableCell>
                          <TableCell>Bets</TableCell>
                          <TableCell className="border-right">Money</TableCell>
                          <TableCell>ML</TableCell>
                          <TableCell>Bets</TableCell>
                          <TableCell className="pr-33">Money</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className="team-wrap-cell pl-33">
                            <Box className="team-wrap">
                              <span className="team-img">
                                <img src={item?.homeRecord?.logo} alt="Logo" />
                              </span>
                              <Box className="team-name">
                                <span className="team-event">
                                  {item?.homeRecord?.shortName}
                                </span>
                                <span className="team-number">
                                  {item?.homeRecord?.rotation}
                                </span>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box className="d-flex-col">
                              <span> {item?.homeRecord?.records?.spread}</span>
                              {/* <span>ATS</span> */}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <span
                              className={
                                item?.bettingTrendsConsensus?.spread
                                  ?.homeOrUnderBettingVolume
                                  ? item?.bettingTrendsConsensus?.spread
                                      ?.homeOrUnderBettingVolume >
                                    item?.bettingTrendsConsensus?.spread
                                      ?.awayOrOverBettingVolume
                                    ? "btn-blue"
                                    : "btn-gray"
                                  : "btn-gray"
                              }
                            >
                              {item?.bettingTrendsConsensus?.spread
                                ?.homeOrUnderBettingVolume
                                ? item?.bettingTrendsConsensus?.spread
                                    ?.homeOrUnderBettingVolume
                                : "N/A"}
                            </span>
                          </TableCell>
                          <TableCell className="border-right">
                            <span
                              className={
                                item?.bettingTrendsConsensus?.spread
                                  ?.homeOrUnderMoneyVolume
                                  ? item?.bettingTrendsConsensus?.spread
                                      ?.homeOrUnderMoneyVolume >
                                    item?.bettingTrendsConsensus?.spread
                                      ?.awayOrOverMoneyVolume
                                    ? "btn-blue"
                                    : "btn-gray"
                                  : "btn-gray"
                              }
                            >
                              {" "}
                              {item?.bettingTrendsConsensus?.spread
                                ?.homeOrUnderMoneyVolume
                                ? item?.bettingTrendsConsensus?.spread
                                    ?.homeOrUnderMoneyVolume
                                : "N/A"}
                            </span>
                          </TableCell>
                          <TableCell>
                            <Box className="d-flex-col">
                              <span>{item?.homeRecord?.records?.total}</span>
                              {/* <span>O/U</span> */}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <span
                              className={
                                item?.bettingTrendsConsensus?.total
                                  ?.homeOrUnderBettingVolume
                                  ? item?.bettingTrendsConsensus?.total
                                      ?.homeOrUnderBettingVolume >
                                    item?.bettingTrendsConsensus?.total
                                      ?.awayOrOverBettingVolume
                                    ? "btn-blue"
                                    : "btn-gray"
                                  : "btn-gray"
                              }
                            >
                              {item?.bettingTrendsConsensus?.total
                                ?.homeOrUnderBettingVolume
                                ? item?.bettingTrendsConsensus?.total
                                    ?.homeOrUnderBettingVolume
                                : "N/A"}
                            </span>
                          </TableCell>
                          <TableCell className="border-right">
                            <span
                              className={
                                item?.bettingTrendsConsensus?.total
                                  ?.homeOrUnderMoneyVolume
                                  ? item?.bettingTrendsConsensus?.total
                                      ?.homeOrUnderMoneyVolume >
                                    item?.bettingTrendsConsensus?.total
                                      ?.awayOrOverMoneyVolume
                                    ? "btn-blue"
                                    : "btn-gray"
                                  : "btn-gray"
                              }
                            >
                              {" "}
                              {item?.bettingTrendsConsensus?.total
                                ?.homeOrUnderMoneyVolume
                                ? item?.bettingTrendsConsensus?.total
                                    ?.homeOrUnderMoneyVolume
                                : "N/A"}
                            </span>
                          </TableCell>
                          <TableCell>
                            <Box className="d-flex-col">
                              <span>
                                {item?.homeRecord?.records?.moneyline}
                              </span>
                              {/* <span>S/U</span> */}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <span
                              className={
                                item?.bettingTrendsConsensus?.moneyline
                                  ?.homeOrUnderBettingVolume
                                  ? item?.bettingTrendsConsensus?.moneyline
                                      ?.homeOrUnderBettingVolume >
                                    item?.bettingTrendsConsensus?.moneyline
                                      ?.awayOrOverBettingVolume
                                    ? "btn-blue"
                                    : "btn-gray"
                                  : "btn-gray"
                              }
                            >
                              {item?.bettingTrendsConsensus?.moneyline
                                ?.homeOrUnderBettingVolume
                                ? item?.bettingTrendsConsensus?.moneyline
                                    ?.homeOrUnderBettingVolume
                                : "N/A"}
                            </span>
                          </TableCell>
                          <TableCell className="pr-33">
                            <span
                              className={
                                item?.bettingTrendsConsensus?.moneyline
                                  ?.homeOrUnderMoneyVolume
                                  ? item?.bettingTrendsConsensus?.moneyline
                                      ?.homeOrUnderMoneyVolume >
                                    item?.bettingTrendsConsensus?.moneyline
                                      ?.awayOrOverMoneyVolume
                                    ? "btn-blue"
                                    : "btn-gray"
                                  : "btn-gray"
                              }
                            >
                              {" "}
                              {item?.bettingTrendsConsensus?.moneyline
                                ?.homeOrUnderMoneyVolume
                                ? item?.bettingTrendsConsensus?.moneyline
                                    ?.homeOrUnderMoneyVolume
                                : "N/A"}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow className="teamsports-tablerow">
                          <TableCell className="team-wrap-cell pl-33">
                            <Box className="team-wrap">
                              <span className="team-img">
                                <img src={item?.awayRecord?.logo} alt="Logo" />
                              </span>
                              <Box className="team-name">
                                <span className="team-event">
                                  {item?.awayRecord?.shortName}
                                </span>
                                <span className="team-number">
                                  {item?.awayRecord?.rotation}
                                </span>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box className="d-flex-col">
                              <span>{item?.awayRecord?.records?.spread}</span>
                              {/* <span>ATS</span> */}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <span
                              className={
                                item?.bettingTrendsConsensus?.spread
                                  ?.awayOrOverBettingVolume
                                  ? item?.bettingTrendsConsensus?.spread
                                      ?.awayOrOverBettingVolume >
                                    item?.bettingTrendsConsensus?.spread
                                      ?.homeOrUnderBettingVolume
                                    ? "btn-blue"
                                    : "btn-gray"
                                  : "btn-gray"
                              }
                            >
                              {item?.bettingTrendsConsensus?.spread
                                ?.awayOrOverBettingVolume
                                ? item?.bettingTrendsConsensus?.spread
                                    ?.awayOrOverBettingVolume
                                : "N/A"}
                            </span>
                          </TableCell>
                          <TableCell className="border-right">
                            <span
                              className={
                                item?.bettingTrendsConsensus?.spread
                                  ?.awayOrOverMoneyVolume
                                  ? item?.bettingTrendsConsensus?.spread
                                      ?.awayOrOverMoneyVolume >
                                    item?.bettingTrendsConsensus?.spread
                                      ?.homeOrUnderMoneyVolume
                                    ? "btn-blue"
                                    : "btn-gray"
                                  : "btn-gray"
                              }
                            >
                              {item?.bettingTrendsConsensus?.spread
                                ?.awayOrOverMoneyVolume
                                ? item?.bettingTrendsConsensus?.spread
                                    ?.awayOrOverMoneyVolume
                                : "N/A"}
                            </span>
                          </TableCell>
                          <TableCell>
                            <Box className="d-flex-col">
                              <span>{item?.awayRecord?.records?.total}</span>
                              {/* <span>O/U</span> */}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <span
                              className={
                                item?.bettingTrendsConsensus?.total
                                  ?.awayOrOverBettingVolume
                                  ? item?.bettingTrendsConsensus?.total
                                      ?.awayOrOverBettingVolume >
                                    item?.bettingTrendsConsensus?.total
                                      ?.homeOrUnderBettingVolume
                                    ? "btn-blue"
                                    : "btn-gray"
                                  : "btn-gray"
                              }
                            >
                              {item?.bettingTrendsConsensus?.total
                                ?.awayOrOverBettingVolume
                                ? item?.bettingTrendsConsensus?.total
                                    ?.awayOrOverBettingVolume
                                : "N/A"}
                            </span>
                          </TableCell>
                          <TableCell className="border-right">
                            <span
                              className={
                                item?.bettingTrendsConsensus?.total
                                  ?.awayOrOverMoneyVolume
                                  ? item?.bettingTrendsConsensus?.total
                                      ?.awayOrOverMoneyVolume >
                                    item?.bettingTrendsConsensus?.total
                                      ?.homeOrUnderMoneyVolume
                                    ? "btn-blue"
                                    : "btn-gray"
                                  : "btn-gray"
                              }
                            >
                              {item?.bettingTrendsConsensus?.total
                                ?.awayOrOverMoneyVolume
                                ? item?.bettingTrendsConsensus?.total
                                    ?.awayOrOverMoneyVolume
                                : "N/A"}
                            </span>
                          </TableCell>
                          <TableCell>
                            <Box className="d-flex-col">
                              <span>
                                {item?.awayRecord?.records?.moneyline}
                              </span>
                              {/* <span>S/U</span> */}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <span
                              className={
                                item?.bettingTrendsConsensus?.moneyline
                                  ?.awayOrOverBettingVolume
                                  ? item?.bettingTrendsConsensus?.moneyline
                                      ?.awayOrOverBettingVolume >
                                    item?.bettingTrendsConsensus?.moneyline
                                      ?.homeOrUnderBettingVolume
                                    ? "btn-blue"
                                    : "btn-gray"
                                  : "btn-gray"
                              }
                            >
                              {item?.bettingTrendsConsensus?.moneyline
                                ?.awayOrOverBettingVolume
                                ? item?.bettingTrendsConsensus?.moneyline
                                    ?.awayOrOverBettingVolume
                                : "N/A"}
                            </span>
                          </TableCell>
                          <TableCell className="pr-33">
                            <span
                              className={
                                item?.bettingTrendsConsensus?.moneyline
                                  ?.awayOrOverMoneyVolume
                                  ? item?.bettingTrendsConsensus?.moneyline
                                      ?.awayOrOverMoneyVolume >
                                    item?.bettingTrendsConsensus?.moneyline
                                      ?.homeOrUnderMoneyVolume
                                    ? "btn-blue"
                                    : "btn-gray"
                                  : "btn-gray"
                              }
                            >
                              {item?.bettingTrendsConsensus?.moneyline
                                ?.awayOrOverMoneyVolume
                                ? item?.bettingTrendsConsensus?.moneyline
                                    ?.awayOrOverMoneyVolume
                                : "N/A"}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow className="sport-result-show">
                          <TableCell className="pl-33">
                            <Box className="d-flex-col">
                              <span>Consensus</span>
                              <span className="result-grey">Open</span>
                            </Box>
                          </TableCell>
                          <TableCell colSpan={3}>
                            <Box className="d-flex-col">
                              <span>
                                {item?.consensus?.consensusBetting?.spread
                                  ? item?.consensus?.consensusBetting?.spread
                                  : "N/A"}
                              </span>
                              <span className="result-grey">
                                {item?.openline?.openBetting?.spread
                                  ? item?.openline?.openBetting?.spread
                                  : "N/A"}
                              </span>
                            </Box>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Box className="d-flex-col text-center">
                              <span>
                                {item?.consensus?.consensusBetting?.total
                                  ? item?.consensus?.consensusBetting?.total
                                  : "N/A"}
                              </span>
                              <span className="result-grey">
                                {item?.openline?.openBetting?.total
                                  ? item?.openline?.openBetting?.total
                                  : "N/A"}
                              </span>
                            </Box>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell className=" text-center">
                            {" "}
                            <Box className="d-flex-col">
                              <span>
                                {item?.consensus?.consensusBetting?.moneyline
                                  ? item?.consensus?.consensusBetting?.moneyline
                                  : "N/A"}
                              </span>
                              <span className="result-grey">
                                {item?.openline?.openBetting?.moneyline
                                  ? item?.openline?.openBetting?.moneyline
                                  : "N/A"}
                              </span>
                            </Box>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell className="pr-33"></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                ))}
              </>
            );
          })
        ) : (
          <Box className="mobile-sport-match">
            <Box className="mobile-sport-wrap">
              <Box className="sport-tab">
                <Tabs
                  value={value}
                  variant="scrollable"
                  scrollButtons="off"
                  indicatorColor="primary"
                  textColor="primary"
                  className="sport-tab-detail"
                >
                  {betsMoney?.map((item, index) => {
                    return (
                      <Box>
                        <Tab
                          disableRipple
                          disableFocusRipple
                          label={item?.name}
                          value={item?.id}
                          className={item?.id == value ? "active" : ""}
                          onChange={(event, newValue) =>
                            handleChange(event, item?.id)
                          }
                        />
                      </Box>
                    );
                  })}
                </Tabs>
              </Box>
              {matchUpData?.map((obj, index) => {
                return (
                  <>
                    {obj?.[Matchup]?.map(item => {
                      return (
                        <>
                          <Box className="mobile-table-wrap">
                            {value === 0 ? (
                              <>
                                <Table className="mobile-table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        {/* {" "}
                                {item?.isLive ? (
                                  <span className="live-btn">Live</span>
                                ) : (
                                  <span className="time">10:00 PM</span>
                                )} */}
                                        <Box className="time-indicator-wrap">
                                          <Box>
                                            {moment
                                              .utc(obj?.startTime)
                                              .local()
                                              .format("hh:mm A")}
                                          </Box>
                                          <span className="sport-indicator-btn">
                                            {teamSportType ===
                                            "americanfootball"
                                              ? obj?.AFLTournament?.name
                                              : teamSportType === "baseball"
                                              ? obj?.BaseballTournament?.name
                                              : teamSportType === "icehockey"
                                              ? obj?.IceHockeyTournament?.name
                                              : teamSportType === "basketball"
                                              ? obj?.NBATournament?.name
                                              : ""}
                                          </span>
                                        </Box>
                                      </TableCell>
                                      <TableCell className="border-right">
                                        Spread
                                      </TableCell>
                                      <TableCell className="border-right">
                                        Total
                                      </TableCell>
                                      <TableCell>ML</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell className="team-wrap-cell">
                                        {" "}
                                        <Box className="team-wrap">
                                          <span className="team-img">
                                            <img
                                              src={item?.homeRecord?.logo}
                                              alt="Logo"
                                            />
                                          </span>
                                          <Box className="team-name">
                                            <span className="team-event">
                                              {item?.homeRecord?.shortName}
                                            </span>
                                            <span className="team-number">
                                              {item?.homeRecord?.rotation}
                                            </span>
                                          </Box>
                                        </Box>
                                      </TableCell>
                                      <TableCell className="border-right">
                                        <Box className="d-flex-col mobile-win-details">
                                          <span className="number-bm">
                                            {item?.homeRecord?.records?.spread}
                                          </span>
                                          <span
                                            className={
                                              item?.bettingTrendsConsensus
                                                ?.spread
                                                ?.homeOrUnderBettingVolume
                                                ? item?.bettingTrendsConsensus
                                                    ?.spread
                                                    ?.homeOrUnderBettingVolume >
                                                  item?.bettingTrendsConsensus
                                                    ?.spread
                                                    ?.awayOrOverBettingVolume
                                                  ? "btn-blue"
                                                  : "btn-gray"
                                                : "btn-gray"
                                            }
                                          >
                                            {item?.bettingTrendsConsensus
                                              ?.spread?.homeOrUnderBettingVolume
                                              ? item?.bettingTrendsConsensus
                                                  ?.spread
                                                  ?.homeOrUnderBettingVolume
                                              : "N/A"}
                                          </span>
                                        </Box>
                                      </TableCell>
                                      <TableCell className="border-right">
                                        <Box className="d-flex-col mobile-win-details">
                                          <span className="number-bm">
                                            {item?.homeRecord?.records?.total}
                                          </span>
                                          <span
                                            className={
                                              item?.bettingTrendsConsensus
                                                ?.total
                                                ?.homeOrUnderBettingVolume
                                                ? item?.bettingTrendsConsensus
                                                    ?.total
                                                    ?.homeOrUnderBettingVolume >
                                                  item?.bettingTrendsConsensus
                                                    ?.total
                                                    ?.awayOrOverBettingVolume
                                                  ? "btn-blue"
                                                  : "btn-gray"
                                                : "btn-gray"
                                            }
                                          >
                                            {item?.bettingTrendsConsensus?.total
                                              ?.homeOrUnderBettingVolume
                                              ? item?.bettingTrendsConsensus
                                                  ?.total
                                                  ?.homeOrUnderBettingVolume
                                              : "N/A"}
                                          </span>
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        <Box className="d-flex-col mobile-win-details">
                                          <span className="number-bm">
                                            {
                                              item?.homeRecord?.records
                                                ?.moneyline
                                            }
                                          </span>
                                          <span
                                            className={
                                              item?.bettingTrendsConsensus
                                                ?.moneyline
                                                ?.homeOrUnderBettingVolume
                                                ? item?.bettingTrendsConsensus
                                                    ?.moneyline
                                                    ?.homeOrUnderBettingVolume >
                                                  item?.bettingTrendsConsensus
                                                    ?.moneyline
                                                    ?.awayOrOverBettingVolume
                                                  ? "btn-blue"
                                                  : "btn-gray"
                                                : "btn-gray"
                                            }
                                          >
                                            {item?.bettingTrendsConsensus
                                              ?.moneyline
                                              ?.homeOrUnderBettingVolume
                                              ? item?.bettingTrendsConsensus
                                                  ?.moneyline
                                                  ?.homeOrUnderBettingVolume
                                              : "N/A"}
                                          </span>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="team-wrap-cell">
                                        {" "}
                                        <Box className="team-wrap">
                                          <span className="team-img">
                                            <img
                                              src={item?.awayRecord?.logo}
                                              alt="Logo"
                                            />
                                          </span>
                                          <Box className="team-name">
                                            <span className="team-event">
                                              {item?.awayRecord?.shortName}
                                            </span>
                                            <span className="team-number">
                                              {item?.awayRecord?.rotation}
                                            </span>
                                          </Box>
                                        </Box>
                                      </TableCell>
                                      <TableCell className="border-right">
                                        <Box className="d-flex-col mobile-win-details">
                                          <span className="number-bm">
                                            {item?.awayRecord?.records?.spread}
                                          </span>
                                          <span
                                            className={
                                              item?.bettingTrendsConsensus
                                                ?.spread
                                                ?.awayOrOverBettingVolume
                                                ? item?.bettingTrendsConsensus
                                                    ?.spread
                                                    ?.awayOrOverBettingVolume >
                                                  item?.bettingTrendsConsensus
                                                    ?.spread
                                                    ?.homeOrUnderBettingVolume
                                                  ? "btn-blue"
                                                  : "btn-gray"
                                                : "btn-gray"
                                            }
                                          >
                                            {item?.bettingTrendsConsensus
                                              ?.spread?.awayOrOverBettingVolume
                                              ? item?.bettingTrendsConsensus
                                                  ?.spread
                                                  ?.awayOrOverBettingVolume
                                              : "N/A"}
                                          </span>
                                        </Box>
                                      </TableCell>
                                      <TableCell className="border-right">
                                        <Box className="d-flex-col mobile-win-details">
                                          <span className="number-bm">
                                            {item?.awayRecord?.records?.total}
                                          </span>
                                          <span
                                            className={
                                              item?.bettingTrendsConsensus
                                                ?.total?.awayOrOverBettingVolume
                                                ? item?.bettingTrendsConsensus
                                                    ?.total
                                                    ?.awayOrOverBettingVolume >
                                                  item?.bettingTrendsConsensus
                                                    ?.total
                                                    ?.homeOrUnderBettingVolume
                                                  ? "btn-blue"
                                                  : "btn-gray"
                                                : "btn-gray"
                                            }
                                          >
                                            {item?.bettingTrendsConsensus?.total
                                              ?.awayOrOverBettingVolume
                                              ? item?.bettingTrendsConsensus
                                                  ?.total
                                                  ?.awayOrOverBettingVolume
                                              : "N/A"}
                                          </span>
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        <Box className="d-flex-col mobile-win-details">
                                          <span className="number-bm">
                                            {
                                              item?.awayRecord?.records
                                                ?.moneyline
                                            }
                                          </span>
                                          <span
                                            className={
                                              item?.bettingTrendsConsensus
                                                ?.moneyline
                                                ?.awayOrOverBettingVolume
                                                ? item?.bettingTrendsConsensus
                                                    ?.moneyline
                                                    ?.awayOrOverBettingVolume >
                                                  item?.bettingTrendsConsensus
                                                    ?.moneyline
                                                    ?.homeOrUnderBettingVolume
                                                  ? "btn-blue"
                                                  : "btn-gray"
                                                : "btn-gray"
                                            }
                                          >
                                            {item?.bettingTrendsConsensus
                                              ?.moneyline
                                              ?.awayOrOverBettingVolume
                                              ? item?.bettingTrendsConsensus
                                                  ?.moneyline
                                                  ?.awayOrOverBettingVolume
                                              : "N/A"}
                                          </span>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow className="mobile-sport-result">
                                      <TableCell>
                                        <Box className="d-flex-col">
                                          <span className="font-dark">
                                            Consensus
                                          </span>
                                          <span className="result-grey">
                                            Open
                                          </span>
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        <Box className="d-flex-col">
                                          <span className="font-dark">
                                            {" "}
                                            {item?.consensus?.consensusBetting
                                              ?.spread
                                              ? item?.consensus
                                                  ?.consensusBetting?.spread
                                              : "N/A"}
                                          </span>
                                          <span className="result-grey">
                                            {item?.openline?.openBetting?.spread
                                              ? item?.openline?.openBetting
                                                  ?.spread
                                              : "N/A"}
                                          </span>
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        <Box className="d-flex-col text-center">
                                          <span className="font-dark">
                                            {" "}
                                            {item?.consensus?.consensusBetting
                                              ?.total
                                              ? item?.consensus
                                                  ?.consensusBetting?.total
                                              : "N/A"}
                                          </span>
                                          <span className="result-grey">
                                            {" "}
                                            {item?.openline?.openBetting?.total
                                              ? item?.openline?.openBetting
                                                  ?.total
                                              : "N/A"}
                                          </span>
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        <Box className="d-flex-col text-center">
                                          <span className="font-dark">
                                            {item?.consensus?.consensusBetting
                                              ?.moneyline
                                              ? item?.consensus
                                                  ?.consensusBetting?.moneyline
                                              : "N/A"}
                                          </span>
                                          <span className="result-grey">
                                            {" "}
                                            {item?.openline?.openBetting
                                              ?.moneyline
                                              ? item?.openline?.openBetting
                                                  ?.moneyline
                                              : "N/A"}
                                          </span>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </>
                            ) : value === 1 ? (
                              <Table className="mobile-table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      {/* {" "}
                              {item?.isLive ? (
                                <span className="live-btn">Live</span>
                              ) : (
                                <span className="time">10:00 PM</span>
                              )} */}{" "}
                                      <Box className="time-indicator-wrap">
                                        <Box>
                                          {moment
                                            .utc(obj?.startTime)
                                            .local()
                                            .format("hh:mm A")}
                                        </Box>
                                        <span className="sport-indicator-btn">
                                          {teamSportType === "americanfootball"
                                            ? obj?.AFLTournament?.name
                                            : teamSportType === "baseball"
                                            ? obj?.BaseballTournament?.name
                                            : teamSportType === "icehockey"
                                            ? obj?.IceHockeyTournament?.name
                                            : teamSportType === "basketball"
                                            ? obj?.NBATournament?.name
                                            : ""}
                                        </span>
                                      </Box>
                                    </TableCell>
                                    <TableCell className="border-right">
                                      Spread
                                    </TableCell>
                                    <TableCell className="border-right">
                                      Total
                                    </TableCell>
                                    <TableCell>ML</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell className="team-wrap-cell">
                                      {" "}
                                      <Box className="team-wrap">
                                        <span className="team-img">
                                          <img
                                            src={item?.homeRecord?.logo}
                                            alt="Logo"
                                          />
                                        </span>
                                        <Box className="team-name">
                                          <span className="team-event">
                                            {item?.homeRecord?.shortName}
                                          </span>
                                          <span className="team-number">
                                            {item?.homeRecord?.rotation}
                                          </span>
                                        </Box>
                                      </Box>
                                    </TableCell>
                                    <TableCell className="border-right">
                                      <Box className="d-flex-col mobile-win-details">
                                        <span className="number-bm">
                                          {item?.homeRecord?.records?.spread}
                                        </span>
                                        <span
                                          className={
                                            item?.bettingTrendsConsensus?.spread
                                              ?.homeOrUnderMoneyVolume
                                              ? item?.bettingTrendsConsensus
                                                  ?.spread
                                                  ?.homeOrUnderMoneyVolume >
                                                item?.bettingTrendsConsensus
                                                  ?.spread
                                                  ?.awayOrOverMoneyVolume
                                                ? "btn-blue"
                                                : "btn-gray"
                                              : "btn-gray"
                                          }
                                        >
                                          {" "}
                                          {item?.bettingTrendsConsensus?.spread
                                            ?.homeOrUnderMoneyVolume
                                            ? item?.bettingTrendsConsensus
                                                ?.spread?.homeOrUnderMoneyVolume
                                            : "N/A"}
                                        </span>
                                      </Box>
                                    </TableCell>
                                    <TableCell className="border-right">
                                      <Box className="d-flex-col mobile-win-details">
                                        <span className="number-bm">
                                          {item?.homeRecord?.records?.total}
                                        </span>
                                        <span
                                          className={
                                            item?.bettingTrendsConsensus?.total
                                              ?.homeOrUnderMoneyVolume
                                              ? item?.bettingTrendsConsensus
                                                  ?.total
                                                  ?.homeOrUnderMoneyVolume >
                                                item?.bettingTrendsConsensus
                                                  ?.total?.awayOrOverMoneyVolume
                                                ? "btn-blue"
                                                : "btn-gray"
                                              : "btn-gray"
                                          }
                                        >
                                          {" "}
                                          {item?.bettingTrendsConsensus?.total
                                            ?.homeOrUnderMoneyVolume
                                            ? item?.bettingTrendsConsensus
                                                ?.total?.homeOrUnderMoneyVolume
                                            : "N/A"}
                                        </span>
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <Box className="d-flex-col mobile-win-details">
                                        <span className="number-bm">
                                          {item?.homeRecord?.records?.moneyline}
                                        </span>
                                        <span
                                          className={
                                            item?.bettingTrendsConsensus
                                              ?.moneyline
                                              ?.homeOrUnderMoneyVolume
                                              ? item?.bettingTrendsConsensus
                                                  ?.moneyline
                                                  ?.homeOrUnderMoneyVolume >
                                                item?.bettingTrendsConsensus
                                                  ?.moneyline
                                                  ?.awayOrOverMoneyVolume
                                                ? "btn-blue"
                                                : "btn-gray"
                                              : "btn-gray"
                                          }
                                        >
                                          {" "}
                                          {item?.bettingTrendsConsensus
                                            ?.moneyline?.homeOrUnderMoneyVolume
                                            ? item?.bettingTrendsConsensus
                                                ?.moneyline
                                                ?.homeOrUnderMoneyVolume
                                            : "N/A"}
                                        </span>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="team-wrap-cell">
                                      {" "}
                                      <Box className="team-wrap">
                                        <span className="team-img">
                                          <img
                                            src={item?.awayRecord?.logo}
                                            alt="Logo"
                                          />
                                        </span>
                                        <Box className="team-name">
                                          <span className="team-event">
                                            {item?.awayRecord?.shortName}
                                          </span>
                                          <span className="team-number">
                                            {item?.awayRecord?.rotation}
                                          </span>
                                        </Box>
                                      </Box>
                                    </TableCell>
                                    <TableCell className="border-right">
                                      <Box className="d-flex-col mobile-win-details">
                                        <span className="number-bm">
                                          {item?.awayRecord?.records?.spread}
                                        </span>
                                        <span
                                          className={
                                            item?.bettingTrendsConsensus?.spread
                                              ?.awayOrOverMoneyVolume
                                              ? item?.bettingTrendsConsensus
                                                  ?.spread
                                                  ?.awayOrOverMoneyVolume >
                                                item?.bettingTrendsConsensus
                                                  ?.spread
                                                  ?.homeOrUnderMoneyVolume
                                                ? "btn-blue"
                                                : "btn-gray"
                                              : "btn-gray"
                                          }
                                        >
                                          {item?.bettingTrendsConsensus?.spread
                                            ?.awayOrOverMoneyVolume
                                            ? item?.bettingTrendsConsensus
                                                ?.spread?.awayOrOverMoneyVolume
                                            : "N/A"}
                                        </span>
                                      </Box>
                                    </TableCell>
                                    <TableCell className="border-right">
                                      <Box className="d-flex-col mobile-win-details">
                                        <span className="number-bm">
                                          {item?.awayRecord?.records?.total}
                                        </span>
                                        <span
                                          className={
                                            item?.bettingTrendsConsensus?.total
                                              ?.awayOrOverMoneyVolume
                                              ? item?.bettingTrendsConsensus
                                                  ?.total
                                                  ?.awayOrOverMoneyVolume >
                                                item?.bettingTrendsConsensus
                                                  ?.total
                                                  ?.homeOrUnderMoneyVolume
                                                ? "btn-blue"
                                                : "btn-gray"
                                              : "btn-gray"
                                          }
                                        >
                                          {item?.bettingTrendsConsensus?.total
                                            ?.awayOrOverMoneyVolume
                                            ? item?.bettingTrendsConsensus
                                                ?.total?.awayOrOverMoneyVolume
                                            : "N/A"}
                                        </span>
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <Box className="d-flex-col mobile-win-details">
                                        <span className="number-bm">
                                          {item?.awayRecord?.records?.moneyline}
                                        </span>
                                        <span
                                          className={
                                            item?.bettingTrendsConsensus
                                              ?.moneyline?.awayOrOverMoneyVolume
                                              ? item?.bettingTrendsConsensus
                                                  ?.moneyline
                                                  ?.awayOrOverMoneyVolume >
                                                item?.bettingTrendsConsensus
                                                  ?.moneyline
                                                  ?.homeOrUnderMoneyVolume
                                                ? "btn-blue"
                                                : "btn-gray"
                                              : "btn-gray"
                                          }
                                        >
                                          {item?.bettingTrendsConsensus
                                            ?.moneyline?.awayOrOverMoneyVolume
                                            ? item?.bettingTrendsConsensus
                                                ?.moneyline
                                                ?.awayOrOverMoneyVolume
                                            : "N/A"}
                                        </span>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow className="mobile-sport-result">
                                    <TableCell>
                                      <Box className="d-flex-col">
                                        <span className="font-dark">
                                          Consensus
                                        </span>
                                        <span className="result-grey">
                                          Open
                                        </span>
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <Box className="d-flex-col">
                                        <span className="font-dark">
                                          {item?.consensus?.consensusMoney
                                            ?.spread
                                            ? item?.consensus?.consensusMoney
                                                ?.spread
                                            : "N/A"}
                                        </span>
                                        <span className="result-grey">
                                          {item?.openline?.openMoney?.spread
                                            ? item?.openline?.openMoney?.spread
                                            : "N/A"}
                                        </span>
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <Box className="d-flex-col text-center">
                                        <span className="font-dark">
                                          {item?.consensus?.consensusMoney
                                            ?.total
                                            ? item?.consensus?.consensusMoney
                                                ?.total
                                            : "N/A"}
                                        </span>
                                        <span className="result-grey">
                                          {item?.openline?.openMoney?.total
                                            ? item?.openline?.openMoney?.total
                                            : "N/A"}
                                        </span>
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <Box className="d-flex-col text-center">
                                        <span className="font-dark">
                                          {item?.consensus?.consensusMoney
                                            ?.moneyline
                                            ? item?.consensus?.consensusMoney
                                                ?.moneyline
                                            : "N/A"}
                                        </span>
                                        <span className="result-grey">
                                          {item?.openline?.openMoney?.moneyline
                                            ? item?.openline?.openMoney
                                                ?.moneyline
                                            : "N/A"}
                                        </span>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            ) : (
                              ""
                            )}
                          </Box>
                        </>
                      );
                    })}
                  </>
                );
              })}
            </Box>
          </Box>
        )
      ) : (
        <Box style={{ textAlign: "center" }}>
          {/* No Data Available  */}
          <NoDataComp />
        </Box>
      )}
    </>
  );
};

export default SportMatchup;
