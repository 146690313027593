import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  // Breadcrumbs,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import Loader from "src/components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
// import AdvertisingBannner from "../advertisingBanner";
// import OurPartner from "src/views/component/ourPartners";
// import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import "./teamSportData.scss";
import NoDataComp from "../UI/NoData";
import SmartBookModal from "src/views/component/UI/smartbookModal";
import { ReactComponent as Plus } from "../../../assets/images/icons/plus.svg";
import { ReactComponent as Right } from "../../../assets/images/icons/right.svg";
import { ReactComponent as BlackbookRight } from "../../../assets/images/icons/blackbookRight.svg";
import { ReactComponent as BlackbookPlus } from "../../../assets/images/icons/blackbookPlus.svg";

const TeamSportsDataPage = ({ teamSportType, searchInput }) => {
  const navigate = useNavigate();
  const [tournamentData, setTournamentData] = useState([]);
  const [tournamentCount, setTournamentCount] = useState(0);
  const [tournamentOffset, setTournamentOffset] = useState(0);
  const [tournamentFeaturedData, setTournamentFeaturedData] = useState([]);
  const [tournamentFeaturedCount, setTournamentFeaturedCount] = useState(0);
  const [tournamentFeaturedOffset, setTournamentFeaturedOffset] = useState(0);
  const [loader, setLoader] = useState(false);
  const [SBopen, setSBOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedSportId, setSelectedSportId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // fetchAllTournament(0, [], searchInput);
    // fetchAllTournamentFeatured(0, [], searchInput);
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      fetchAllTournament(0, [], searchInput);
      fetchAllTournamentFeatured(0, [], searchInput);
    }, 500);
    return () => {
      clearTimeout(searchDelay);
    };
  }, [searchInput]);

  const fetchAllTournament = async (offset, tournamentDatas, searchInput) => {
    offset === 0 && setLoader(true);
    try {
      let SportId =
        teamSportType === "cricket"
          ? 4
          : teamSportType === "rugbyleague"
          ? 12
          : teamSportType === "rugbyunion"
          ? 13
          : teamSportType === "basketball"
          ? 10
          : teamSportType === "americanfootball"
          ? 15
          : teamSportType === "australianrules"
          ? 9
          : teamSportType === "golf"
          ? 16
          : teamSportType === "tennis"
          ? 7
          : teamSportType === "baseball"
          ? 11
          : teamSportType === "icehockey"
          ? 17
          : teamSportType === "boxing"
          ? 6
          : teamSportType === "mma"
          ? 5
          : teamSportType === "soccer"
          ? 8
          : 14;
      let passApi =
        teamSportType === "cricket"
          ? `allsport/tournament?SportId=${SportId}&search=${
              searchInput && searchInput !== "" ? searchInput : ""
            }&limit=20&offset=${offset}&Sport=true&unique=true&isFeatured=0`
          : teamSportType === "rugbyleague"
          ? `allsport/tournament?SportId=${SportId}&search=${
              searchInput && searchInput !== "" ? searchInput : ""
            }&limit=20&offset=${offset}&Sport=true&isFeatured=0`
          : `allsport/tournament?SportId=${SportId}&search=${
              searchInput && searchInput !== "" ? searchInput : ""
            }&limit=20&offset=${offset}&isFeatured=0`;

      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        // setTournamentData(data?.result?.rows);
        // let count = data?.result?.count / 20;
        // setTournamentCount(Math.ceil(count));
        setLoader(false);
        setTournamentCount(data?.result?.rows?.length);
        const tournamentDetailsData = [
          ...tournamentDatas,
          ...data?.result?.rows
        ];
        const filteredData = _.uniqBy(tournamentDetailsData, function(e) {
          return e.id;
        });
        setTournamentData(filteredData);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };
  const fetchAllTournamentFeatured = async (
    offset,
    tournamentDatas,
    searchInput
  ) => {
    offset === 0 && setLoader(true);
    try {
      let SportId =
        teamSportType === "cricket"
          ? 4
          : teamSportType === "rugbyleague"
          ? 12
          : teamSportType === "rugbyunion"
          ? 13
          : teamSportType === "basketball"
          ? 10
          : teamSportType === "americanfootball"
          ? 15
          : teamSportType === "australianrules"
          ? 9
          : teamSportType === "golf"
          ? 16
          : teamSportType === "tennis"
          ? 7
          : teamSportType === "baseball"
          ? 11
          : teamSportType === "icehockey"
          ? 17
          : teamSportType === "boxing"
          ? 6
          : teamSportType === "mma"
          ? 5
          : teamSportType === "soccer"
          ? 8
          : 14;
      let passApi =
        teamSportType === "cricket"
          ? `allsport/tournament?SportId=${SportId}&search=${
              searchInput && searchInput !== "" ? searchInput : ""
            }&limit=20&offset=${offset}&Sport=true&unique=true&isFeatured=1`
          : teamSportType === "rugbyleague"
          ? `allsport/tournament?SportId=${SportId}&search=${
              searchInput && searchInput !== "" ? searchInput : ""
            }&limit=20&offset=${offset}&Sport=true&isFeatured=1`
          : `allsport/tournament?SportId=${SportId}&search=${
              searchInput && searchInput !== "" ? searchInput : ""
            }&limit=20&offset=${offset}&isFeatured=1`;

      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        setLoader(false);
        setTournamentFeaturedCount(data?.result?.rows?.length);
        const tournamentDetailsData = [
          ...tournamentDatas,
          ...data?.result?.rows
        ];
        const filteredData = _.uniqBy(tournamentDetailsData, function(e) {
          return e.id;
        });
        setTournamentFeaturedData(filteredData);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };
  const scrollToTopSection = () => {
    const targetSection = document.getElementById("view-less");
    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const handleMoreData = () => {
    setTournamentOffset(tournamentOffset + 20);
    fetchAllTournament(tournamentOffset + 20, tournamentData, searchInput);
  };

  const handleLessData = () => {
    scrollToTopSection();
    setTournamentOffset(tournamentOffset - 20);
    if (tournamentData?.length % 20 === 0) {
      setTournamentData(prevArray => prevArray.slice(0, -20));
    } else {
      setTournamentData(prevArray => prevArray.slice(0, -tournamentCount));
    }
  };
  const handleMoreFeaturedData = () => {
    setTournamentFeaturedOffset(tournamentFeaturedOffset + 20);
    fetchAllTournamentFeatured(
      tournamentFeaturedOffset + 20,
      tournamentFeaturedData,
      searchInput
    );
  };

  const handleLessFeaturedData = () => {
    scrollToTopSection();
    setTournamentFeaturedOffset(tournamentFeaturedOffset - 20);
    if (tournamentFeaturedData?.length % 20 === 0) {
      setTournamentFeaturedData(prevArray => prevArray.slice(0, -20));
    } else {
      setTournamentFeaturedData(prevArray =>
        prevArray.slice(0, -tournamentFeaturedCount)
      );
    }
  };
  // const fetchScrollAllTournament = async offset => {
  //   try {
  //     let SportId =
  //       teamSportType === "cricket"
  //         ? 4
  //         : teamSportType === "rugbyleague"
  //         ? 12
  //         : teamSportType === "rugbyunion"
  //         ? 13
  //         : teamSportType === "basketball"
  //         ? 10
  //         : teamSportType === "americanfootball"
  //         ? 15
  //         : teamSportType === "australianrules"
  //         ? 9
  //         : teamSportType === "golf"
  //         ? 16
  //         : teamSportType === "tennis"
  //         ? 7
  //         : teamSportType === "baseball"
  //         ? 11
  //         : teamSportType === "icehockey"
  //         ? 17
  //         : teamSportType === "boxing"
  //         ? 6
  //         : teamSportType === "mma"
  //         ? 5
  //         : teamSportType === "soccer"
  //         ? 8
  //         : 14;

  //     const { status, data } = await axiosInstance.get(
  //       `allsport/tournament?SportId=${SportId}&search=&limit=20&offset=${offset}`
  //     );
  //     if (status === 200) {
  //       setTournamentData(prevData => [
  //         ...tournamentData,
  //         ...data?.result?.rows
  //       ]);
  //       setTournamentOffset(offset);
  //     } else {
  //     }
  //   } catch (err) {}
  // };

  const handleNavigate = data => {
    if (teamSportType === "cricket" || teamSportType === "soccer") {
      const tournamentSlug = data?.Scd ? data?.Scd : "tournament";
      const type =
        teamSportType === "cricket"
          ? "cricket"
          : teamSportType === "soccer"
          ? "soccer"
          : "";
      navigate(
        `/teamsports/${type}/statistics/${tournamentSlug}/info/${data?.SportId}/${data?.id}`
      );
    } else if (teamSportType === "rugbyleague") {
      navigate(
        `/sport/statistics/${data?.SportId}/${data?.id}/league-data${
          data?.RLSeasons?.[0]?.id
            ? `?seasonId=${data?.RLSeasons?.[0]?.id}`
            : ""
        }`
      );
    } else {
      navigate(`/sport/statistics/${data?.SportId}/${data?.id}/league-data`);
    }
  };

  const sportName =
    teamSportType === "cricket"
      ? "Cricket"
      : teamSportType === "rugbyleague"
      ? "Rugby League"
      : teamSportType === "rugbyunion"
      ? "Rugby Union"
      : teamSportType === "basketball"
      ? "Basketball"
      : teamSportType === "americanfootball"
      ? "American Football"
      : teamSportType === "australianrules"
      ? "Australian Rules"
      : teamSportType === "golf"
      ? "Golf"
      : teamSportType === "tennis"
      ? "Tennis"
      : teamSportType === "baseball"
      ? "Baseball"
      : teamSportType === "icehockey"
      ? "Ice Hockey"
      : teamSportType === "boxing"
      ? "Boxing"
      : teamSportType === "mma"
      ? "Mixed Martial Arts"
      : teamSportType === "soccer"
      ? "Soccer"
      : "";
  const handleNavigateToSmartBook = e => {
    e.stopPropagation();
    navigate("/smartbook");
  };
  const handleBlackBookModal = (title, id, type, item, e) => {
    e.stopPropagation();
    setSelectedTitle(title);
    setSelectedType(type);
    setSelectedId(id);
    setSelectedSportId(item?.SportId);
    handleModal();
  };
  const handleModal = () => {
    setSBOpen(true);
  };

  const handleClose = () => {
    setSBOpen(false);
  };
  return (
    <>
      <Box className="teamsport-data-wrap">
        <Box className="teamsport-data-accordian mt-18">
          <Box className="teamsport-section-details league-accordion">
            {tournamentFeaturedData?.length > 0 && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Featured League</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="accordion-teamsport-details">
                    {loader ? (
                      <div className="allsport-loader-center">
                        <Loader />
                      </div>
                    ) : (
                      <>
                        {/* <InfiniteScroll
                        dataLength={tournamentData?.length}
                        next={() =>
                          fetchScrollAllTournament(tournamentOffset + 20)
                        }
                        // hasMore={tournamentCount > tournamentOffset}
                        hasMore={
                          tournamentCount !==
                          Math.ceil(tournamentOffset / 20 + 1)
                        }
                        loader={
                          <div className="allsport-loader-center ">
                            <Loader />
                          </div>
                        }
                      > */}
                        {tournamentFeaturedData?.map((obj, id) => {
                          return (
                            <>
                              <Box
                                className="league-list"
                                key={id}
                                onClick={() => handleNavigate(obj)}
                              >
                                <Box className="league-wrap">
                                  <Typography className="league-name">
                                    {teamSportType === "cricket"
                                      ? obj?.uniqueTournament
                                        ? obj?.uniqueTournament?.name
                                        : obj?.name
                                      : obj?.name}
                                  </Typography>
                                  {teamSportType === "cricket" ? (
                                    <Typography className="league-sub-name">
                                      {obj?.CricketCategory?.name}
                                    </Typography>
                                  ) : teamSportType === "soccer" ? (
                                    obj?.SoccerCategory ? (
                                      <Typography className="league-sub-name">
                                        {obj?.SoccerCategory?.name}
                                      </Typography>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </Box>
                                {screenWidth > 799 ? (
                                  <span>
                                    {obj?.isSmartBookTournament ? (
                                      <span
                                        className="add-to-blackbook view-Blackbook"
                                        onClick={e =>
                                          handleNavigateToSmartBook(e)
                                        }
                                      >
                                        <Right />
                                        View in Smartbook
                                      </span>
                                    ) : (
                                      <span
                                        className="add-to-blackbook"
                                        onClick={e =>
                                          handleBlackBookModal(
                                            obj?.name,
                                            obj?.id,
                                            "Tournament",
                                            obj,
                                            e
                                          )
                                        }
                                      >
                                        <Plus />
                                        Add to my Smartbook
                                      </span>
                                    )}
                                  </span>
                                ) : (
                                  <>
                                    {obj?.isSmartBookTournament ? (
                                      <Box
                                        onClick={() =>
                                          handleNavigateToSmartBook()
                                        }
                                      >
                                        <BlackbookRight />
                                      </Box>
                                    ) : (
                                      <Box
                                        onClick={() =>
                                          handleBlackBookModal(
                                            obj?.name,
                                            obj?.id,
                                            "Tournament",
                                            obj
                                          )
                                        }
                                      >
                                        <BlackbookPlus />
                                      </Box>
                                    )}
                                  </>
                                )}
                                {/* <Box className="arrow">
                                  <KeyboardArrowRightIcon fontSize="small" />
                                </Box> */}
                              </Box>
                            </>
                          );
                        })}
                        {/* </InfiniteScroll> */}
                      </>
                    )}
                    {tournamentFeaturedData?.length > 0 ? (
                      <Box className="view-more">
                        {tournamentFeaturedOffset + 20 ===
                        tournamentFeaturedData?.length ? (
                          <span
                            className="view-more-text"
                            onClick={() => handleMoreFeaturedData()}
                          >
                            View More
                          </span>
                        ) : (
                          <></>
                        )}
                        {tournamentFeaturedOffset >= 20 &&
                        tournamentFeaturedData?.length > 20 ? (
                          <span
                            className="view-more-text view-lese-text"
                            onClick={() => handleLessFeaturedData()}
                          >
                            View Less
                          </span>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Other League</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className="accordion-teamsport-details">
                  {loader ? (
                    <div className="allsport-loader-center">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {/* <InfiniteScroll
                        dataLength={tournamentData?.length}
                        next={() =>
                          fetchScrollAllTournament(tournamentOffset + 20)
                        }
                        // hasMore={tournamentCount > tournamentOffset}
                        hasMore={
                          tournamentCount !==
                          Math.ceil(tournamentOffset / 20 + 1)
                        }
                        loader={
                          <div className="allsport-loader-center ">
                            <Loader />
                          </div>
                        }
                      > */}
                      {tournamentData?.length > 0 ? (
                        tournamentData?.map((obj, id) => {
                          return (
                            <>
                              <Box
                                className="league-list"
                                key={id}
                                onClick={() => handleNavigate(obj)}
                              >
                                <Box className="league-wrap">
                                  <Typography className="league-name">
                                    {teamSportType === "cricket"
                                      ? obj?.uniqueTournament
                                        ? obj?.uniqueTournament?.name
                                        : obj?.name
                                      : obj?.name}
                                  </Typography>
                                  {teamSportType === "cricket" ? (
                                    <Typography className="league-sub-name">
                                      {obj?.CricketCategory?.name}
                                    </Typography>
                                  ) : teamSportType === "soccer" ? (
                                    obj?.SoccerCategory ? (
                                      <Typography className="league-sub-name">
                                        {obj?.SoccerCategory?.name}
                                      </Typography>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </Box>
                                {screenWidth > 799 ? (
                                  <span>
                                    {obj?.isSmartBookTournament ? (
                                      <span
                                        className="add-to-blackbook view-Blackbook"
                                        onClick={e =>
                                          handleNavigateToSmartBook(e)
                                        }
                                      >
                                        <Right />
                                        View in Smartbook
                                      </span>
                                    ) : (
                                      <span
                                        className="add-to-blackbook"
                                        onClick={e =>
                                          handleBlackBookModal(
                                            obj?.name,
                                            obj?.id,
                                            "Tournament",
                                            obj,
                                            e
                                          )
                                        }
                                      >
                                        <Plus />
                                        Add to my Smartbook
                                      </span>
                                    )}
                                  </span>
                                ) : (
                                  <>
                                    {obj?.isSmartBookTournament ? (
                                      <Box
                                        onClick={() =>
                                          handleNavigateToSmartBook()
                                        }
                                      >
                                        <BlackbookRight />
                                      </Box>
                                    ) : (
                                      <Box
                                        onClick={() =>
                                          handleBlackBookModal(
                                            obj?.name,
                                            obj?.id,
                                            "Tournament",
                                            obj
                                          )
                                        }
                                      >
                                        <BlackbookPlus />
                                      </Box>
                                    )}
                                  </>
                                )}
                                {/* <Box className="arrow">
                                  <KeyboardArrowRightIcon fontSize="small" />
                                </Box> */}
                              </Box>
                            </>
                          );
                        })
                      ) : (
                        <Box className="no-data-wrap">
                          <Typography className="text-align no-data">
                            {/* No Data Available */}
                            <NoDataComp />
                          </Typography>
                        </Box>
                      )}
                      {/* </InfiniteScroll> */}
                    </>
                  )}
                  {tournamentData?.length > 0 ? (
                    <Box className="view-more">
                      {tournamentOffset + 20 === tournamentData?.length ? (
                        <span
                          className="view-more-text"
                          onClick={() => handleMoreData()}
                        >
                          View More
                        </span>
                      ) : (
                        <></>
                      )}
                      {tournamentOffset >= 20 && tournamentData?.length > 20 ? (
                        <span
                          className="view-more-text view-lese-text"
                          onClick={() => handleLessData()}
                        >
                          View Less
                        </span>
                      ) : (
                        <></>
                      )}
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
      <SmartBookModal
        open={SBopen}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
        dataId={selectedId}
        sportId={selectedSportId}
        listingFunction={() => {
          fetchAllTournament(0, [], searchInput);
          fetchAllTournamentFeatured(0, [], searchInput);
        }}
      />
    </>
  );
};

export default TeamSportsDataPage;
