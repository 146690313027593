import React, { useContext, useState } from "react";
import { Box } from "@material-ui/core";
import { IntlContext } from "src/App";
import { useParams } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import AboveTable from "../../../../../../../assets/images/ad-placeholder/sports1.webp";
import StatisticsTable from "../../../StatisticsTable";

function HorsesStats({ fetchAds, staticsType, animalData, trackId }) {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [OverAllStats, setOverAllStats] = useState([]);
  const [IsOverAllStatsLoading, setIsOverAllStatsLoading] = useState(false);
  const [collapseMenu, setCollapseMenu] = useState(-1);
  const [
    TrackConditionCareercollapse,
    setTrackConditionCareercollapse
  ] = useState(-1);
  const [TrackConditionCareerData, setTrackConditionCareerData] = useState([]);
  const [
    IsTrackConditionCareerLoading,
    setTrackConditionCareerLoading
  ] = useState(false);
  const [TrackTypesCareercollapse, setTrackTypesCareercollapse] = useState(-1);
  const [TrackTypesCareerData, setTrackTypesCareerData] = useState([]);
  const [IsTrackTypesCareerLoading, setTrackTypesCareerLoading] = useState(
    false
  );
  const [DistancesCareercollapse, setDistancesCareercollapse] = useState(-1);
  const [DistancesCareerData, setDistancesCareerData] = useState([]);
  const [IsDistancesCareerLoading, setDistancesCareerLoading] = useState(false);
  const [BarrierCareercollapse, setBarrierCareercollapse] = useState(-1);
  const [BarrierCareerData, setBarrierCareerData] = useState([]);
  const [IsBarrierCareerLoading, setBarrierCareerLoading] = useState(false);
  const [PrizeCareercollapse, setPrizeCareercollapse] = useState(-1);
  const [PrizeCareerData, setPrizeCareerData] = useState([]);
  const [IsPrizeCareerLoading, setPrizeCareerLoading] = useState(false);
  const [WeightCareercollapse, setWeightCareercollapse] = useState(-1);
  const [WeightCareerData, setWeightCareerData] = useState([]);
  const [IsWeightCareerLoading, setWeightCareerLoading] = useState(false);

  const fetchOverallStat = async () => {
    setIsOverAllStatsLoading(true);
    try {
      let passApi = "getHorseData";
      const career = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }`
      );
      const Last12Month = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&duration=12`
      );
      const Last10 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&limit=10`
      );
      const Last100 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&limit=100`
      );
      const careerData = career?.data?.result;
      const Last12MonthData = Last12Month?.data?.result;
      const Last10Data = Last10?.data?.result;
      const Last100Data = Last100?.data?.result;
      careerData["name"] = "Career";
      Last12MonthData["name"] = "12 Months";
      Last10Data["name"] = "Last 10";
      Last100Data["name"] = "Last 100";
      let MergeData = [
        ...[careerData],
        ...[Last12MonthData],
        ...[Last10Data],
        ...[Last100Data]
      ];
      setIsOverAllStatsLoading(false);
      setOverAllStats(MergeData);
    } catch (err) {}
  };

  const fetchTrackConditionCareer = async () => {
    setTrackConditionCareerLoading(true);
    try {
      let passApi = "getHorseData";
      const Firm = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&trackCondition=Firm`
      );
      const Good = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&trackCondition=Good`
      );
      const Soft = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&trackCondition=Soft`
      );
      const Heavy = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&trackCondition=Heavy`
      );
      const Synthetic = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&trackCondition=Synthetic`
      );
      const Unknown = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&trackCondition=Unknown`
      );
      const Soft7 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&trackCondition=Soft7`
      );

      const FirmData = Firm?.data?.result;
      const GoodData = Good?.data?.result;
      const SoftData = Soft?.data?.result;
      const HeavyData = Heavy?.data?.result;
      const SyntheticData = Synthetic?.data?.result;
      const UnknownData = Unknown?.data?.result;
      const Soft7Data = Soft7?.data?.result;
      let MergeData = [
        ...[FirmData],
        ...[GoodData],
        ...[SoftData],
        ...[HeavyData],
        ...[SyntheticData],
        ...[UnknownData],
        ...[Soft7Data]
      ];
      setTrackConditionCareerData(MergeData);
      setTrackConditionCareerLoading(false);
    } catch (error) {
      setTrackConditionCareerLoading(false);
    }
  };

  const fetchTrackTypeCareer = async () => {
    setTrackTypesCareerLoading(true);
    try {
      let passApi = "getHorseData";
      const Turf = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&trackType=Turf`
      );
      const Cushion = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&trackType=Cushion`
      );

      const TurfData = Turf?.data?.result;
      const CushionData = Cushion?.data?.result;

      TurfData["name"] = "Turf";
      CushionData["name"] = "Cushion";

      let MergeData = [...[TurfData], ...[CushionData]];
      setTrackTypesCareerData(MergeData);
      setTrackTypesCareerLoading(false);
    } catch (error) {
      setTrackTypesCareerLoading(false);
    }
  };

  const fetchDistancesCareer = async () => {
    setDistancesCareerLoading(true);
    try {
      let passApi = "getHorseData";
      const Distances1000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startDistance=0&endDistance=1000`
      );
      const Distances1300 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startDistance=1001&endDistance=1300`
      );
      const Distances1500 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startDistance=1301&endDistance=1500`
      );
      const Distances2000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startDistance=1501&endDistance=2000`
      );
      const Distances2400 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startDistance=2001&endDistance=2400`
      );
      const DistancesPlus2401 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startDistance=2401`
      );

      const Distances1000Data = Distances1000?.data?.result;
      const Distances1300Data = Distances1300?.data?.result;
      const Distances1500Data = Distances1500?.data?.result;
      const Distances2000Data = Distances2000?.data?.result;
      const Distances2400Data = Distances2400?.data?.result;
      const DistancesPlus2401Data = DistancesPlus2401?.data?.result;

      let MergeData = [
        ...[Distances1000Data],
        ...[Distances1300Data],
        ...[Distances1500Data],
        ...[Distances2000Data],
        ...[Distances2400Data],
        ...[DistancesPlus2401Data]
      ];
      setDistancesCareerData(MergeData);
      setDistancesCareerLoading(false);
    } catch (error) {
      setDistancesCareerLoading(false);
    }
  };

  const fetchBarrierCareer = async () => {
    setBarrierCareerLoading(true);
    try {
      let passApi = "getHorseData";
      const Barrier13 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startBarrier=1&endBarrier=3`
      );
      const Barrier46 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startBarrier=4&endBarrier=6`
      );
      const Barrier710 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startBarrier=7&endBarrier=10`
      );
      const Barrier1113 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startBarrier=11&endBarrier=13`
      );
      const Barrier1416 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startBarrier=14&endBarrier=16`
      );
      const Barrier17 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startBarrier=17`
      );

      const Barrier13Data = Barrier13?.data?.result;
      const Barrier46Data = Barrier46?.data?.result;
      const Barrier710Data = Barrier710?.data?.result;
      const Barrier1113Data = Barrier1113?.data?.result;
      const Barrier1416Data = Barrier1416?.data?.result;
      const Barrier17Data = Barrier17?.data?.result;

      // TurfData["name"] = "Turf";
      // CushionData["name"] = "Cushion";

      let MergeData = [
        ...[Barrier13Data],
        ...[Barrier46Data],
        ...[Barrier710Data],
        ...[Barrier1113Data],
        ...[Barrier1416Data],
        ...[Barrier17Data]
      ];
      setBarrierCareerData(MergeData);
      setBarrierCareerLoading(false);
    } catch (error) {
      setBarrierCareerLoading(false);
    }
  };

  const fetchPrizeCareer = async () => {
    setPrizeCareerLoading(true);
    try {
      let passApi = "getHorseData";
      const Prize5000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startPrize=0&endPrize=5000&duration=`
      );
      const Prize10000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startPrize=5001&endPrize=10000&duration=`
      );
      const Prize20000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startPrize=10001&endPrize=20000&duration=`
      );
      const Prize35000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startPrize=20001&endPrize=35000&duration=`
      );
      const Prize75000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startPrize=35001&endPrize=75000&duration=`
      );
      const Prize75000Plus = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startPrize=75001&duration=`
      );

      const Prize5000Data = Prize5000?.data?.result;
      const Prize10000Data = Prize10000?.data?.result;
      const Prize20000Data = Prize20000?.data?.result;
      const Prize35000Data = Prize35000?.data?.result;
      const Prize75000Data = Prize75000?.data?.result;
      const Prize75000PlusData = Prize75000Plus?.data?.result;
      Prize5000Data["name"] = "$0 - $5000";
      Prize10000Data["name"] = "$5001 - $10000";
      Prize20000Data["name"] = "$10001 - $20000";
      Prize35000Data["name"] = "$20001 - $35000";
      Prize75000Data["name"] = "$35001 - $75000";
      Prize75000PlusData["name"] = "$75001+";
      let MergeData = [
        ...[Prize5000Data],
        ...[Prize10000Data],
        ...[Prize20000Data],
        ...[Prize35000Data],
        ...[Prize75000Data],
        ...[Prize75000PlusData]
      ];
      setPrizeCareerData(MergeData);
      setPrizeCareerLoading(false);
    } catch (error) {
      setPrizeCareerLoading(false);
    }
  };

  const fetchWeightCareer = async () => {
    setWeightCareerLoading(true);
    try {
      let passApi = "getHorseData";
      const Weight52 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startWeight=51&endWeight=52&duration=`
      );
      const Weight54 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startWeight=53&endWeight=54&duration=`
      );
      const Weight56 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startWeight=55&endWeight=56&duration=`
      );
      const Weight58 = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startWeight=57&endWeight=58&duration=`
      );
      const Weight59Plus = await axiosInstance.get(
        `statistics/${passApi}/profile/${
          staticsType == "modal" ? animalData?.id : params?.horseId
        }?trackId=${
          staticsType == "modal"
            ? trackId === "0"
              ? ""
              : trackId
            : params?.trackId === "0"
            ? ""
            : params?.trackId
        }&startWeight=59&duration=`
      );

      const Weight52Data = Weight52?.data?.result;
      const Weight54Data = Weight54?.data?.result;
      const Weight56Data = Weight56?.data?.result;
      const Weight58Data = Weight58?.data?.result;
      const Weight59PlusData = Weight59Plus?.data?.result;

      let MergeData = [
        ...[Weight52Data],
        ...[Weight54Data],
        ...[Weight56Data],
        ...[Weight58Data],
        ...[Weight59PlusData]
      ];
      setWeightCareerData(MergeData);
      setWeightCareerLoading(false);
    } catch (error) {
      setWeightCareerLoading(false);
    }
  };

  return (
    <>
      {staticsType == "modal" ? "" : fetchAds(1, "102px", AboveTable)}
      <Box className="horse-strap-wrap">
        <StatisticsTable
          Name="Overall Stats"
          dataName="Career"
          isLoading={IsOverAllStatsLoading}
          TableData={OverAllStats}
          collapseMenu={collapseMenu}
          setcollapseMenu={setCollapseMenu}
          apiCallFunction={() => fetchOverallStat()}
          modalStaticsType={staticsType == "modal" ? "modal" : ""}
        />
        <StatisticsTable
          Name="Track Conditions"
          dataName="Career"
          isLoading={IsTrackConditionCareerLoading}
          TableData={TrackConditionCareerData}
          collapseMenu={TrackConditionCareercollapse}
          setcollapseMenu={setTrackConditionCareercollapse}
          apiCallFunction={() => fetchTrackConditionCareer()}
          modalStaticsType={staticsType == "modal" ? "modal" : ""}
        />
        <StatisticsTable
          Name="Track Types"
          dataName="Career"
          isLoading={IsTrackTypesCareerLoading}
          TableData={TrackTypesCareerData}
          collapseMenu={TrackTypesCareercollapse}
          setcollapseMenu={setTrackTypesCareercollapse}
          apiCallFunction={() => fetchTrackTypeCareer()}
          modalStaticsType={staticsType == "modal" ? "modal" : ""}
        />
        <StatisticsTable
          Name="Distances"
          dataName="Career"
          isLoading={IsDistancesCareerLoading}
          TableData={DistancesCareerData}
          collapseMenu={DistancesCareercollapse}
          setcollapseMenu={setDistancesCareercollapse}
          apiCallFunction={() => fetchDistancesCareer()}
          modalStaticsType={staticsType == "modal" ? "modal" : ""}
        />
        <StatisticsTable
          Name="Barrier"
          dataName="Career"
          isLoading={IsBarrierCareerLoading}
          TableData={BarrierCareerData}
          collapseMenu={BarrierCareercollapse}
          setcollapseMenu={setBarrierCareercollapse}
          apiCallFunction={() => fetchBarrierCareer()}
          modalStaticsType={staticsType == "modal" ? "modal" : ""}
        />
        <StatisticsTable
          Name="Prize Money"
          dataName="Career"
          isLoading={IsPrizeCareerLoading}
          TableData={PrizeCareerData}
          collapseMenu={PrizeCareercollapse}
          setcollapseMenu={setPrizeCareercollapse}
          apiCallFunction={() => fetchPrizeCareer()}
          modalStaticsType={staticsType == "modal" ? "modal" : ""}
        />
        <StatisticsTable
          Name="Weight"
          dataName="Career"
          isLoading={IsWeightCareerLoading}
          TableData={WeightCareerData}
          collapseMenu={WeightCareercollapse}
          setcollapseMenu={setWeightCareercollapse}
          apiCallFunction={() => fetchWeightCareer()}
          modalStaticsType={staticsType == "modal" ? "modal" : ""}
        />
      </Box>
    </>
  );
}

export default HorsesStats;
