import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  withStyles,
  IconButton,
  Collapse
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DefaultTeam from "src/assets/images/defaultTeam.png";
import { IntlContext } from "src/App";
import NoDataComp from "src/views/component/UI/NoData";
import Loader from "../../../../../components/Loader";
import _ from "lodash";
import { useParams } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "src/helpers/context";

const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 999,
    background: "#FFFFFF",
    minWidth: "165px",
    // boxShadow: "inset -2px 2px 6px 0px #ebebeb",
    "@media(max-width: 1023px)": {
      maxWidth: "133px",
      boxShadow: "inset -2px 2px 6px 0px #ebebeb"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 999,
    background: "#FFFFFF",
    // boxShadow: "inset -2px 2px 6px 0px #ebebeb",
    "@media(max-width: 1023px)": {
      boxShadow: "inset -2px 2px 6px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const InfoTable = ({ singleMatchData }) => {
  const params = useParams();
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePointData, setTablePointData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [collapseMenu, setcollapseMenu] = useState(-1);
  const [categoryMenu, setcategoryMenu] = useState(-1);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    fetchTeamTableData();
  }, [params]);

  const fetchTeamTableData = async () => {
    setTableLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/rl/table/tournament/${params?.tournamentId}?seasonId=${singleMatchData?.RLSeasonId}&type=overall`
      );
      if (status === 200) {
        // const groupedData = _.groupBy(data?.result?.result, "name");
        setTablePointData(data?.result?.result);
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    } catch (err) {
      setTableLoading(false);
    }
  };
  return (
    <>
      <Box className="info-table-wrap">
        {tableLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <>
            {tablePointData?.length > 0 ? (
              <>
                <Box className="short-name-details">
                  <Typography className="short-name">
                    M - <span>Played</span> , W - <span>Win</span>, L -{" "}
                    <span>Loss</span>, T - <span>Ties</span>
                    {/* , N/R -{" "} */}
                    <span>,No Results</span>, PTS - <span>Points</span>
                    {/* , NRR -{" "}
                    <span>Net Run Rate</span> */}
                  </Typography>
                </Box>
                {screenWidth >= 1023 ? (
                  <TableContainer
                    id="details-table"
                    className="info-table-cont-wrap"
                  >
                    <Table
                      className="data-table"
                      style={{ minWidth: "max-content", height: "100%" }}
                    >
                      <TableHead className="table-header">
                        <TableRow className="table-head-row ">
                          <TableCell>#</TableCell>
                          <TableCell>Team</TableCell>
                          <TableCell>M</TableCell>
                          <TableCell>W</TableCell>
                          <TableCell>L</TableCell>
                          <TableCell>T</TableCell>
                          {/* <TableCell>N/R</TableCell> */}
                          <TableCell>PTS</TableCell>
                          {/* <TableCell>NRR</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody className="table-body">
                        {tablePointData?.map((item, i) => {
                          return (
                            <>
                              <TableRow
                                key={i}
                                className={
                                  singleMatchData?.homeTeamId ===
                                    item?.teamId ||
                                  singleMatchData?.awayTeamId === item?.teamId
                                    ? "live-row table-body-row"
                                    : "table-body-row "
                                }
                              >
                                <TableCell>{item?.position}</TableCell>
                                <TableCell>
                                  <Box className="d-flex align-center col-gap-5 table-team-logo">
                                    <Box className="logo-wrap">
                                      <img
                                        src={
                                          item?.team?.flag
                                            ? Config.mediaURL + item?.team?.flag
                                            : DefaultTeam
                                        }
                                        alt="logo"
                                      />
                                    </Box>
                                    <Typography className="soccer-team-name">
                                      {item?.team?.name}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell>{item?.matches}</TableCell>
                                <TableCell>{item?.wins}</TableCell>
                                <TableCell>{item?.losses}</TableCell>
                                <TableCell>{item?.draws}</TableCell>
                                {/* <TableCell>{item?.nr}</TableCell> */}
                                <TableCell>{item?.points}</TableCell>
                                {/* <TableCell>{item?.nrr}</TableCell> */}
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <>
                    <Box className="mobile-info-table">
                      <Box className="d-flex align-center mobile-table-heading">
                        <Typography
                          variant="h6"
                          className="table-heading heading-pos"
                        >
                          #
                        </Typography>
                        <Typography variant="h6" className="table-heading">
                          Team
                        </Typography>
                      </Box>
                      {tablePointData?.map((item, index) => (
                        <>
                          <Box
                            className={
                              collapseMenu !== index
                                ? "info-table-border-bottom info-table-deatils"
                                : "info-table-deatils"
                            }
                            onClick={() => {
                              setcollapseMenu(
                                collapseMenu === index ? -1 : index
                              );
                            }}
                          >
                            <Box
                              className={
                                singleMatchData?.homeTeamId === item?.teamId ||
                                singleMatchData?.awayTeamId === item?.teamId
                                  ? "mobile-live-row d-flex align-center info-table-mobile-header"
                                  : "d-flex align-center info-table-mobile-header "
                              }
                            >
                              <Typography className="details-name">
                                {item?.position}
                              </Typography>
                              <Box className="table-body-column">
                                <Box className="d-flex align-center team-wrap-body">
                                  <Box className="img-wrap">
                                    <img
                                      src={
                                        item?.team?.flag
                                          ? Config.mediaURL + item?.team?.flag
                                          : DefaultTeam
                                      }
                                      alt="logo"
                                    />
                                  </Box>
                                  <Typography className="team-name cursor-pointer">
                                    {item?.team?.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box className="arrow-details">
                              <IconButton aria-label="expand row" size="small">
                                {collapseMenu === index ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </Box>
                          </Box>
                          <Collapse
                            in={collapseMenu === index}
                            timeout="auto"
                            unmountOnExit
                            className="collapse-box collapse-box-bottom"
                          >
                            <Box className="collapse-deatils">
                              <Table className="collapse-table">
                                <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">M</TableCell>
                                  <TableCell className="td-right">
                                    {item?.matches}
                                  </TableCell>
                                </TableRow>
                                <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">W</TableCell>
                                  <TableCell className="td-right">
                                    {item?.wins}
                                  </TableCell>
                                </TableRow>
                                <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">L</TableCell>
                                  <TableCell className="td-right">
                                    {item?.losses}
                                  </TableCell>
                                </TableRow>
                                <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">T</TableCell>
                                  <TableCell className="td-right">
                                    {item?.draws}
                                  </TableCell>
                                </TableRow>
                                {/* <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">N/R</TableCell>
                                  <TableCell className="td-right">
                                    {item?.nr}
                                  </TableCell>
                                </TableRow> */}
                                <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">PTS</TableCell>
                                  <TableCell className="td-right">
                                    {item?.points}
                                  </TableCell>
                                </TableRow>

                                {/* <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">NRR</TableCell>
                                  <TableCell className="td-right">
                                    {item?.nrr}
                                  </TableCell>
                                </TableRow> */}
                              </Table>
                            </Box>
                          </Collapse>
                        </>
                      ))}
                    </Box>
                  </>
                )}
              </>
            ) : (
              <NoDataComp />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default InfoTable;
