import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef
} from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  makeStyles
} from "@material-ui/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import playerAvtar from "src/assets/images/player-avatar.png";
import { useSelector } from "react-redux";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import banner from "src/assets/images/banner/smart-b-banner-secodary.webp";
import { IntlContext } from "src/App";
import moment from "moment-timezone";
import _, { upperCase } from "lodash";
import OurPartner from "../../ourPartners";
import NoDataComp from "../../UI/NoData";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ReactComponent as TableRightArrow } from "../../../../assets/images/icons/table-right-arrow.svg";
import Slider from "react-slick";
import Loader from "src/components/Loader";
import { release } from "src/helpers/context/release";
import { Config } from "src/helpers/context";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import "./sportExpertTipsDetails.scss";

const SportExpertTipsDetailsPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const carouselRef = useRef(null);

  const localesData = intlContext?.messages;
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isLoading, setLoading] = useState(false);
  const [tableHeaderData, setTableHeaderData] = useState([]);
  const [tipDetailData, setTipDetailData] = useState([]);
  const [scrollX, setscrollX] = useState("left");
  // const [currentPage, setCurrentPage] = useState(1);

  // Slider Code
  const [relatedTipsData, setRelatedTipsData] = useState([]);
  const [relatedTipsLoader, setRelatedTipsLoader] = useState(false);
  const [dragging, setDragging] = useState(false);

  const settingsSport = {
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(3, relatedTipsData?.length),
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    dots: false,
    beforeChange: (currentSlide, nextSlide) => {
      handleBeforeChange();

      if (currentSlide < nextSlide) {
        if (nextSlide == Number(relatedTipsData?.length) - 2) {
        }
      }
    },
    prevArrow: (
      <Box className="slick-prev">
        <ChevronLeftIcon />
      </Box>
    ),
    nextArrow: (
      <Box className="slick-next">
        <ChevronRightIcon />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 899,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 799,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handlePaginationAction = () => {
    // Implement your pagination action here
    // For example, update the current page or fetch more data
    // setCurrentPage((prevPage) => prevPage + 1);
    // fetchDataForNextPage();
  };

  // Slider
  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  let SportId =
    params?.type === "cricket"
      ? 4
      : params?.type === "rugbyleague"
      ? 12
      : params?.type === "rugbyunion"
      ? 13
      : params?.type === "basketball"
      ? 10
      : params?.type === "americanfootball"
      ? 15
      : params?.type === "australianrules"
      ? 9
      : params?.type === "baseball"
      ? 11
      : params?.type === "boxing"
      ? 6
      : params?.type === "icehockey"
      ? 17
      : params?.type === "mma"
      ? 5
      : params?.type === "soccer"
      ? 8
      : params?.type === "tennis"
      ? 7
      : params?.type === "golf"
      ? 16
      : "";

  useEffect(() => {
    getOneTipData();
    getRelatedTipData();
  }, [params]);

  // /expertTips/bew / getTipsDetail / 7;
  const getOneTipData = async () => {
    setLoading(true);

    try {
      const { status, data } = await axiosInstance.get(
        `/expertTips/web/getTipsDetail/${params?.id}`
      );
      if (status === 200) {
        setLoading(false);
        setTableHeaderData(data?.header);
        setTipDetailData(data?.result);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getRelatedTipData = async () => {
    setRelatedTipsLoader(true);
    // &tournamentId=${params?.tournamentId}
    try {
      const { status, data } = await axiosInstance.get(
        `/expertTips/web/getAllTips?SportId=${
          SportId ? SportId : ""
        }&ExpertSportTipId=${params?.id}`
      );
      if (status === 200) {
        setRelatedTipsLoader(false);
        setRelatedTipsData(data?.result?.rows);
      } else {
        setRelatedTipsLoader(false);
      }
    } catch (err) {
      setRelatedTipsLoader(false);
    }
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const getSportname = type => {
    return type === "cricket"
      ? "Cricket"
      : type === "rugbyleague"
      ? "Rugby League"
      : type === "rugbyunion"
      ? "Rugby Union"
      : type === "basketball"
      ? "Basketball "
      : type === "americanfootball"
      ? "American Football"
      : type === "australianrules"
      ? "Australian Rules "
      : type === "golf"
      ? "Golf"
      : type === "tennis"
      ? "Tennis"
      : type === "baseball"
      ? "Baseball"
      : type === "icehockey"
      ? "Ice Hockey"
      : type === "boxing"
      ? "Boxing"
      : type === "mma"
      ? "mma"
      : type === "soccer"
      ? "soccer"
      : "Rugby Union Sevens";
  };

  const handleNavigate = () => {
    navigate(`/teamsports/${params?.type}/odds/0/false`, {
      state: {
        round: params?.round,
        tournament: params?.tournamentId
      }
    });
  };
  const SlideSeeAllTable = direction => {
    setscrollX(direction);
    var container = document.getElementById("sport-expert-right-table");
    var scrollCompleted = 0;
    var slideVar = setInterval(function() {
      if (direction == "left") {
        container.scrollLeft -= 10;
      } else {
        container.scrollLeft += 10;
      }
      scrollCompleted += 10;
      if (scrollCompleted >= 900) {
        window.clearInterval(slideVar);
      }
    }, 10);
  };
  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="sport-tips-details-wrap">
          <Box className="sport-tips-details-header">
            <Box className="page-bredcrumn-wrap">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    HOME
                  </Link>
                  <Link underline="hover" color="inherit" to="/">
                    SPORTS
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to={`/teamsports/${params?.type}/odds/0/false`}
                  >
                    {getSportname(params?.type)}
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to={`/teamsports/${params?.type}/sport-expert-tips`}
                  >
                    Expert Tips
                  </Link>
                  <Typography>
                    Expert Tips for{" "}
                    {params?.type === "cricket" ? "Day" : "Round"}{" "}
                    {params?.round}
                  </Typography>
                </Breadcrumbs>
              </Box>
            </Box>

            <Box className="title-btn-box">
              <Typography variant="h1">
                Expert Tips for {params?.type === "cricket" ? "Day" : "Round"}{" "}
                {params?.round}
              </Typography>
              <Box className="chip-sec">
                <Typography className="chip-txt">
                  {params?.name
                    ? params?.name.trim().replaceAll("-", " ")
                    : "name"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="page-deatils-wrap">
            {isLoading ? (
              <div className="allsport-loader-center ">
                <Loader />
              </div>
            ) : tableHeaderData && tipDetailData?.length > 0 ? (
              <>
                <Box className="tip-content-box">
                  {/* <Typography className="time">
                      {moment(expertTipDetailsData?.time).format("ddd")}{" "}
                      {moment(expertTipDetailsData?.time).format("DD MMMM, YYYY")}
                    </Typography> */}
                  {/* <Typography className="title">
                      {expertTipDetailsData?.title}
                    </Typography>
                    <Typography
                    className="des"
                    dangerouslySetInnerHTML={{
                      __html:
                        localesData?.ADVERTISING_ENQUIRIES?.ENQUIRIES_DETAILS
                    }}
                  ></Typography>
                    <Typography className="des">
                      {expertTipDetailsData?.des}
                    </Typography> */}
                  <Box className="odds-btn-box">
                    <Typography className="title mt-0">
                      <span className="txt-uppercase">
                        {params?.name
                          ? params?.name.trim().replaceAll("-", " ")
                          : "name"}
                      </span>
                      : Tips for {params?.type === "cricket" ? "Day" : "Round"}{" "}
                      {params?.round}
                    </Typography>
                    <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      className="see-odds-button"
                      onClick={() => handleNavigate()}
                      endIcon={<KeyboardArrowRightIcon />}
                    >
                      {release[Config.release]?.oddsCheck
                        ? `See Featured odds for ${
                            params?.type === "cricket" ? "Day" : "Round"
                          } ${params?.round}`
                        : `See ${
                            params?.type === "cricket" ? "Day" : "Round"
                          } ${params?.round}`}
                    </Button>
                  </Box>
                </Box>
                {/* <TableContainer className="international-table-container table-box-shadow">
                  <Table
                    style={{ height: "100%" }}
                    className="international-table"
                  >
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#e8eaec" }}>
                      
                        <StickyTableCell
                          className={`${classes.head} title-start title-padding bg-color-Light-grey in-head font-16`}
                        >
                          <Box>
                            <span> Tipper Name </span>
                            <span
                              className="title-padding bg-color-Light-grey in-head"
                              style={{
                                width: "200px !important"
                              }}
                            >
                              <span className="font-16">Total</span>

                              <span>
                                Last{" "}
                                {params?.type === "cricket" ? "Day" : "Round"}
                              </span>
                            </span>
                          </Box>
                        </StickyTableCell>

                        {tableHeaderData && tableHeaderData?.length > 0 ? (
                          tableHeaderData?.map((item, index) => {
                            return (
                              <TableCell
                                className="bg-color-Light-grey in-head"
                                style={{
                                  width: "11%",
                                  padding: "8px"
                                }}
                                key={index}
                              >
                                <span>{item?.homeTeam}</span>
                                <br />
                                <span>VS</span>
                                <br />
                                <span>{item?.awayTeam}</span>
                              </TableCell>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tipDetailData?.map((item, index) => {
                        return (
                          <>
                            <TableRow key={index}>
                              <StickyTableCell
                                className={`${classes.cell} detail-flex`}
                              >
                                <p className="tipper-name font-16">
                                  {item?.tipper?.firstName +
                                    " " +
                                    item?.tipper?.lastName}
                                </p>

                                <span className="text-center">
                                  {item?.totalRound}
                                </span>
                              </StickyTableCell>
                              {item?.selectedTeam?.map((sItem, sindex) => {
                                return (
                                  <TableCell
                                    className="max-word text-center"
                                    key={sindex}
                                  >
                                
                                    {sItem?.homeTeam?.isTip == 1 ? (
                                      <img
                                        src={
                                          sItem?.homeTeam?.flag?.includes(
                                            "uploads"
                                          )
                                            ? Config.mediaURL +
                                              sItem?.homeTeam?.flag
                                            : Melbourne
                                        }
                                        className="winner-team-img"
                                      />
                                    ) : sItem?.awayTeam?.isTip == 1 ? (
                                      <img
                                        src={
                                          sItem?.awayTeam?.flag?.includes(
                                            "uploads"
                                          )
                                            ? Config.mediaURL +
                                              sItem?.awayTeam?.flag
                                            : Brisbane
                                        }
                                        className="winner-team-img"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer> */}
                <Box className="sport-expert-table-wrap">
                  <Box className="sport-expert-left">
                    <TableContainer
                      id="details-table"
                      className="sport-expert-table-container "
                    >
                      <Table className="sport-expert-table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="font-16">
                              Tipper Name{" "}
                            </TableCell>
                            <TableCell className="left-align">
                              <Box className="font-16">Total</Box>
                              <span>
                                {" "}
                                Last{" "}
                                {params?.type === "cricket" ? "Day" : "Round"}
                              </span>{" "}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tipDetailData?.map((item, index) => {
                            return (
                              <>
                                <TableRow key={index}>
                                  <TableCell className="no-runner">
                                    <p className="tipper-name font-16">
                                      {item?.tipper?.firstName +
                                        " " +
                                        item?.tipper?.lastName}
                                    </p>
                                  </TableCell>
                                  <TableCell className="left-align bold">
                                    {item?.totalRound}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box className="sport-expert-right">
                    <TableContainer
                      id="sport-expert-right-table"
                      className="sport-expert-table-container"
                    >
                      <Box className="overlay"></Box>
                      {screenWidth > 799 && (
                        <Box className="arrow-wrap">
                          <span
                            className={
                              scrollX == "right"
                                ? "active table-arrow arrow-left"
                                : "disable table-arrow arrow-left"
                            }
                            onClick={() => SlideSeeAllTable("left")}
                          >
                            <TableRightArrow />
                          </span>

                          <span
                            className={
                              scrollX == "left"
                                ? "active table-arrow arrow-right"
                                : "disable table-arrow arrow-right"
                            }
                            onClick={() => SlideSeeAllTable("right")}
                          >
                            <TableRightArrow />
                          </span>
                        </Box>
                      )}
                      <Table className="sport-expert-table">
                        <TableHead>
                          <TableRow>
                            {tableHeaderData && tableHeaderData?.length > 0 ? (
                              tableHeaderData?.map((item, index) => {
                                return (
                                  <TableCell key={index}>
                                    <span className="expert-teamname">
                                      {item?.homeTeam}
                                    </span>

                                    <Box>VS</Box>

                                    <span className="expert-teamname">
                                      {item?.awayTeam}
                                    </span>
                                  </TableCell>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* {bookmakerFlucsData.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item?.open}</TableCell>
                              {item?.bookmakerFlus?.map((flus, flusIndex) => {
                                return (
                                  <>
                                    <TableCell key={flusIndex}>
                                      {flus}
                                    </TableCell>
                                  </>
                                );
                              })}
                            </TableRow>
                          ))} */}
                          {tipDetailData?.map((item, index) => {
                            return (
                              <>
                                <TableRow key={index}>
                                  {item?.selectedTeam?.map((sItem, sindex) => {
                                    return (
                                      <TableCell key={sindex}>
                                        {sItem?.homeTeam?.isTip == 1 ? (
                                          <Box className="winner-team-image-wrap">
                                            <img
                                              src={
                                                sItem?.homeTeam?.flag?.includes(
                                                  "uploads"
                                                )
                                                  ? Config.mediaURL +
                                                    sItem?.homeTeam?.flag
                                                  : Melbourne
                                              }
                                              className="winner-team-img"
                                            />
                                          </Box>
                                        ) : sItem?.awayTeam?.isTip == 1 ? (
                                          <Box className="winner-team-image-wrap">
                                            <img
                                              src={
                                                sItem?.awayTeam?.flag?.includes(
                                                  "uploads"
                                                )
                                                  ? Config.mediaURL +
                                                    sItem?.awayTeam?.flag
                                                  : Brisbane
                                              }
                                              className="winner-team-img"
                                            />
                                          </Box>
                                        ) : (
                                          <></>
                                        )}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box className="nodata">
                  There is no information available here.
                </Box>
              </>
            )}
            {relatedTipsData && relatedTipsData?.length > 0 ? (
              <>
                <Box className="related-tips-box">
                  <Typography className="title">Related Tips</Typography>
                </Box>
                {relatedTipsLoader ? (
                  <div className="allsport-loader-center ">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <Box className="related-tips-box mt-0">
                      <Box className="racing-slider-wrap">
                        <Slider
                          {...settingsSport}
                          afterChange={handleAfterChange}
                          className="racing-slick-slider"
                          ref={carouselRef}
                        >
                          {relatedTipsData?.map(item => {
                            return (
                              <>
                                <Box className="racing-slider-details cursor-pointer">
                                  <Box
                                    className="tip-card-box"
                                    onClick={() => {
                                      const nameSlug = item?.tournamentName
                                        ? item?.tournamentName
                                            .trim()
                                            .toLowerCase()
                                            .replaceAll(" ", "-")
                                        : "name";

                                      navigate(
                                        `/teamsports/${params?.type}/sport-expert-tips/${nameSlug}/${item?.round}/${item?.id}/${item?.tournamentId}`
                                      );
                                    }}
                                  >
                                    <Box className="content-box">
                                      <Box className="chip-sec">
                                        <Typography className="chip-txt">
                                          {item?.tournamentName}
                                        </Typography>
                                      </Box>
                                      <Typography className="title">
                                        Expert Tips for{" "}
                                        {params?.type === "cricket"
                                          ? "Day"
                                          : "Round"}{" "}
                                        {item?.round}
                                      </Typography>
                                      <Typography className="time">
                                        {moment(item?.updatedAt).format("ddd")}{" "}
                                        {moment(item?.updatedAt).format(
                                          "DD MMMM, YYYY"
                                        )}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            );
                          })}
                        </Slider>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
          <OurPartner />
        </Box>
      </Box>
    </>
  );
};

export default SportExpertTipsDetailsPage;
