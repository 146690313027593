import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Tab, Tabs } from "@material-ui/core";
import { IntlContext } from "src/App";
import { Config } from "../../../../../helpers/context/config";
import { useParams } from "react-router-dom";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import { ReactComponent as FootballPlayerIn } from "../../../../../assets/images/SportIcon/footballIcon/FootballPlayerIn.svg";
import { ReactComponent as FootballPlayerOut } from "../../../../../assets/images/SportIcon/footballIcon/FootballPlayerOut.svg";
import { ReactComponent as MedicalPlus } from "../../../../../assets/images/SportIcon/footballIcon/medical-plus.svg";
import { ReactComponent as Unknown } from "../../../../../assets/images/SportIcon/footballIcon/Unknown.svg";
import { ReactComponent as RedCard } from "../../../../../assets/images/SportIcon/footballIcon/redCard.svg";
import { ReactComponent as YellowCards } from "../../../../../assets/images/SportIcon/footballIcon/yellowCard.svg";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import NoDataComp from "src/views/component/UI/NoData";
import _ from "lodash";

const LineUpInfo = ({ fixtureInfoData, singleMatchData }) => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tabvalue, setTabValue] = useState(1);
  const [lineupInfo, setLineupInfo] = useState([]);
  const [isLineupLoading, setIsLineupLoading] = useState(false);
  const [substitutesHome, setSubstitutesHome] = useState([]);
  const [substitutesAway, setSubstitutesAway] = useState([]);
  const [substitutesLoading, setSubstitutesLoading] = useState(false);
  const [injuredplayersLoading, setInjuredplayersLoading] = useState(false);
  const [injuredplayersHome, setInjuredplayersHome] = useState([]);
  const [injuredplayersAway, setInjuredplayersAway] = useState([]);

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    fetchLineupInfo();
    fetchSubstitutesinfo();
    fetchInjuredplayersInfo();
  }, [params]);

  const getType = type => {
    if (type) {
      return type == "COACH"
        ? "Coach"
        : type == "SUBSTITUTE_PLAYER"
        ? "Substitute Players"
        : type == "MIDFIELDER"
        ? "Midfielder"
        : type == "DEFENDER"
        ? "Defender"
        : type == "FORWARD"
        ? "Forward"
        : type == "GOALKEEPER"
        ? "Goalkeeper"
        : type;
    }
  };

  const fetchLineupInfo = async () => {
    setIsLineupLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `sports/statistics/soccer/event/line-ups/${params?.id}`
      );
      if (status === 200) {
        let responseData = data?.result?.result?.[0]?.SoccerLineups;
        let responseFo = data?.result?.result?.[0]?.Fo;

        if (responseData?.length > 0) {
          let homeTeamData = responseData?.filter(
            el => el?.teamId == data?.result?.result?.[0]?.homeTeamId
          );
          let awayTeamData = responseData?.filter(
            el => el?.teamId == data?.result?.result?.[0]?.awayTeamId
          );

          var homeTeamGroupedData = homeTeamData
            ?.filter(a => a?.PosA != null)
            ?.sort((a, b) => a?.PosA - b?.PosA)
            ?.reduce((acc, obj) => {
              const { Pon, ...rest } = obj;
              const groupName = getType(Pon.toUpperCase());
              acc[groupName] = acc[groupName] || [];
              acc[groupName].push(rest);

              return acc;
            }, {});
          var awayTeamGroupedData = awayTeamData
            ?.filter(a => a?.PosA != null)
            ?.sort((a, b) => a?.PosA - b?.PosA)
            ?.reduce((acc, obj) => {
              const { Pon, ...rest } = obj;
              const groupName = getType(Pon.toUpperCase());

              acc[groupName] = acc[groupName] || [];
              acc[groupName].push(rest);

              return acc;
            }, {});
        }
        if (responseFo?.length > 0) {
          var homeTeamFO = responseFo?.filter(
            el => el?.teamId == data?.result?.result?.[0]?.homeTeamId
          );
          var awayTeamFO = responseFo?.filter(
            el => el?.teamId == data?.result?.result?.[0]?.awayTeamId
          );
          var homeTeamFOData = homeTeamFO?.[0]?.Fo;
          var awayTeamFOData = awayTeamFO?.[0]?.Fo;
        }
        const LineUpTeamData = [
          {
            id: 1,
            team: "hometeam",
            formation: homeTeamFOData ? homeTeamFOData : "[]",
            data: homeTeamGroupedData ? homeTeamGroupedData : ""
          },
          {
            id: 2,
            team: "awayteam",
            formation: awayTeamFOData ? awayTeamFOData : "[]",
            data: awayTeamGroupedData ? awayTeamGroupedData : ""
          }
        ];
        setLineupInfo(LineUpTeamData);
        setIsLineupLoading(false);
      } else {
        setIsLineupLoading(false);
      }
    } catch (err) {
      setIsLineupLoading(false);
    }
  };

  const groupingData = data => {
    const groupedData = data?.reduce((result, item) => {
      const key = item?.Min;
      result[key] = result[key] || [];
      result[key].push(item);
      return result;
    }, {});

    const finalResult = {};
    Object.keys(groupedData).forEach(key => {
      const groupedArray = groupedData[key];
      finalResult[key] = [];
      for (let i = 0; i < groupedArray?.length; i += 2) {
        finalResult[key].push(groupedArray?.slice(i, i + 2));
      }
    });
    return finalResult;
  };

  const fetchSubstitutesinfo = async () => {
    setSubstitutesLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `sports/statistics/soccer/event/line-ups/substitutes/${params?.id}`
      );
      if (status === 200) {
        const responseData = data?.result?.result?.[0]?.SubstitutePlayer;
        if (responseData?.length > 0) {
          const homeTeam = responseData?.filter(el => el?.Nm === "1");
          const awayTeam = responseData?.filter(el => el?.Nm === "2");
          const finalHome = await groupingData(homeTeam);
          const finalAway = await groupingData(awayTeam);
          setSubstitutesHome(finalHome);
          setSubstitutesAway(finalAway);
        }
        setSubstitutesLoading(false);
      } else {
        setSubstitutesLoading(false);
      }
    } catch (err) {
      setSubstitutesLoading(false);
    }
  };

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Brisbane} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Brisbane} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Melbourne} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Melbourne} alt="Odds Icon" />;
      }
    }
  };

  const fetchInjuredplayersInfo = async () => {
    setInjuredplayersLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `sports/statistics/soccer/event/line-ups/injured-players/${params?.id}`
      );
      if (status === 200) {
        const responsePalyer = data?.result?.result;
        if (responsePalyer?.length > 0) {
          const homeTeam = responsePalyer?.filter(
            el => el?.teamId === singleMatchData?.homeTeamId
          );
          const awayTeam = responsePalyer?.filter(
            el => el?.teamId === singleMatchData?.awayTeamId
          );
          const customOrder = [3, 4, 1, 2]; // [suspended,injured,RedCard,yellowCard]
          const HomeTeamSort = homeTeam?.sort((a, b) => {
            const indexA = customOrder?.indexOf(a?.Rt);
            const indexB = customOrder?.indexOf(b?.Rt);
            return indexA - indexB;
          });
          const AwayTeamSort = awayTeam?.sort((a, b) => {
            const indexA = customOrder?.indexOf(a?.Rt);
            const indexB = customOrder?.indexOf(b?.Rt);
            return indexA - indexB;
          });
          setInjuredplayersHome(HomeTeamSort);
          setInjuredplayersAway(AwayTeamSort);
        }
        setInjuredplayersLoading(false);
      } else {
        setInjuredplayersLoading(false);
      }
    } catch (err) {
      setInjuredplayersLoading(false);
    }
  };

  const getInjuredStatus = status => {
    if (status == 3) {
      return <Unknown />;
    } else if (status == 4) {
      return <MedicalPlus />;
    } else if (status == 1) {
      return <RedCard className="card-center" />;
    } else if (status == 2) {
      return <YellowCards className="card-center" />;
    }
  };
  return (
    <>
      <Box className="lineup-info-wrap">
        {isLineupLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : lineupInfo?.[0]?.data && lineupInfo?.[1]?.data ? (
          screenWidth >= 1023 ? (
            <Box className="lineup-info-inner-wrap">
              {lineupInfo?.map((item, index) => (
                <>
                  <Box className="team-card" key={index}>
                    <Box className="card-heading">
                      <Box className="d-flex align-center team-wrap">
                        <span className="team-img-wrap">
                          {fetchTeamlogo(singleMatchData, item?.team)}
                        </span>
                        <span className="team-name medium lineup-team ">
                          {item?.team === "hometeam"
                            ? singleMatchData?.homeTeam?.name
                              ? singleMatchData?.homeTeam?.name
                              : ""
                            : singleMatchData?.awayTeam?.name
                            ? singleMatchData?.awayTeam?.name
                            : ""}
                        </span>
                      </Box>
                      <Box className="team-score">
                        <Typography>
                          {item?.formation
                            ? JSON.parse(item?.formation)?.join("-")
                            : ""}
                        </Typography>
                      </Box>
                    </Box>
                    {Object.entries(item?.data)?.map(([category, obj]) => (
                      <Box className="player-details-wrap">
                        <Typography className="player-postion">
                          {category}
                        </Typography>
                        {obj?.map((player, playerIndex) => (
                          <>
                            <Box
                              className="d-flex align-center player-details"
                              key={playerIndex}
                            >
                              <Typography className="player-name">
                                {player?.SoccerPlayer?.name}
                              </Typography>
                              {player?.Snu ? (
                                <Typography className="player-score">
                                  {player?.Snu}
                                </Typography>
                              ) : null}
                            </Box>
                          </>
                        ))}
                      </Box>
                    ))}
                  </Box>
                </>
              ))}
            </Box>
          ) : (
            <Box className="lineup-info-inner-wrap">
              <Tabs
                value={tabvalue}
                variant="scrollable"
                scrollButtons="off"
                indicatorColor="primary"
                textColor="primary"
                className="lineup-tab-detail"
              >
                {lineupInfo?.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Tab
                        label={
                          <Box className="d-flex align-center team-wrap">
                            <span className="team-img-wrap">
                              {fetchTeamlogo(singleMatchData, item?.team)}
                            </span>
                            <span className="team-name medium lineup-team ">
                              {item?.team === "hometeam"
                                ? singleMatchData?.homeTeam?.name
                                  ? singleMatchData?.homeTeam?.name
                                  : ""
                                : singleMatchData?.awayTeam?.name
                                ? singleMatchData?.awayTeam?.name
                                : ""}
                            </span>
                          </Box>
                        }
                        value={item?.id}
                        className={item?.id === tabvalue ? "active " : ""}
                        onChange={(event, newValue) =>
                          handleTabChange(event, item?.id)
                        }
                      />
                    </Box>
                  );
                })}
              </Tabs>
              {lineupInfo
                ?.filter(item => item?.id === tabvalue)
                ?.map((item, index) => {
                  return (
                    <>
                      <Box className="team-score">
                        <Typography>
                          {" "}
                          {item?.formation
                            ? JSON.parse(item?.formation)?.join("-")
                            : ""}
                        </Typography>
                      </Box>
                      {Object.entries(item?.data)?.map(([category, obj]) => (
                        <Box className="player-details-wrap">
                          <Typography className="player-postion">
                            {category}
                          </Typography>
                          {obj?.map((player, playerIndex) => (
                            <>
                              <Box
                                className="d-flex align-center player-details"
                                key={playerIndex}
                              >
                                <Typography className="player-name">
                                  {player?.SoccerPlayer?.name}
                                </Typography>
                                {player?.Snu ? (
                                  <Typography className="player-score">
                                    {player?.Snu}
                                  </Typography>
                                ) : null}
                              </Box>
                            </>
                          ))}
                        </Box>
                      ))}
                    </>
                  );
                })}
            </Box>
          )
        ) : (
          <Box className="no-match"> {localesData?.NO_DATA?.NO_LINEUP}</Box>
        )}
        {lineupInfo?.[0]?.data && lineupInfo?.[1]?.data ? (
          <>
            {Object.entries(substitutesHome)?.length > 0 &&
            Object.entries(substitutesAway)?.length > 0 ? (
              <Box className="substitutes-wrap">
                <Typography variant="h4" className="fixture-heading">
                  SUBSTITUTIONS
                </Typography>
                <Box className="substitutes-details-wrap">
                  {substitutesLoading ? (
                    <div className="allsport-loader-center">
                      <Loader />
                    </div>
                  ) : screenWidth >= 1023 ? (
                    <>
                      <Box className="substitutes-card">
                        {Object.entries(substitutesHome)?.map(
                          ([category, items]) => {
                            return (
                              <>
                                {items?.map((obj, index) => {
                                  return (
                                    <>
                                      <Box className="card-details" key={index}>
                                        <Box className="substitutes-time-wrap">
                                          <Typography className="time">
                                            {category}'
                                          </Typography>
                                        </Box>
                                        <Box className="substitutes-player-details-wrap">
                                          <Box className="player-wrap mb-4">
                                            <Box className="icon-wrap">
                                              <FootballPlayerOut />
                                            </Box>
                                            <Typography className="player">
                                              {screenWidth >= 799
                                                ? obj?.[0]?.player?.name
                                                : obj?.[0]?.Fn?.charAt(0) +
                                                  "." +
                                                  " " +
                                                  obj?.[0]?.Ln}
                                            </Typography>
                                          </Box>
                                          <Box className="player-wrap">
                                            <Box className="icon-wrap">
                                              <FootballPlayerIn />
                                            </Box>
                                            <Typography className="player">
                                              {screenWidth >= 799
                                                ? obj?.[1]?.player?.name
                                                : obj?.[1]?.Fn?.charAt(0) +
                                                  "." +
                                                  " " +
                                                  obj?.[1]?.Ln}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </>
                                  );
                                })}
                              </>
                            );
                          }
                        )}
                      </Box>
                      <Box className="substitutes-card">
                        {Object.entries(substitutesAway)?.map(
                          ([category, items]) => {
                            return (
                              <>
                                {items?.map((obj, index) => {
                                  return (
                                    <>
                                      <Box className="card-details" key={index}>
                                        <Box className="substitutes-time-wrap">
                                          <Typography className="time">
                                            {category}'
                                          </Typography>
                                        </Box>
                                        <Box className="substitutes-player-details-wrap">
                                          <Box className="player-wrap mb-4">
                                            <Box className="icon-wrap">
                                              <FootballPlayerOut />
                                            </Box>
                                            <Typography className="player">
                                              {screenWidth >= 799
                                                ? obj?.[0]?.player?.name
                                                : obj?.[0]?.Fn?.charAt(0) +
                                                  "." +
                                                  " " +
                                                  obj?.[0]?.Ln}
                                            </Typography>
                                          </Box>
                                          <Box className="player-wrap">
                                            <Box className="icon-wrap ">
                                              <FootballPlayerIn />
                                            </Box>
                                            <Typography className="player">
                                              {screenWidth >= 799
                                                ? obj?.[1]?.player?.name
                                                : obj?.[1]?.Fn?.charAt(0) +
                                                  "." +
                                                  " " +
                                                  obj?.[1]?.Ln}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </>
                                  );
                                })}
                              </>
                            );
                          }
                        )}
                      </Box>{" "}
                    </>
                  ) : tabvalue === 1 ? (
                    <Box className="substitutes-card">
                      {Object.entries(substitutesHome)?.map(
                        ([category, items]) => {
                          return (
                            <>
                              {items?.map((obj, index) => {
                                return (
                                  <>
                                    <Box className="card-details" key={index}>
                                      <Box className="substitutes-time-wrap">
                                        <Typography className="time">
                                          {category}'
                                        </Typography>
                                      </Box>
                                      <Box className="substitutes-player-details-wrap">
                                        <Box className="player-wrap mb-4">
                                          <Box className="icon-wrap">
                                            <FootballPlayerOut />
                                          </Box>
                                          <Typography className="player">
                                            {screenWidth >= 799
                                              ? obj?.[0]?.player?.name
                                              : obj?.[0]?.Fn?.charAt(0) +
                                                "." +
                                                " " +
                                                obj?.[0]?.Ln}
                                          </Typography>
                                        </Box>
                                        <Box className="player-wrap">
                                          <Box className="icon-wrap">
                                            <FootballPlayerIn />
                                          </Box>
                                          <Typography className="player">
                                            {screenWidth >= 799
                                              ? obj?.[1]?.player?.name
                                              : obj?.[1]?.Fn?.charAt(0) +
                                                "." +
                                                " " +
                                                obj?.[1]?.Ln}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </>
                                );
                              })}
                            </>
                          );
                        }
                      )}
                    </Box>
                  ) : (
                    <Box className="substitutes-card">
                      {Object.entries(substitutesAway)?.map(
                        ([category, items]) => {
                          return (
                            <>
                              {items?.map((obj, index) => {
                                return (
                                  <>
                                    <Box className="card-details" key={index}>
                                      <Box className="substitutes-time-wrap">
                                        <Typography className="time">
                                          {category}'
                                        </Typography>
                                      </Box>
                                      <Box className="substitutes-player-details-wrap">
                                        <Box className="player-wrap mb-4">
                                          <Box className="icon-wrap">
                                            <FootballPlayerOut />
                                          </Box>
                                          <Typography className="player">
                                            {screenWidth >= 799
                                              ? obj?.[0]?.player?.name
                                              : obj?.[0]?.Fn?.charAt(0) +
                                                "." +
                                                " " +
                                                obj?.[0]?.Ln}
                                          </Typography>
                                        </Box>
                                        <Box className="player-wrap">
                                          <Box className="icon-wrap ">
                                            <FootballPlayerIn />
                                          </Box>
                                          <Typography className="player">
                                            {screenWidth >= 799
                                              ? obj?.[1]?.player?.name
                                              : obj?.[1]?.Fn?.charAt(0) +
                                                "." +
                                                " " +
                                                obj?.[1]?.Ln}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </>
                                );
                              })}
                            </>
                          );
                        }
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <></>
            )}
            {injuredplayersHome?.length > 0 &&
            injuredplayersAway?.length > 0 ? (
              <Box className="substitutes-wrap">
                <Typography variant="h4" className="fixture-heading">
                  injries & suspensions
                </Typography>
                <Box className="substitutes-details-wrap">
                  {injuredplayersLoading ? (
                    <div className="allsport-loader-center">
                      <Loader />
                    </div>
                  ) : screenWidth >= 1023 ? (
                    <>
                      <Box className="substitutes-card">
                        {injuredplayersHome?.map((items, index) => {
                          return (
                            <>
                              <Box className="card-details" key={index}>
                                <Box className="icon-wrap">
                                  {getInjuredStatus(items?.Rt)}
                                </Box>
                                <Box className="substitutes-player-details-wrap">
                                  <Box className="player-wrap mb-4">
                                    <Typography className="player fw-700">
                                      {screenWidth >= 799
                                        ? items?.SoccerPlayer?.name
                                        : items?.Fn?.charAt(0) +
                                          "." +
                                          " " +
                                          items?.Ln}
                                    </Typography>
                                  </Box>
                                  <Box className="player-wrap">
                                    <Typography className="player">
                                      {items?.Rs ?? ""}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </>
                          );
                        })}
                      </Box>
                      <Box className="substitutes-card">
                        {injuredplayersAway?.map((items, index) => {
                          return (
                            <>
                              <Box className="card-details" key={index}>
                                <Box className="icon-wrap">
                                  {getInjuredStatus(items?.Rt)}
                                </Box>
                                <Box className="substitutes-player-details-wrap">
                                  <Box className="player-wrap mb-4">
                                    <Typography className="player fw-700">
                                      {screenWidth >= 799
                                        ? items?.SoccerPlayer?.name
                                        : items?.Fn?.charAt(0) +
                                          "." +
                                          " " +
                                          items?.Ln}
                                    </Typography>
                                  </Box>
                                  <Box className="player-wrap">
                                    <Typography className="player">
                                      {items?.Rs ?? ""}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </>
                          );
                        })}
                      </Box>{" "}
                    </>
                  ) : tabvalue === 1 ? (
                    <Box className="substitutes-card">
                      {injuredplayersHome?.map((items, index) => {
                        return (
                          <>
                            <Box className="card-details" key={index}>
                              <Box className="icon-wrap">
                                {getInjuredStatus(items?.Rt)}
                              </Box>
                              <Box className="substitutes-player-details-wrap">
                                <Box className="player-wrap mb-4">
                                  <Typography className="player fw-700">
                                    {screenWidth >= 799
                                      ? items?.SoccerPlayer?.name
                                      : items?.Fn?.charAt(0) +
                                        "." +
                                        " " +
                                        items?.Ln}
                                  </Typography>
                                </Box>
                                <Box className="player-wrap">
                                  <Typography className="player">
                                    {items?.Rs ?? ""}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        );
                      })}
                    </Box>
                  ) : (
                    <Box className="substitutes-card">
                      {injuredplayersAway?.map((items, index) => {
                        return (
                          <>
                            <Box className="card-details" key={index}>
                              <Box className="icon-wrap">
                                {getInjuredStatus(items?.Rt)}
                              </Box>
                              <Box className="substitutes-player-details-wrap">
                                <Box className="player-wrap mb-4">
                                  <Typography className="player fw-700">
                                    {screenWidth >= 799
                                      ? items?.SoccerPlayer?.name
                                      : items?.Fn?.charAt(0) +
                                        "." +
                                        " " +
                                        items?.Ln}
                                  </Typography>
                                </Box>
                                <Box className="player-wrap">
                                  <Typography className="player">
                                    {items?.Rs ?? ""}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default LineUpInfo;
