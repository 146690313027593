import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Tab, Tabs } from "@material-ui/core";
import { useParams } from "react-router";
import { IntlContext } from "src/App";
// import { commentaryData } from "./livescoreSummary";
// import { liveData } from "./livescoreData";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";

const summaryData = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  }
];

const summaryTabData = [
  {
    id: 1,
    name: "Commentary"
  },
  {
    id: 2,
    name: "Wickets"
  }
];

const InfoSummary = ({ singleMatchData }) => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [tabvalue, setTabValue] = useState(1);
  const [wicketTabvalue, setWicketTabvalue] = useState(1);
  const [commentaryData, setCommentaryData] = useState([]);
  const [commentaryLoading, setCommentaryLoading] = useState(false);
  const [wicketsData, setWicketsData] = useState([]);
  const [wicketsLoading, setWicketsLoading] = useState(false);

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };
  const handleWicketTabChange = (event, value) => {
    setWicketTabvalue(value);
  };

  useEffect(() => {
    fetchMatchCommentary();
    fetchMatchwickets();
  }, [params]);

  const fetchMatchCommentary = async () => {
    setCommentaryLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/cricket/commentary/${params?.id}?limit=500&offset=0`
      );
      if (status === 200) {
        setCommentaryData(data?.result?.result);
        setCommentaryLoading(false);
      } else {
        setCommentaryLoading(false);
      }
    } catch (err) {
      setCommentaryLoading(false);
    }
  };

  const fetchMatchwickets = async () => {
    setWicketsLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/cricket/wickets/${params?.id}`
      );
      if (status === 200) {
        const newWicketData = data?.result?.map((item, index) => {
          return {
            ...item,
            dataId: index + 1
          };
        });
        setWicketsData(newWicketData);
        setWicketsLoading(false);
      } else {
        setWicketsLoading(false);
      }
    } catch (err) {
      setWicketsLoading(false);
    }
  };

  const optimizeName = fullName => {
    if (fullName) {
      const names = fullName?.split(" ");
      const optimizedName = names?.[0]?.[0] + " " + names?.[names?.length - 1];
      return optimizedName;
    } else return "";
  };

  const getWicketsType = type => {
    if (type === 30) {
      return "Run OUT. ";
    } else if (type === 25) {
      return "Catch OUT. ";
    } else if (type === 20) {
      return "LBW OUT. ";
    } else if (type === 23) {
      return "Clean Bowled. ";
    } else if (type === 26) {
      return "Catch Out. ";
    } else if (type === 35) {
      return "Out Stumped. ";
    } else if (type === 45) {
      return "Timed OUT. ";
    } else if (type === 15) {
      return "Hit Wicket. ";
    } else {
      return "";
    }
  };

  return (
    <>
      {Number(params?.SportId) !== 4 ? (
        <Box className="fixture-summary-wrap">
          {summaryData?.map((item, index) => {
            return (
              <>
                <Box className="summary-details" key={index}>
                  <Typography className="details-text light">11'</Typography>
                  <Typography className="details-text dark">
                    Raul Jimenez
                  </Typography>
                  <Typography className="details-text dark">0 - 1</Typography>
                </Box>
              </>
            );
          })}
        </Box>
      ) : (
        <>
          {/* {commentaryData?.length > 0 && wicketsData?.length > 0 ? ( */}
          <Box className="fixture-summary-wrap scorecard-wrap">
            <Tabs
              value={tabvalue}
              variant="scrollable"
              scrollButtons="off"
              indicatorColor="primary"
              textColor="primary"
              className="scorecard-tab-detail"
            >
              {summaryTabData?.map((item, index) => {
                return (
                  <Box key={index}>
                    <Tab
                      label={
                        <Box className="d-flex align-center team-wrap">
                          <span>{item?.name}</span>
                        </Box>
                      }
                      value={item?.id}
                      className={item?.id === tabvalue ? "active " : ""}
                      onChange={(event, newValue) =>
                        handleTabChange(event, item?.id)
                      }
                    />
                  </Box>
                );
              })}
            </Tabs>
            {tabvalue === 1 ? (
              commentaryLoading ? (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              ) : commentaryData?.length > 0 ? (
                <Box className="summary-commentary-wrap">
                  <Box className="commentary-section">
                    {commentaryData?.map((item, index) => {
                      return (
                        <>
                          {item?.S === "End of over" ||
                          item?.S === undefined ||
                          item?.S === null ||
                          item?.S === "" ? (
                            <Box className="end-of-over">
                              {item?.S === undefined ||
                              item?.S === null ||
                              item?.S === ""
                                ? item?.T
                                : item?.S + ". " + item?.T}
                            </Box>
                          ) : (
                            <Box className="d-flex commentary-wrap">
                              <Box className="overs">
                                <Typography className="overs-text">
                                  {item?.Ov}
                                </Typography>
                              </Box>
                              <Box className="score">
                                <Typography
                                  className={
                                    item?.S === "Four runs"
                                      ? "Four-runs"
                                      : item?.S === "Six runs"
                                      ? "Six-runs"
                                      : item?.S === "Wide"
                                      ? "Wide"
                                      : item?.S === "Wicket"
                                      ? "wickets"
                                      : "score-text"
                                  }
                                >
                                  {item?.Sv}
                                </Typography>
                              </Box>
                              <Box className="commentary">
                                <Typography className="bowler-batsman">
                                  {item?.bowler &&
                                    optimizeName(item?.bowler?.name) +
                                      " to"}{" "}
                                  {item?.betting &&
                                    optimizeName(item?.betting?.name)}
                                </Typography>
                                <Typography className="commentary-cont">
                                  {item?.S}. {item?.T}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </>
                      );
                    })}
                  </Box>
                </Box>
              ) : (
                <Box className="no-match no-match-boxshadow">
                  {" "}
                  {localesData?.NO_DATA?.NO_COMMENTARY}
                </Box>
              )
            ) : wicketsLoading ? (
              <div className="allsport-loader-center">
                <Loader />
              </div>
            ) : wicketsData?.length > 0 ? (
              <Box>
                <Tabs
                  value={wicketTabvalue}
                  variant="scrollable"
                  scrollButtons="off"
                  indicatorColor="primary"
                  textColor="primary"
                  className="scorecard-tab-detail wicket-tab-details"
                >
                  {wicketsData?.map((item, index) => {
                    return (
                      <Box key={index}>
                        <Tab
                          label={
                            <Box className="d-flex align-center team-wrap">
                              <span>{item?.Ti}</span>
                            </Box>
                          }
                          value={item?.dataId}
                          className={
                            item?.dataId === wicketTabvalue ? "active " : ""
                          }
                          onChange={(event, newValue) =>
                            handleWicketTabChange(event, item?.dataId)
                          }
                        />
                      </Box>
                    );
                  })}
                </Tabs>
                {wicketsData
                  ?.filter(item => item?.dataId === wicketTabvalue)
                  ?.map((data, index) => {
                    return (
                      <>
                        {data?.CricketFallOfWickets?.length > 0 ? (
                          <Box className="wicket-section">
                            {data?.CricketFallOfWickets?.map(
                              (obj, indexWicket) => {
                                return (
                                  <>
                                    <Box className="d-flex wickets-wrap">
                                      <Box className="wickets-overs">
                                        <Typography className="wickets-overs-text">
                                          {obj?.B}
                                        </Typography>
                                      </Box>
                                      <Box className="score">
                                        <Typography className="wickets">
                                          W
                                        </Typography>
                                      </Box>
                                      <Box className="commentary">
                                        <Box className="d-flex align-center wickets-commentary">
                                          <Typography className="bowler-batsman">
                                            {obj?.bowler &&
                                              optimizeName(obj?.bowler?.name) +
                                                " to"}{" "}
                                            {obj?.betting &&
                                              optimizeName(obj?.betting?.name)}
                                          </Typography>
                                          <Typography className="bowler-batsman">
                                            {obj?.R && obj?.R}/
                                            {obj?.WkN && obj?.WkN}
                                          </Typography>
                                        </Box>
                                        <Typography className="commentary-cont">
                                          {getWicketsType(obj?.Wk)} {obj?.Co}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </>
                                );
                              }
                            )}
                          </Box>
                        ) : (
                          <Box className="no-match no-match-boxshadow">
                            {" "}
                            No Wickets Availble
                          </Box>
                        )}
                      </>
                    );
                  })}
              </Box>
            ) : (
              <Box className="no-match no-match-boxshadow">
                {" "}
                {localesData?.NO_DATA?.NO_WICKETS}
              </Box>
            )}
          </Box>
          {/* ) : (
        <Box className="no-match no-match-boxshadow">
          {" "}
          {localesData?.NO_DATA?.NO_SUMMARY}
        </Box>
      )} */}
        </>
      )}
    </>
  );
};

export default InfoSummary;
