import React from "react";
import Page from "src/components/Page";
import SOCSignupPage from "src/views/component/socSignupPage";

const SOCSignup = () => {
  return (
    <Page title="SOCSignup">
      <SOCSignupPage />
    </Page>
  );
};

export default SOCSignup;
