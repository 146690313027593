import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  withStyles
} from "@material-ui/core";
import { IntlContext } from "src/App";
import { Config } from "../../../../../helpers/context/config";
import DefaultTeam from "src/assets/images/defaultTeam.png";
import { ReactComponent as Bat } from "../../../../../assets/images/SportIcon/bat.svg";
import { ReactComponent as Ball } from "../../../../../assets/images/SportIcon/ball.svg";
import { useParams } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import NoDataComp from "src/views/component/UI/NoData";
import SportsOdds from "src/views/component/sportsOdds/SportsOdds";

const matchStatsData = [
  {
    id: 1,
    homeStats: 8,
    stats: "Corner Kicks",
    awayStats: 8
  },
  {
    id: 2,
    homeStats: 1,
    stats: "Yellow Cards",
    awayStats: 2
  },
  {
    id: 3,
    homeStats: 1,
    stats: "Yellow Red Cards",
    awayStats: 8
  },
  {
    id: 4,
    homeStats: 1,
    stats: "Red Cards",
    awayStats: 5
  }
];

const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    minWidth: "45px",
    boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 799px)": {
      maxWidth: "45px",
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    background: "#FFFFFF",
    boxShadow: "inset 1px 0px 6px 0px #ebebeb",
    "@media(max-width: 799px)": {
      boxShadow: "inset 1px 0px 6px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const MatchStats = ({ fixtureInfoData, individualTeamData }) => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const classes = useStyles();
  const localesData = intlContext?.messages;
  const [tabvalue, setTabValue] = useState(1);
  const [scoreboardData, setscoreboardData] = useState([]);
  const [scoreBoardDataLength, setScoreBoardDataLength] = useState([]);
  const [scoreboardLoading, setscoreboardLoading] = useState(false);

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={DefaultTeam} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={DefaultTeam} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={DefaultTeam} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={DefaultTeam} alt="Odds Icon" />;
      }
    }
  };

  const TeamStats = (data, type) => {
    const total = data?.homeStats + data?.awayStats;
    const width = (
      ((type === "homeTeam" ? data?.homeStats : data?.awayStats) / total) *
      100
    ).toFixed(2);
    return (
      <>
        <Box
          className="progress-bar"
          style={{
            width: `${width}%`,
            background: width > 50 ? "#003764" : "#D4D6D8"
          }}
        ></Box>
      </>
    );
  };

  useEffect(() => {
    fetchTeamTableData();
  }, [params]);

  const fetchTeamTableData = async () => {
    setscoreboardLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/rl/event/stats/${params?.id}`
      );
      if (status === 200) {
        const filterData = data?.result?.result?.filter(
          item => item?.period == "ALL"
        );
        setScoreBoardDataLength(filterData?.length);
        const cricketMatchInnings = filterData?.map((item, index) => {
          return {
            ...item,
            dataId: index + 1,
            id: item?.id,
            homeStats: item?.homeValue,
            stats: item?.name,
            awayStats: item?.awayValue
          };
        });
        setscoreboardData(cricketMatchInnings);
        setscoreboardLoading(false);
      } else {
        setscoreboardLoading(false);
      }
    } catch (err) {
      setscoreboardLoading(false);
    }
  };

  const batsmenStauts = data => {
    return (
      <>
        <Box>
          {data?.LpTx?.replace(
            "[F]",
            data?.Fplayer?.name?.split(" ")?.pop()
          )?.replace("[B]", data?.Bplayer?.name?.split(" ")?.pop())}
        </Box>
      </>
    );
  };

  const optimizeName = fullName => {
    if (fullName) {
      const names = fullName?.split(" ");
      const optimizedName = names?.[0]?.[0] + " " + names?.[names?.length - 1];
      return optimizedName;
    } else return "";
  };
  return (
    <>
      {scoreboardLoading ? (
        <div className="allsport-loader-center">
          <Loader />
        </div>
      ) : scoreBoardDataLength > 0 ? (
        <Box className="fixture-wrap">
          {scoreboardData?.length > 0 && (
            <Typography variant="h4" className="fixture-heading">
              {localesData?.FIXTURE_INFO?.MATCH_STATS}
            </Typography>
          )}

          <Box className="match-stats-wrap">
            <Box className="d-flex align-center match-team-heading">
              <Box className="d-flex align-center team-wrap home-team-wrap">
                <Box className="fixture-info-odds home-team-odds">
                  <SportsOdds
                    data={individualTeamData}
                    type={"odds"}
                    team={"hometeam"}
                    teamId={individualTeamData?.homeTeamId}
                    isScore={
                      individualTeamData?.status === "finished" ? true : false
                    }
                  />
                </Box>
                <Box className="stats-logo-name-wrap hometeam-logo-wrap">
                  <span className="team-name bold">
                    {fixtureInfoData?.homeTeam?.name
                      ? fixtureInfoData?.homeTeam?.name
                      : ""}
                  </span>
                  <span className="team-img-wrap">
                    {fetchTeamlogo(fixtureInfoData, "hometeam")}
                  </span>
                </Box>
              </Box>
              <Box className="bold divider">
                <span></span>
              </Box>
              <Box className="d-flex align-center team-wrap away-team-wrap">
                <Box className="stats-logo-name-wrap awayteam-logo-wrap">
                  <span className="team-img-wrap ">
                    {fetchTeamlogo(fixtureInfoData, "awayteam")}
                  </span>
                  <span className="team-name bold">
                    {fixtureInfoData?.awayTeam?.name
                      ? fixtureInfoData?.awayTeam?.name
                      : ""}
                  </span>
                </Box>
                <Box className="fixture-info-odds away-team-odds">
                  <SportsOdds
                    data={individualTeamData}
                    type={"odds"}
                    team={"awayteam"}
                    teamId={individualTeamData?.awayTeamId}
                    isScore={
                      individualTeamData?.status === "finished" ? true : false
                    }
                  />
                </Box>
              </Box>
            </Box>
            {scoreboardData?.map((data, index) => (
              <>
                <Box className="match-stats-details" key={index}>
                  <Box className="d-flex align-center stats-value-wrap">
                    <Typography className="details-value">
                      {data?.homeStats}
                    </Typography>
                    <Typography className="details-name">
                      {data?.stats}
                    </Typography>
                    <Typography className="details-value">
                      {data?.awayStats}
                    </Typography>
                  </Box>
                  <Box className="stats-progress-bar">
                    {TeamStats(data, "homeTeam")}
                    {TeamStats(data, "awayTeam")}
                  </Box>
                </Box>
              </>
            ))}
          </Box>
        </Box>
      ) : (
        <NoDataComp />
      )}
    </>
  );
};

export default MatchStats;
