import { Box } from "@material-ui/core";
import React, { useEffect } from "react";

export const ScriptSmartB = ({}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.charset = "utf-8";
    script.src =
      "https://www.buzzsprout.com/2234145.js?container_id=buzzsprout-large-player&player=large";
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return <Box className="podcasts-smartb" id="buzzsprout-large-player"></Box>;
};

export const ScriptRacingSmartB = ({}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.charset = "utf-8";
    script.src =
      "https://www.buzzsprout.com/2290603.js?container_id=buzzsprout-large-player&player=large";
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return <Box className="podcasts-smartb" id="buzzsprout-large-player"></Box>;
};
