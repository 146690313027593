import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  ClickAwayListener,
  Tab,
  Tabs
} from "@material-ui/core";
import { Config } from "../../../../../helpers/context/config";
import DefaultTeam from "src/assets/images/defaultTeam.png";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useParams } from "react-router";
import moment from "moment";
import Loader from "src/components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactComponent as RightBlack } from "../../../../../assets/images/icons/rightblack.svg";
import { ReactComponent as PlusBlack } from "../../../../../assets/images/icons/plusblack.svg";
import { ReactComponent as BlackbookRight } from "../../../../../assets/images/icons/blackbookRight.svg";
import { ReactComponent as BlackbookPlus } from "../../../../../assets/images/icons/blackbookPlus.svg";
import { ReactComponent as TooltipArrow } from "../../../../../assets/images/icons/tooltip_arrow.svg";
import { ReactComponent as AddBookIcon } from "../../../../../assets/images/add-blackbook.svg";
import SmartBookModal from "src/views/component/UI/smartbookModal";

const H2HInfoData = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  },
  {
    id: 5
  }
];

const tableTabData = [
  {
    id: 1,
    name: "H2H"
  },
  {
    id: 2,
    name: "Home"
  },
  {
    id: 3,
    name: "Away"
  }
];

const InfoH2H = ({ fixtureInfoData, singleMatchData }) => {
  const params = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tabvalue, setTabValue] = useState(1);
  const [h2hLoading, seth2hLoading] = useState(false);
  const [h2hData, seth2hData] = useState([]);
  const [matchCount, setMatchCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [clickToolTipTeamId, setClickToolTipTeamId] = useState(null);
  const [SBopen, setSBOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedSportId, setSelectedSportId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const rowPerPage = 20;

  const handleTabChange = (event, value) => {
    setTabValue(value);
    setOffset(0);
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (singleMatchData) {
      fetchH2HData(0);
    }
  }, [params, tabvalue, singleMatchData]);

  const fetchH2HData = async offset => {
    seth2hLoading(true);
    const h2hQuary =
      tabvalue === 1
        ? `awayTeamId=${singleMatchData?.awayTeamId ??
            ""}&homeTeamId=${singleMatchData?.homeTeamId ?? ""}`
        : tabvalue === 2
        ? `homeTeamId=${singleMatchData?.homeTeamId ?? ""}`
        : tabvalue === 3
        ? `awayTeamId=${singleMatchData?.awayTeamId ?? ""}`
        : "";
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/rl/event?${h2hQuary}&limit=${rowPerPage}&offset=${offset}`
      );
      if (status === 200) {
        let count = data?.result?.count / 20;
        setMatchCount(Math.ceil(count));
        seth2hData(data?.result?.result);
        seth2hLoading(false);
      } else {
        seth2hLoading(false);
      }
    } catch (err) {
      seth2hLoading(false);
    }
  };

  const handleOnScrollBottomMatch = async offset => {
    const h2hQuary =
      tabvalue === 1
        ? `awayTeamId=${singleMatchData?.awayTeamId ??
            ""}&homeTeamId=${singleMatchData?.homeTeamId ?? ""}`
        : tabvalue === 2
        ? `homeTeamId=${singleMatchData?.homeTeamId ?? ""}`
        : tabvalue === 3
        ? `awayTeamId=${singleMatchData?.awayTeamId ?? ""}`
        : "";
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/rl/event?${h2hQuary}&limit=${rowPerPage}&offset=${offset}`
      );
      if (status === 200) {
        setOffset(offset);
        seth2hData([...h2hData, ...data?.result?.result]);
      } else {
      }
    } catch (err) {}
  };

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={DefaultTeam} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={DefaultTeam} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={DefaultTeam} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={DefaultTeam} alt="Odds Icon" />;
      }
    }
  };

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  const getMatchStatus = status => {
    if (status == "FT") {
      return "Full Time";
    } else if (status == "HT") {
      return "Half Time";
    } else return status;
  };

  const handleModal = () => {
    setSBOpen(true);
  };

  const handleClose = () => {
    setSBOpen(false);
  };
  const handleBlackBookModal = (title, id, type, item) => {
    setSelectedTitle(title);
    setSelectedType(type);
    setSelectedId(id);
    setSelectedSportId(item?.SportId);
    handleModal();
  };

  const handleTooltipContentClick = (event, teamId, objindex) => {
    event.stopPropagation();
    setTooltipOpen(!tooltipOpen);
    setHoveredIndex(objindex);
    setClickToolTipTeamId(teamId);
  };
  const handleOutsideClick = (item, teamId, index) => {
    if (hoveredIndex === index && clickToolTipTeamId === teamId) {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    }
  };

  const handleOpenTooltip = (item, teamId, index, teamType, score) => {
    let tournamentName =
      item?.SportId === 4 && item?.CricketTournament?.name
        ? item?.CricketTournament?.name
        : item?.SportId === 10 && item?.NBATournament?.name
        ? item?.NBATournament?.name
        : item?.SportId === 15 && item?.AFLTournament?.name
        ? item?.AFLTournament?.name
        : item?.SportId === 9 && item?.ARTournament?.name
        ? item?.ARTournament?.name
        : item?.SportId === 16 && item?.GolfTournament?.name
        ? item?.GolfTournament?.name
        : item?.SportId === 7 && item?.TennisTournament?.name
        ? item?.TennisTournament?.name
        : item?.SportId === 11 && item?.BaseballTournament?.name
        ? item?.BaseballTournament?.name
        : item?.SportId === 17 && item?.IceHockeyTournament?.name
        ? item?.IceHockeyTournament?.name
        : item?.SportId === 6 && item?.BoxingTournament?.name
        ? item?.BoxingTournament?.name
        : item?.SportId === 5 && item?.MMATournament?.name
        ? item?.MMATournament?.name
        : item?.SportId === 8 && item?.SoccerTournament?.name
        ? item?.SoccerTournament?.name
        : item?.RLTournament?.name;

    let tournamentId =
      item?.SportId === 4 && item?.CricketTournamentId
        ? item?.CricketTournamentId
        : item?.SportId === 10 && item?.NBATournamentId
        ? item?.NBATournamentId
        : item?.SportId === 15 && item?.AFLTournamentId
        ? item?.AFLTournamentId
        : item?.SportId === 9 && item?.ARTournamentId
        ? item?.ARTournamentId
        : item?.SportId === 16 && item?.GolfTournamentId
        ? item?.GolfTournamentId
        : item?.SportId === 7 && item?.TennisTournamentId
        ? item?.TennisTournamentId
        : item?.SportId === 11 && item?.BaseballTournamentId
        ? item?.BaseballTournamentId
        : item?.SportId === 17 && item?.IceHockeyTournamentId
        ? item?.IceHockeyTournamentId
        : item?.SportId === 6 && item?.BoxingTournamentId
        ? item?.BoxingTournamentId
        : item?.SportId === 5 && item?.MMATournamentId
        ? item?.MMATournamentId
        : item?.SportId === 8 && item?.SoccerTournamentId
        ? item?.SoccerTournamentId
        : item?.RLTournamentId;

    return (
      <Box
        className="custom-tooltip"
        onClick={e => handleTooltipContentClick(e, teamId, index)}
      >
        <Box
          // className={showOdds ? "smartboook-icon-box" : "smartboook-icon-box"}
          className="smartboook-icon-box"
        >
          {(teamType === "hometeam"
            ? item?.isSmartBookHomeTeam
            : item?.isSmartBookAwayTeam) || item?.isSmartBookTournament ? (
            <BlackbookRight className="book-icon" />
          ) : (
            <BlackbookPlus className="book-icon" />
          )}
        </Box>

        {hoveredIndex === index && clickToolTipTeamId === teamId ? (
          tooltipOpen && (
            <Box className="tooltip">
              <TooltipArrow className="tooltip-arrow" />
              <Typography className="add-smartbook">
                <AddBookIcon /> Add To My Smartbook
              </Typography>
              <Box className="black-details">
                <Box className="tooltip-details">
                  <Typography
                    className="tooltip-txt"
                    onClick={() =>
                      handleBlackBookModal(
                        teamType === "hometeam"
                          ? item?.homeTeam?.name
                          : item?.awayTeam?.name,
                        teamType === "hometeam"
                          ? item?.homeTeam?.id
                          : item?.awayTeam?.id,
                        "Team",
                        item
                      )
                    }
                  >
                    <span>
                      {" "}
                      Team:{" "}
                      {teamType === "hometeam"
                        ? item?.homeTeam?.name
                        : item?.awayTeam?.name}
                    </span>
                    {(teamType === "hometeam" ? (
                      item?.isSmartBookHomeTeam
                    ) : (
                      item?.isSmartBookAwayTeam
                    )) ? (
                      <RightBlack />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )}
                    {/* {(teamType === "hometeam" &&
                      item?.isSmartBookAwayTeam === true) ||
                    (teamType === "awayteam" &&
                      item?.isSmartBookHomeTeam === true) ? (
                      <RightBlack className="icon-blackbook" />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )} */}
                  </Typography>
                </Box>

                <Box className="tooltip-details">
                  <Typography
                    className="tooltip-txt"
                    onClick={() =>
                      handleBlackBookModal(
                        tournamentName,
                        tournamentId,
                        "Tournament",
                        item
                      )
                    }
                  >
                    <span>
                      League: {tournamentName ? tournamentName : "N/A"}
                    </span>
                    {item?.isSmartBookTournament === true ? (
                      <RightBlack />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const fetchRLscore = (individualTeamData, teamType) => {
    const HomeTeamScores = individualTeamData?.RLScores?.filter(
      item => item?.teamId == individualTeamData?.homeTeamId
    )?.[0]?.score?.current;
    const AwayTeamScores = individualTeamData?.RLScores?.filter(
      item => item?.teamId == individualTeamData?.awayTeamId
    )?.[0]?.score?.current;
    if (teamType == "hometeam") {
      return HomeTeamScores;
    } else {
      return AwayTeamScores;
    }
  };
  return (
    <>
      <Box className="info-table-wrap h2h-wrap">
        <Tabs
          value={tabvalue}
          variant="scrollable"
          scrollButtons="off"
          indicatorColor="primary"
          textColor="primary"
          className="scorecard-tab-detail"
        >
          {tableTabData?.map((item, index) => {
            return (
              <Box key={index}>
                <Tab
                  label={
                    <Box className="d-flex align-center team-wrap">
                      <span>{item?.name}</span>
                    </Box>
                  }
                  value={item?.id}
                  className={item?.id === tabvalue ? "active " : ""}
                  onChange={(event, newValue) =>
                    handleTabChange(event, item?.id)
                  }
                />
              </Box>
            );
          })}
        </Tabs>
        {h2hLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={h2hData?.length}
            next={() => handleOnScrollBottomMatch(offset + 20)}
            hasMore={
              matchCount !== 0 && matchCount !== Math.ceil(offset / 20 + 1)
            }
            loader={
              <div className="allsport-loader-center ">
                <Loader />
              </div>
            }
          >
            <Box>
              {h2hData?.length > 0 ? (
                h2hData?.map((item, index) => {
                  return (
                    <>
                      <Box className="h2h-sport-table-wrap" key={index}>
                        <Box className="table-header-wrap">
                          <span className="start-time-date">
                            {fetchDayName(item?.startTime)}{" "}
                            {moment
                              .utc(item?.startTime)
                              .local()
                              .format("DD/MM/YYYY")}{" "}
                            |{" "}
                            {moment
                              .utc(item?.startTime)
                              .local()
                              .format("hh:mm A")}{" "}
                          </span>
                        </Box>
                        {screenWidth > 1023 ? (
                          <Table className="h2h-sport-table">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Box className="h2h-sport-table-indicator">
                                    <Box>
                                      {item?.RLTournament && (
                                        <Box className="table-indicator-wrap">
                                          <span>
                                            {item?.RLTournament?.name}
                                          </span>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                </TableCell>
                                {item?.RLScores && (
                                  <TableCell className="table-head">
                                    {item?.status == "inprogress"
                                      ? "Score"
                                      : "Result"}
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow className="teamsports-tablerow hometeam-row">
                                <TableCell className="team-wrap">
                                  <Box className="d-flex align-center team-wrap-box">
                                    <span className="team-img-wrap">
                                      {fetchTeamlogo(item, "hometeam")}
                                    </span>
                                    <span className="team-name">
                                      {item?.homeTeam?.name}
                                    </span>
                                    <ClickAwayListener
                                      onClickAway={() =>
                                        handleOutsideClick(
                                          item,
                                          item?.homeTeamId,
                                          index
                                        )
                                      }
                                    >
                                      <Box className="blackbook-icon">
                                        {handleOpenTooltip(
                                          item,
                                          item?.homeTeamId,
                                          index,
                                          "hometeam"
                                        )}
                                      </Box>
                                    </ClickAwayListener>
                                  </Box>
                                </TableCell>
                                {item?.RLScores && (
                                  <TableCell className="td-cell border-bottom">
                                    <Box className="odds-wrap-info d-flex align-center">
                                      <Box className="points">
                                        <span>
                                          {fetchRLscore(item, "hometeam")}
                                        </span>
                                      </Box>
                                      {item?.winnerCode == 3 ? (
                                        <Box className="win-loss draw">
                                          <span>D</span>
                                        </Box>
                                      ) : item?.winnerCode == 2 ? (
                                        <Box className="win-loss loss">
                                          <span>L</span>
                                        </Box>
                                      ) : item?.winnerCode == 1 ? (
                                        <Box className="win-loss win">
                                          <span>W</span>
                                        </Box>
                                      ) : null}
                                    </Box>
                                  </TableCell>
                                )}
                              </TableRow>
                              <TableRow className="awayteam-row">
                                <TableCell className="team-wrap">
                                  <Box className="d-flex align-center team-wrap-box">
                                    <span className="team-img-wrap">
                                      {fetchTeamlogo(item, "awayteam")}
                                    </span>
                                    <span className="team-name mr-6">
                                      {item?.awayTeam?.name}
                                    </span>
                                    <ClickAwayListener
                                      onClickAway={() =>
                                        handleOutsideClick(
                                          item,
                                          item?.awayTeamId,
                                          index
                                        )
                                      }
                                    >
                                      <Box className="blackbook-icon">
                                        {handleOpenTooltip(
                                          item,
                                          item?.awayTeamId,
                                          index,
                                          "awayteam"
                                        )}
                                      </Box>
                                    </ClickAwayListener>
                                  </Box>
                                </TableCell>
                                {item?.RLScores && (
                                  <TableCell className="td-cell">
                                    <Box className="odds-wrap-info d-flex align-center">
                                      <Box className="points">
                                        <span>
                                          {fetchRLscore(item, "awayteam")}
                                        </span>
                                      </Box>
                                      {item?.winnerCode == 3 ? (
                                        <Box className="win-loss draw">
                                          <span>D</span>
                                        </Box>
                                      ) : item?.winnerCode == 1 ? (
                                        <Box className="win-loss loss">
                                          <span>L</span>
                                        </Box>
                                      ) : item?.winnerCode == 2 ? (
                                        <Box className="win-loss win">
                                          <span>W</span>
                                        </Box>
                                      ) : null}
                                    </Box>
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableBody>
                          </Table>
                        ) : (
                          <Box className="mobileview-h2h-table-wrap">
                            <Table className="h2h-sport-table">
                              <TableBody>
                                <TableRow className="teamsports-tablerow hometeam-row">
                                  <TableCell className="team-wrap">
                                    <Box className="d-flex align-center team-wrap-box">
                                      <Box className="d-flex align-center">
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "hometeam")}
                                        </span>
                                        <span className="team-name">
                                          {item?.homeTeam?.name}
                                        </span>
                                      </Box>
                                      <ClickAwayListener
                                        onClickAway={() =>
                                          handleOutsideClick(
                                            item,
                                            item?.homeTeamId,
                                            index
                                          )
                                        }
                                      >
                                        <Box className="blackbook-icon">
                                          {handleOpenTooltip(
                                            item,
                                            item?.homeTeamId,
                                            index,
                                            "hometeam"
                                          )}
                                        </Box>
                                      </ClickAwayListener>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow className="awayteam-row">
                                  <TableCell className="team-wrap">
                                    <Box className="d-flex align-center team-wrap-box">
                                      <Box className="d-flex align-center">
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "awayteam")}
                                        </span>
                                        <span className="team-name mr-6">
                                          {item?.awayTeam?.name}
                                        </span>
                                      </Box>
                                      <ClickAwayListener
                                        onClickAway={() =>
                                          handleOutsideClick(
                                            item,
                                            item?.awayTeamId,
                                            index
                                          )
                                        }
                                      >
                                        <Box className="blackbook-icon">
                                          {handleOpenTooltip(
                                            item,
                                            item?.awayTeamId,
                                            index,
                                            "awayteam"
                                          )}
                                        </Box>
                                      </ClickAwayListener>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                            {item?.RLScores && (
                              <Box className="h2h-full-time-wrap">
                                <Box className="full-time-wrap">
                                  <span>
                                    {item?.status == "inprogress"
                                      ? "Score"
                                      : "Result"}
                                  </span>
                                </Box>
                                <Box className="d-flex align-center mobile-h2h-odds">
                                  <Box className="d-flex align-center h2h-odds-left">
                                    <Box className="points">
                                      <span>
                                        {fetchRLscore(item, "hometeam")}
                                      </span>
                                    </Box>
                                    {item?.winnerCode == 3 ? (
                                      <Box className="win-loss draw">
                                        <span>D</span>
                                      </Box>
                                    ) : item?.winnerCode == 2 ? (
                                      <Box className="win-loss loss">
                                        <span>L</span>
                                      </Box>
                                    ) : item?.winnerCode == 1 ? (
                                      <Box className="win-loss win">
                                        <span>W</span>
                                      </Box>
                                    ) : null}
                                  </Box>
                                  <Box className="d-flex align-center h2h-odds-right">
                                    <Box className="points">
                                      <span>
                                        {fetchRLscore(item, "awayteam")}
                                      </span>
                                    </Box>
                                    {item?.winnerCode == 3 ? (
                                      <Box className="win-loss draw">
                                        <span>D</span>
                                      </Box>
                                    ) : item?.winnerCode == 1 ? (
                                      <Box className="win-loss loss">
                                        <span>L</span>
                                      </Box>
                                    ) : item?.winnerCode == 2 ? (
                                      <Box className="win-loss win">
                                        <span>W</span>
                                      </Box>
                                    ) : null}
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        )}
                      </Box>
                    </>
                  );
                })
              ) : (
                <Box className="no-match"> No Stats Available </Box>
              )}
            </Box>
          </InfiniteScroll>
        )}
      </Box>
      <SmartBookModal
        open={SBopen}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
        dataId={selectedId}
        sportId={selectedSportId}
        listingFunction={() => fetchH2HData(0)}
      />
    </>
  );
};

export default InfoH2H;
