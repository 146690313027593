import React, { useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import banner from "src/assets/images/banner/smart-b-banner-secodary.webp";
import OddsCompSignup from "src/assets/images/signup-img/odds-comp-signup.png";
import MasterTheOdds from "src/assets/images/signup-img/master-the-odds.png";
import PlanIndicator from "src/assets/images/plan-indicator.png";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import "./socSignupPage.scss";
import { useNavigate } from "react-router";

const planData = [
  "Racing (Horse, Harness & Greyhounds) Odds Comparison for Win & Place featuring over 50 corporate bookmakers, plus Betfair and the 3 Totes.",
  "Set price watch notifications for odds on your runners or races of interest. Snipe the top early odds ahead of the rest.",
  "Filterable odds fluctuation graphs.",
  "Choose between win or place odds separately.",
  "Tailorable to your own bookmakers and race meetings/races of interest.",
  "Import personal ratings sheet prices and staking.",
  "Access race, runner, jockey, trainer, form & silks data directly.",
  "Multiple customisable layouts as per your preferences. Just set your layout and let smart odds comparison tell you your best bets."
];

const SOCSignupPage = () => {
  const navigate = useNavigate();
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleNavigate = () => {
    navigate("/subscription-payment");
  };

  return (
    <>
      <Box className="soc-signup-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="odds-comp-img-wrap">
          <img src={OddsCompSignup} alt="signup" />
        </Box>
        <Box className="odds-comp-signup-details-wrap">
          <Box className="smart-odds-comparison">
            <Typography className="smart-text fs-37">smart</Typography>
            <Typography className="odds-comp-text fs-37">
              Odds Comparison
            </Typography>
            <Box
              className="master-the-odds-wrap"
              style={{
                backgroundImage: `url('${MasterTheOdds}')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
              }}
            >
              <Typography className="master-the-odds-text">
                Master the Odds: Customizable Insights at Your Fingertips
              </Typography>
            </Box>
            <Typography className="odds-text-details">
              Experience the thrill of horse, harness, and greyhound racing like
              never before with SmartB – your ultimate companion for odds
              comparison. Navigate effortlessly through markets, analyze trends,
              and make informed bets with ease using this comprehensive and
              user-friendly tool. Don't just watch the races, immerse yourself
              in the excitement of betting smarter with SmartB.
            </Typography>
            <Button
              variant="contained"
              className="subscribe-odds-btn"
              onClick={() => handleNavigate()}
            >
              Subscribe to the Smart odds comparison
            </Button>
          </Box>
          <Box className="odds-plan-details-wrap">
            <Box className="plan-section">
              {planData?.map((item, index) => {
                return (
                  <>
                    <Box className="plan-grid">
                      <Box className="grid-icon">
                        <img src={PlanIndicator} alt="indi" />
                      </Box>
                      <Box className="plan-name">
                        <Typography className="plan-text">{item}</Typography>
                      </Box>
                    </Box>
                  </>
                );
              })}
            </Box>
            <Box className="free-trail-now-wrap">
              <Button
                variant="contained"
                className="subscribe-odds-btn free-trail-now"
                onClick={() => handleNavigate()}
              >
                Every Win Begins Here: Start your free trial now!
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SOCSignupPage;
