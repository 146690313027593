import React from "react";
import { Box, Tab, Tabs } from "@material-ui/core";

const CustomTab = ({ mapData, tabvalue, handleTabChange, mode }) => {
  return (
    <Box
      className={`custom-sports-tab ${mode === "dark" ? "dark-sport-tab" : ""}`}
    >
      <Tabs
        value={tabvalue}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        className="custom-tab-detail"
      >
        {mapData?.map((item, index) => {
          return (
            <Box key={index}>
              <Tab
                label={
                  <Box className="tab-label">
                    <span className="label-name">{item?.label}</span>
                    {/* <span className="label-icon">{item?.icon}</span> */}
                  </Box>
                }
                value={item?.value}
                className={item?.value === tabvalue ? "active " : ""}
                onChange={(event, newValue) => handleTabChange(event, item)}
              />
            </Box>
          );
        })}
      </Tabs>
    </Box>
  );
};

export default CustomTab;
