import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Breadcrumbs,
  Button,
  ClickAwayListener,
  FormControlLabel,
  Switch,
  Tooltip
} from "@material-ui/core";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import bannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import NotepadBig from "../../../assets/images/notepadBig.png";
import Facebook from "../../../assets/images/social/fb-link.svg";
import Linkedin from "../../../assets/images/social/li-link.svg";
import Twitter_X from "../../../assets/images/social/x-link.svg";
import Snapchat from "../../../assets/images/social/sc-link.svg";
import Copy from "../../../assets/images/social/copy-link.svg";
import Messenger from "../../../assets/images/social/mess-link.svg";
import Wp from "../../../assets/images/social/wp-link.svg";
import Message from "../../../assets/images/social/messages.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import AdvertisingBannner from "../advertisingBanner";
import TipsTab from "./tips";
import LadderTab from "./ladder";
import { Link, useLocation } from "react-router-dom";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import OurPartner from "../ourPartners";
import { Config, fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Share } from "../../../assets/images/icons/share.svg";
import { ReactComponent as LeftArrow } from "src/assets/images/left-arrow.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import { ReactComponent as TooltipArrow } from "src/assets/images/icons/tooltip_arrow.svg";
import { ReactComponent as InfoIcon } from "../../../assets/images/icons/info-icon.svg";
import { ReactComponent as Print } from "../../../assets/images/icons/print.svg";
import { ReactComponent as SubsBadge } from "../../../assets/images/icons/subscription-badge.svg";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";
import { ToastContainer, toast } from "react-toastify";
import DefaultImg from "src/assets/images/smartb_default.png";
import moment from "moment-timezone";
import AdBannner from "../AdBanner/AdBanner";
import "./individualCompetition.scss";
import CompDetailsTab from "./compDetails";
import TippingRightSideBar from "./tippingRightSideBar";

const newTabData = [
  {
    id: 0,
    name: "Tips"
  },
  { id: 1, name: "Ladder" },
  { id: 2, name: "Comp Details" }
];

const tippingTypeOption = {
  winning:
    "Points will be awarded based on the number of correct tips you make for each round.",
  odds:
    "The odds are locked when you select the team to win and earn points based on those odds.",
  spread: "You will get win/points based on the handicap."
};

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const IndividualCompetitionPage = () => {
  const params = useParams();
  const redux_userData = useSelector(
    state => state?.reduxData?.SubscripitionData
  );
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [tabvalue, setTabValue] = useState(0);
  const [stepperCount, setStepperCount] = useState(null);
  const [eventLength, setEventLength] = useState(0);
  const [eventByIdData, setEventByIdData] = useState({});
  const [allEventData, setAllEventData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isJoker, setIsJoker] = useState(false);
  const [jokerRound, setJockerRound] = useState(null);
  const [rounds, setRounds] = useState([]);
  const [newRoundDataOption, setNewRoundDataOption] = useState([]);
  const [newRoundOptions, setNewRoundOptions] = useState([]);
  const [noRoundData, setNoRoundData] = useState("");
  const [completedRound, setCompletedRound] = useState([]);
  const [cutoffDate, setcutoffDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [recallAPI, setRecallAPI] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [lastEventDate, setLastEventDate] = useState(null);
  const [isSelectAll, setIsSelectAll] = useState(false);
  useEffect(() => {
    if (cutoffDate && eventByIdData?.cutOffTime) {
      const time = moment
        .utc(
          `${eventByIdData?.cutOffWeek} ${eventByIdData?.cutOffTime}`,
          "dddd HH:mm:ss"
        )
        .tz(timezone)
        .format("HH:mm:ss");
      const dateTime = moment(`${cutoffDate} ${time}`, "YYYY-MM-DD HH:mm:ss");
      setLastDate(dateTime);
    }
  }, [cutoffDate, eventByIdData?.cutOffTime]);

  useEffect(() => {
    if (lastDate && lastDate > moment()) {
      const updateCurrentTime = () => {
        const currentTimePassed = lastDate < moment();
        if (currentTimePassed) {
          setRecallAPI(true);
          clearInterval(intervalId);
        }
      };

      const intervalId = setInterval(updateCurrentTime, 1000);

      return () => clearInterval(intervalId);
    }
  }, [lastDate]);

  useEffect(() => {
    if (recallAPI) {
      getEventByID(stepperCount);
      // setRecallAPI(false);
    }
  }, [recallAPI]);

  const handleTabChange = (e, id) => {
    setTabValue(id);
    if (id === 0) {
      getEventByID(stepperCount);
    }
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  useEffect(() => {
    fetchRoundData(
      Number(params?.tournamentId),
      Number(params?.SportId),
      Number(params?.compsId)
    );
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  const getEventByID = async (round, signal) => {
    const compId = Number(params?.compsId);
    // const compId = Number(params?.compId);
    setLoading(true);
    try {
      //
      const {
        status,
        data
      } = await axiosInstance.get(
        `tipping/get/${compId}?timezone=${timezone}&round=${round}`,
        { signal }
      );
      if (status === 200) {
        setEventByIdData(data?.result);
        // setStepperCount(round);
        const tipSubmitted = data?.events?.some(
          ele => ele?.roundPointId !== null
        );
        setEventLength(tipSubmitted ? 1 : 0);
        setIsJoker(round == data?.result?.joker);
        setJockerRound(data?.result?.joker);

        // if (data?.events?.length === 0) {
        //   getAllEvents(round);
        // } else {

        if (tipSubmitted && data?.result?.tippingType !== "winning") {
          const newArr = data?.events?.map(ele => {
            const allTeamOddsKey =
              ele?.SportId === 4
                ? "CricketBetOffers"
                : ele?.SportId === 10
                ? "NBABetOffers"
                : ele?.SportId === 15
                ? "AFLBetOffers"
                : ele?.SportId === 9
                ? "ARBetOffers"
                : ele?.SportId === 7
                ? "TennisBetOffers"
                : ele?.SportId === 11
                ? "BaseballBetOffers"
                : ele?.SportId === 17
                ? "IceHockeyBetOffers"
                : ele?.SportId === 6
                ? "BoxingBetOffers"
                : ele?.SportId === 5
                ? "MMABetOffers"
                : ele?.SportId === 8
                ? "SoccerBetOffers"
                : ele?.SportId === 16
                ? "GolfBetOffers"
                : "RLBetOffers";
            const allTeamOddsVal =
              ele?.SportId === 4
                ? ele?.CricketBetOffers
                : ele?.SportId === 10
                ? ele?.NBABetOffers
                : ele?.SportId === 15
                ? ele?.AFLBetOffers
                : ele?.SportId === 9
                ? ele?.ARBetOffers
                : ele?.SportId === 7
                ? ele?.TennisBetOffers
                : ele?.SportId === 11
                ? ele?.BaseballBetOffers
                : ele?.SportId === 17
                ? ele?.IceHockeyBetOffers
                : ele?.SportId === 6
                ? ele?.BoxingBetOffers
                : ele?.SportId === 5
                ? ele?.MMABetOffers
                : ele?.SportId === 8
                ? ele?.SoccerBetOffers
                : ele?.SportId === 16
                ? ele?.GolfBetOffers
                : ele?.RLBetOffers;
            return {
              ...ele,
              [allTeamOddsKey]: {
                awayOdds: {
                  ...allTeamOddsVal?.awayOdds,
                  odd:
                    ele?.awayTeam?.isTip === 1
                      ? ele?.roundData?.selectedOdd
                      : ele?.roundData?.oppositeOdd,
                  point:
                    ele?.awayTeam?.isTip === 1
                      ? ele?.roundData?.selectedPoint
                      : ele?.roundData?.oppositePoint,
                  BookKeeperId:
                    ele?.awayTeam?.isTip === 1
                      ? ele?.roundData?.selectedBookKeeperId
                      : ele?.roundData?.oppositeBookKeeperId
                },
                homeOdds: {
                  ...allTeamOddsVal?.homeOdds,
                  odd:
                    ele?.homeTeam?.isTip === 1
                      ? ele?.roundData?.selectedOdd
                      : ele?.roundData?.oppositeOdd,
                  point:
                    ele?.homeTeam?.isTip === 1
                      ? ele?.roundData?.selectedPoint
                      : ele?.roundData?.oppositePoint,
                  BookKeeperId:
                    ele?.homeTeam?.isTip === 1
                      ? ele?.roundData?.selectedBookKeeperId
                      : ele?.roundData?.oppositeBookKeeperId
                }
              }
            };
          });
          setAllEventData(newArr);
        } else {
          setAllEventData(data?.events);
        }
        setIsSelectAll(data?.result?.sameTipToAll);
        setcutoffDate(data?.result?.date);
        setLoading(false);
        setLastEventDate(
          moment
            .utc(data?.events?.[data?.events?.length - 1]?.startTime)
            .tz(timezone)
            .format("YYYY-MM-DD HH:mm:ss")
        );
        // }
      } else {
        setLoading(false);
      }
    } catch (err) {
      // setLoading(false);
      console.log(err);
    }
  };

  const getAllEvents = async round => {
    const sId = Number(params?.SportId);
    const tId = Number(params?.tournamentId);
    const compId = Number(params?.compsId);
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `tipping/events?timezone=${timezone}&tournamentId=${tId}&SportId=${sId}&startDate=${moment()
          .tz(timezone)
          .format("YYYY-MM-DD")}&round=${round}&competitionId=${compId}`
      );
      if (status === 200) {
        // setStepperCount(round);
        setLoading(false);
        setAllEventData(data?.result);
        setcutoffDate(data?.competition?.date);
        setLastEventDate(
          moment
            .utc(data?.result?.[data?.result?.length - 1]?.startTime)
            .tz(timezone)
            .format("YYYY-MM-DD HH:mm:ss")
        );
        // setRounds(round);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const fetchRoundData = async (tID, sID, cID, shareRound) => {
    const date = moment(Date()).format("YYYY-MM-DD");
    // &startDate=${date}
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/rounds?timezone=${timezone}&tournamentId=${tID}&SportId=${sID}&competitionId=${cID}`
      );
      if (status === 200) {
        let newdata = [];
        const filteredRound = data?.roundList?.filter(
          round => !data?.completedRound.includes(round?.round)
        );
        let track = filteredRound?.map(item => {
          newdata.push({
            label: item?.displayName
              ? item?.displayName
              : sID == 4
              ? `Day ${Number(item?.round)}`
              : `Round ${Number(item?.round)}`,
            value: item?.round,
            displayName: item?.displayName
          });
        });
        // data?.result &&
        //   data?.result?.length > 0 &&
        //   getEventByID(
        //     shareRound
        //       ? shareRound
        //       : data?.current
        //       ? data?.current
        //       : data?.result?.[0]
        //   );

        if (newdata?.length > 0) {
          setNewRoundOptions(newdata);
          setNoRoundData("");
        } else {
          setNoRoundData(
            "There are no rounds available for this tournament!, Try selecting a different League."
          );
        }
        setCompletedRound(data?.completedRound);
        setRounds(data?.result);
        setNewRoundDataOption(
          data?.roundList?.map(round => ({
            value: round?.round,
            displayName: round?.displayName,
            label: `${
              round?.displayName
                ? round?.displayName
                : (sID === 4 ? "Day" : "Round") + " " + round?.round
            }`
          }))
        );
        setStepperCount(
          shareRound
            ? shareRound
            : data?.current
            ? data?.current
            : data?.result?.[0]
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  // let weekDay = moment
  //   .tz(eventByIdData?.cutOffTime, "HH:mm:ss", timezone)
  //   .local()
  //   .format("dddd");
  // let time = moment
  //   .tz(eventByIdData?.cutOffTime, "HH:mm:ss", timezone)
  //   .local()
  //   .format("HH:mm:ss");
  const currentDate = new Date();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  const fetchDayandTime = () => {
    if (eventByIdData?.cutOffTime) {
      const dayWithTime = moment
        .utc(
          `${eventByIdData?.cutOffWeek} ${eventByIdData?.cutOffTime}`,
          "dddd HH:mm:ss"
        )
        .tz(timezone)
        .format("h:mm A");
      return (
        <span>
          {eventByIdData?.cutOffWeek +
            " " +
            moment(cutoffDate).format("DD/MM/YYYY") +
            " | " +
            dayWithTime}
        </span>
      );
    }
  };

  const startingEndingDate = (startDate, endDate) => {
    return (
      <span>
        :{" "}
        {moment(startDate)
          .tz(timezone)
          .format("dddd DD/MM/YYYY")}{" "}
        {"- "}
        {moment(endDate)
          .tz(timezone)
          .format("dddd DD/MM/YYYY")}
      </span>
    );
  };
  const handleTooltipContentClick = e => {
    e.stopPropagation();
    setTooltipOpen(!tooltipOpen);
  };

  const handleOutsideClick = () => {
    if (tooltipOpen) {
      setTooltipOpen(false);
    }
  };

  const handleOpenTooltip = () => {
    const link =
      Config.baseURL.replace("/api", "") +
      `tipscompetition/private-comps?code=${eventByIdData?.code}`;
    return (
      <Box
        className="comp-custom-tooltip right-sec"
        onClick={e => handleTooltipContentClick(e)}
      >
        {/* <Box> */}
        <Button className="share-btn" endIcon={<Share />}>
          Share
        </Button>
        {/* </Box> */}
        {tooltipOpen && (
          <Box className="tooltip">
            <Box style={{ position: "relative" }}>
              <TooltipArrow className="tooltip-arrow" />
            </Box>
            <Box className="share-option">
              <Box className="social-option-box">
                <Box className="option">
                  <CopyToClipboard text={link} onCopy={handleCopy}>
                    <Box className="cursor-pointer">
                      <img src={Copy} alt="Copy" />
                      <Typography className="option-name">Copy Link</Typography>
                    </Box>
                  </CopyToClipboard>
                </Box>
                <Box className="option">
                  <WhatsappShareButton url={link}>
                    <img src={Wp} alt="Whatsapp" />
                  </WhatsappShareButton>
                  <Typography className="option-name">Whatsapp</Typography>
                </Box>
                <Box className="option">
                  <FacebookShareButton url={link}>
                    <img src={Facebook} alt="Facebook" />
                  </FacebookShareButton>
                  <Typography className="option-name">Facebook</Typography>
                </Box>
                <Box className="option cursor-pointer">
                  <img
                    src={Messenger}
                    alt="Messenger"
                    className="social-icon"
                    onClick={() => shareOnMessenger(link)}
                  />
                  <Typography className="option-name">Messenger</Typography>
                </Box>
                <Box className="option">
                  <TwitterShareButton url={link}>
                    <img src={Twitter_X} alt="Twitter_X" />
                  </TwitterShareButton>
                  <Typography className="option-name">Twitter</Typography>
                </Box>
                <Box className="option">
                  <LinkedinShareButton url={link}>
                    <img src={Linkedin} alt="Linkedin" />
                  </LinkedinShareButton>
                  <Typography className="option-name">Linkedin</Typography>
                </Box>
                {/* <Box className="option">
                    <img src={Snapchat} alt="Snapchat" />
                    <Typography className="option-name">Snapchat</Typography>
                  </Box>
                  <Box className="option">
                    {" "}
                    <img src={Message} alt="Message" />
                    <Typography className="option-name">Message</Typography>
                  </Box> */}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const handleCopy = () => {
    toast.success("Link Copied Successfully", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored"
    });
  };

  const shareOnMessenger = link => {
    if (window.FB) {
      window.FB.ui({
        method: "send", // share in facebook post and send in facebook messenger
        link: link // link in facebook messenger and href in facebook post
      });
    } else {
      console.error("Facebook SDK not loaded.");
    }
  };

  const aboutLines = eventByIdData?.about?.split("\n");

  useEffect(() => {
    if (stepperCount !== null) {
      const abortController = new AbortController();
      const { signal } = abortController;

      getEventByID(stepperCount, signal);

      return () => {
        abortController.abort();
      };
    }
  }, [stepperCount]);

  const handleSelectChange = selectedOption => {
    setStepperCount(selectedOption.value);
  };

  const printLadderPDF = async () => {
    // setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/generatePdf?competitionId=${eventByIdData?.id}&round=${stepperCount}`
      );
      if (status === 200) {
        // setLoading(false);

        // Example download
        download(data?.pdfFilePath, data?.fileName);
      } else {
        // setLoading(false);
      }
    } catch (err) {
      // setLoading(false);
    }
  };

  const download = (path, filename) => {
    // Create a new link
    const anchor = document.createElement("a");
    anchor.href = path;
    anchor.download = filename;
    anchor.target = "_blank";

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);
  };

  const handleBookiesPick = () => {
    const eventList = allEventData?.map(obj => {
      let allTeamOdds =
        obj?.SportId == 4
          ? obj?.CricketBetOffers
          : obj?.SportId == 10
          ? obj?.NBABetOffers
          : obj?.SportId == 15
          ? obj?.AFLBetOffers
          : obj?.SportId == 9
          ? obj?.ARBetOffers
          : obj?.SportId == 16
          ? obj?.GolfBetOffers
          : obj?.SportId == 7
          ? obj?.TennisBetOffers
          : obj?.SportId == 11
          ? obj?.BaseballBetOffers
          : obj?.SportId == 17
          ? obj?.IceHockeyBetOffers
          : obj?.SportId == 6
          ? obj?.BoxingBetOffers
          : obj?.SportId == 5
          ? obj?.MMABetOffers
          : obj?.SportId == 8
          ? obj?.SoccerBetOffers
          : obj?.RLBetOffers;
      let HomeTeamOdds = allTeamOdds?.homeOdds?.odd ?? 0;
      let AwayTeamOdds = allTeamOdds?.awayOdds?.odd ?? 0;
      return {
        ...obj,
        awayTeam: {
          ...obj?.awayTeam,
          isTip: AwayTeamOdds < HomeTeamOdds ? 1 : 0
        },
        homeTeam: {
          ...obj?.homeTeam,
          isTip: HomeTeamOdds < AwayTeamOdds ? 1 : 0
        }
      };
    });
    setAllEventData(eventList);
  };

  const handleFavouritePick = () => {
    const eventList = allEventData?.map(obj => {
      return {
        ...obj,
        awayTeam: {
          ...obj?.awayTeam,
          isTip: obj?.awayTeam?.TipCount > obj?.homeTeam?.TipCount ? 1 : 0
        },
        homeTeam: {
          ...obj?.homeTeam,
          isTip: obj?.homeTeam?.TipCount > obj?.awayTeam?.TipCount ? 1 : 0
        }
      };
    });
    setAllEventData(eventList);
  };

  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds(
            "218px",
            Config?.release == "IN" ? bannerIndia : banner
          )}
        </Box>
        <Box className="section-wrapper">
          <Box className="in-comp-wrap">
            <Box className="in-comp-header">
              <Box className="bredcrumn-deatils">
                <Box className="bredcrumn-wrap">
                  <Breadcrumbs
                    separator="/"
                    aria-label="breadcrumb"
                    className="breadcrumb"
                  >
                    <Link underline="hover" color="inherit" to="/">
                      HOME
                    </Link>

                    <Link
                      underline="hover"
                      color="inherit"
                      to=""
                      className="cursor-default"
                    >
                      Tipping Competition
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      to="/tipscompetition/my-comps"
                    >
                      My Comp
                    </Link>

                    <Typography>
                      {eventByIdData && eventByIdData?.competitionName}
                    </Typography>
                  </Breadcrumbs>
                </Box>
              </Box>
              <Box className="comp-details-wrap">
                <Box className="title-about-wrap">
                  <Box className="header-share-wrap">
                    <Typography variant="h1">
                      {eventByIdData && eventByIdData?.competitionName}
                    </Typography>
                    {screenWidth > 799 && (
                      <Box className="comp-share-btn-wrap">
                        <ClickAwayListener
                          onClickAway={() => handleOutsideClick()}
                        >
                          <Box className="share-tooltip-icon">
                            {handleOpenTooltip()}
                          </Box>
                        </ClickAwayListener>
                      </Box>
                    )}
                    {screenWidth < 800 && (
                      <Box className="comp-img-wrap">
                        <img
                          src={
                            eventByIdData?.Media?.filePath
                              ? Config.countryMediaURL +
                                eventByIdData?.Media?.filePath
                              : DefaultImg
                          }
                          alt="comp-image"
                        />
                      </Box>
                    )}
                  </Box>
                  {eventByIdData?.about && (
                    <Box className="about-wrap">
                      <span className="about-label">About This Comp:</span>
                      <span className="about-des">
                        {/* {eventByIdData && eventByIdData?.about} */}
                        {aboutLines?.map((line, index) => (
                          <p key={index} className="about-line">
                            {line} <br />
                          </p>
                        ))}
                      </span>
                    </Box>
                  )}

                  {screenWidth < 800 && (
                    <Box className="comp-share-btn-wrap">
                      <ClickAwayListener
                        onClickAway={() => handleOutsideClick()}
                      >
                        <Box className="share-tooltip-icon">
                          {handleOpenTooltip()}
                        </Box>
                      </ClickAwayListener>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box className="tab-search-section">
              <Box className="team-sports-tab">
                <Tabs
                  value={tabvalue}
                  variant="scrollable"
                  scrollButtons="off"
                  indicatorColor="primary"
                  textColor="primary"
                  className="teamsport-tab-detail"
                >
                  {newTabData?.map((item, index) => {
                    return (
                      <Box key={index}>
                        <Tab
                          label={item?.name}
                          value={item?.id}
                          className={item?.id === tabvalue ? "active " : ""}
                          onChange={(event, newValue) =>
                            handleTabChange(event, item?.id)
                          }
                        />
                      </Box>
                    );
                  })}
                </Tabs>
              </Box>

              <Box className="stepper-score-sec">
                {(tabvalue === 0 || tabvalue === 1) && (
                  <Box className="stepper-input">
                    <Button
                      className="stepper-input__button"
                      onClick={e => {
                        const index = rounds?.indexOf(stepperCount);
                        e.preventDefault();
                        setStepperCount(rounds[index - 1]);
                        // getEventByID(rounds[index - 1]);
                      }}
                      disabled={!stepperCount || stepperCount == rounds[0]}
                      startIcon={<LeftArrow />}
                    >
                      Previous
                    </Button>

                    {/* <div className="stepper-input__content"> */}
                    {/* {eventByIdData?.SportId == 4
                        ? stepperCount
                          ? `Day ${Number(stepperCount)}`
                          : `Day 0`
                        : stepperCount
                        ? `Round ${Number(stepperCount)}`
                        : `Round 0`} */}
                    <Select
                      value={
                        newRoundDataOption?.find(
                          item => item?.value === stepperCount
                        )
                        //   {
                        //   value: stepperCount,
                        //   label:
                        //     eventByIdData?.SportId == 4
                        //       ? stepperCount
                        //         ? `Day ${Number(stepperCount)}`
                        //         : `Day 0`
                        //       : stepperCount
                        //       ? `Round ${Number(stepperCount)}`
                        //       : `Round 0`
                        // }
                      }
                      onChange={handleSelectChange}
                      options={
                        newRoundDataOption
                        //   rounds?.map(round => ({
                        //   value: round,
                        //   label: `${
                        //     eventByIdData?.SportId == 4 ? "Day" : "Round"
                        //   } ${round}`
                        // }))
                      }
                      className="React tipping-select"
                      classNamePrefix="select"
                      isSearchable={false}
                    />
                    {/* </div> */}

                    <Button
                      className="stepper-input__button Next-btn"
                      onClick={e => {
                        const index = rounds?.indexOf(stepperCount);
                        e.preventDefault();
                        setStepperCount(rounds[index + 1]);
                        // getEventByID(rounds[index + 1]);
                      }}
                      endIcon={<RightArrow />}
                      disabled={
                        stepperCount == null ||
                        stepperCount == rounds[rounds?.length - 1]
                      }
                    >
                      Next
                    </Button>
                  </Box>
                )}
                <Box className="score-share-sec">
                  <Box className="score-sec">
                    {tabvalue === 0 && (
                      <Box className="round-score-sec">
                        <Box className="round-wrap">
                          <Box className="round-score">
                            <Typography className="score">Rank:</Typography>
                            <Typography className="score semi-bold">
                              {eventByIdData?.userRank}/
                              {eventByIdData?.totalTipper}
                            </Typography>
                          </Box>
                          <Box className="round-score">
                            <Typography className="score">
                              Tips entered:
                            </Typography>
                            <Typography className="score semi-bold">
                              {eventByIdData?.tipEntered}/{allEventData?.length}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="tips-btn-wrap">
                          <Button
                            className="tips-btn"
                            onClick={() => handleBookiesPick()}
                            disabled={
                              (eventByIdData?.tippingType !== "winning" &&
                                eventLength !== 0) ||
                              (eventByIdData?.tipDeadline == "round" &&
                                lastDate < moment()) ||
                              (eventByIdData?.tipDeadline == "game" &&
                                Boolean(
                                  moment(lastEventDate).tz(timezone) <
                                    moment().tz(timezone)
                                ))
                            }
                          >
                            Bookies pick
                          </Button>
                          <Button
                            className="tips-btn"
                            disabled={
                              eventByIdData?.totalTipper === 0 ||
                              (eventByIdData?.tippingType !== "winning" &&
                                eventLength !== 0) ||
                              (eventByIdData?.tipDeadline == "round" &&
                                lastDate < moment()) ||
                              (eventByIdData?.tipDeadline == "game" &&
                                Boolean(
                                  moment(lastEventDate).tz(timezone) <
                                    moment().tz(timezone)
                                ))
                            }
                            onClick={() => handleFavouritePick()}
                          >
                            Favourites
                          </Button>
                        </Box>
                      </Box>
                    )}
                    {!Boolean(isLoading) && (
                      <Box className="tips-extra-info-wrap">
                        <Box className="extra-info-left">
                          {eventByIdData?.tippingType !== "winning" && (
                            <Box>
                              <SubsBadge className="subs-badge" />
                            </Box>
                          )}
                          <Box>
                            <Box className="tips-type-round">
                              {eventByIdData?.tippingType == "winning"
                                ? "Winner Tipping"
                                : eventByIdData?.tippingType == "odds"
                                ? "Odds Tipping"
                                : eventByIdData?.tippingType == "spread"
                                ? "SpreadLine Tipping"
                                : ""}
                              <span className="info-icon">
                                <Tooltip
                                  disableFocusListener
                                  title={
                                    <>
                                      <Box>
                                        <Box className="comp-type-wrap">
                                          <span className="comp-type">
                                            {eventByIdData?.tippingType ==
                                            "winning"
                                              ? "Winner Tipping"
                                              : eventByIdData?.tippingType ==
                                                "odds"
                                              ? "Odds Tipping"
                                              : eventByIdData?.tippingType ==
                                                "spread"
                                              ? "SpreadLine Tipping"
                                              : ""}
                                            :{" "}
                                          </span>
                                          <span className="comp-des">
                                            {
                                              tippingTypeOption[
                                                eventByIdData?.tippingType
                                              ]
                                            }
                                          </span>
                                        </Box>
                                      </Box>
                                    </>
                                  }
                                  placement="right-start"
                                  arrow={true}
                                  classes={{
                                    tooltip: "info-tooltip"
                                  }}
                                  PopperProps={{
                                    popper: "info-tooltip"
                                  }}
                                >
                                  <InfoIcon />
                                </Tooltip>
                              </span>
                            </Box>
                            <Box className="bottom-line-sec">
                              <Typography className="final-txt">
                                {eventByIdData?.SportId == 4 ? "Day" : "Round"}{" "}
                                {Number(stepperCount)}
                                {eventByIdData?.tipDeadline == "round" &&
                                tabvalue === 1 ? (
                                  startingEndingDate(
                                    eventByIdData?.startOfRound,
                                    eventByIdData?.endOfRound
                                  )
                                ) : (
                                  <>
                                    {eventByIdData &&
                                    eventByIdData?.tipDeadline == "game"
                                      ? " Game Based"
                                      : ` tipping closes at:`}
                                    {eventByIdData &&
                                      eventByIdData?.tipDeadline == "round" && (
                                        <span> {fetchDayandTime()}</span>
                                      )}
                                  </>
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        {tabvalue === 0 && (
                          <Box className="tips-switch">
                            <Box className="switch-box">
                              <FormControlLabel
                                value=""
                                control={
                                  <Switch
                                    color="primary"
                                    checked={
                                      eventByIdData?.sameTipToAll === true
                                        ? true
                                        : isSelectAll
                                    }
                                    disabled={
                                      eventByIdData?.sameTipToAll === true ||
                                      (eventByIdData?.tippingType !==
                                        "winning" &&
                                        eventLength !== 0) ||
                                      (eventByIdData?.tipDeadline == "round" &&
                                        lastDate < moment()) ||
                                      (eventByIdData?.tipDeadline == "game" &&
                                        Boolean(
                                          moment(lastEventDate).tz(timezone) <
                                            moment().tz(timezone)
                                        ))
                                    }
                                    onChange={() =>
                                      setIsSelectAll(prev => !prev)
                                    }
                                  />
                                }
                              />
                              <span className="switch-label">
                                Submit same tips for all
                                <span className="tournament-name">
                                  {" "}
                                  {eventByIdData?.tournament?.name}{" "}
                                </span>
                                comps
                              </span>
                            </Box>
                          </Box>
                        )}
                        {tabvalue === 1 &&
                          redux_userData?.id === eventByIdData?.User?.id && (
                            <Box className="print-btn-box">
                              <span
                                className="print-ladder"
                                onClick={() => {
                                  printLadderPDF();
                                }}
                              >
                                <span className="print-text">Print Ladder</span>{" "}
                                <span className="print-icon">
                                  <Print />
                                </span>
                              </span>
                            </Box>
                          )}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>

              {screenWidth > 799 && (
                <Box className="comp-img-share-wrap">
                  <Box className="comp-img-wrap">
                    <img
                      src={
                        eventByIdData?.Media?.filePath
                          ? Config.countryMediaURL +
                            eventByIdData?.Media?.filePath
                          : DefaultImg
                      }
                      alt="comp-image"
                    />
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              className={`page-deatils-wrap ${
                tabvalue === 0 || tabvalue === 2 ? "padding-container" : ""
              }`}
            >
              {tabvalue === 0 ? (
                <>
                  <TipsTab
                    allEventData={allEventData}
                    isLoading={isLoading}
                    eventLength={eventLength}
                    params={params}
                    stepperCount={stepperCount}
                    isJoker={isJoker}
                    jokerRound={jokerRound}
                    completedRound={completedRound}
                    eventByIdData={eventByIdData}
                    cutoffDate={cutoffDate}
                    getEventByID={getEventByID}
                    redux_userData={redux_userData}
                    tabvalue={tabvalue}
                    lastEventDate={lastEventDate}
                    setIsJoker={e => setIsJoker(e?.target?.checked)}
                    isSelectAll={isSelectAll}
                  />
                </>
              ) : tabvalue === 1 ? (
                <>
                  <LadderTab
                    stepperCount={stepperCount}
                    tabvalue={tabvalue}
                    redux_userData={redux_userData}
                    eventByIdData={eventByIdData}
                    compsId={params?.compsId}
                  />
                </>
              ) : (
                <>
                  <CompDetailsTab
                    eventByIdData={eventByIdData}
                    redux_userData={redux_userData}
                    roundsData={rounds}
                    newRoundOptions={newRoundOptions}
                    noRoundData={noRoundData}
                    getEventByID={getEventByID}
                    stepperCount={stepperCount}
                  />
                </>
              )}
            </Box>
          </Box>
          <Box className="comp-right-sec">
            <TippingRightSideBar
              allEventData={allEventData}
              isLoading={isLoading}
              stepperCount={stepperCount}
              eventByIdData={eventByIdData}
            />
          </Box>
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default IndividualCompetitionPage;
