import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Tab, Tabs } from "@material-ui/core";
import { useParams } from "react-router";
import { IntlContext } from "src/App";
// import { commentaryData } from "./livescoreSummary";
// import { liveData } from "./livescoreData";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { ReactComponent as RLGoal } from "../../../../../assets/images/SportIcon/blueSportIcon/RugbyLeague.svg";
import { ReactComponent as PlayerIn } from "../../../../../assets/images/SportIcon/footballIcon/FootballPlayerIn.svg";
import { ReactComponent as PlayerOut } from "../../../../../assets/images/SportIcon/footballIcon/FootballPlayerOut.svg";
import { ReactComponent as RedCard } from "../../../../../assets/images/SportIcon/footballIcon/redCard.svg";
import { ReactComponent as YellowCards } from "../../../../../assets/images/SportIcon/footballIcon/yellowCard.svg";
import NoDataComp from "src/views/component/UI/NoData";

const summaryData = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  }
];

const summaryTabData = [
  {
    id: 1,
    name: "Commentary"
  },
  {
    id: 2,
    name: "Wickets"
  }
];

const InfoSummary = ({ singleMatchData }) => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [tabvalue, setTabValue] = useState(1);
  const [wicketTabvalue, setWicketTabvalue] = useState(1);
  const [commentaryData, setCommentaryData] = useState([]);
  const [commentaryLoading, setCommentaryLoading] = useState(false);
  const [wicketsData, setWicketsData] = useState([]);
  const [wicketsLoading, setWicketsLoading] = useState(false);

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };
  const handleWicketTabChange = (event, value) => {
    setWicketTabvalue(value);
  };

  useEffect(() => {
    fetchMatchCommentary();
    // fetchMatchwickets();
  }, [params]);

  const fetchMatchCommentary = async () => {
    setCommentaryLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `sports/statistics/rl/event/Incidents/${params?.id}`
      );
      if (status === 200) {
        setCommentaryData(data?.result?.result);
        setCommentaryLoading(false);
      } else {
        setCommentaryLoading(false);
      }
    } catch (err) {
      setCommentaryLoading(false);
    }
  };

  const optimizeName = fullName => {
    if (fullName) {
      const names = fullName?.split(" ");
      const optimizedName = names?.[0]?.[0] + " " + names?.[names?.length - 1];
      return optimizedName;
    } else return "";
  };

  const getWicketsType = type => {
    if (type === 30) {
      return "Run OUT. ";
    } else if (type === 25) {
      return "Catch OUT. ";
    } else if (type === 20) {
      return "LBW OUT. ";
    } else if (type === 23) {
      return "Clean Bowled. ";
    } else if (type === 26) {
      return "Catch Out. ";
    } else if (type === 35) {
      return "Out Stumped. ";
    } else if (type === 45) {
      return "Timed OUT. ";
    } else if (type === 15) {
      return "Hit Wicket. ";
    } else {
      return "";
    }
  };

  return (
    <>
      {commentaryLoading ? (
        <div className="allsport-loader-center">
          <Loader />
        </div>
      ) : commentaryData?.RLPlayerIncidents?.length > 0 ? (
        <>
          <Box className="fixture-summary-wrap">
            {commentaryData?.RLPlayerIncidents?.map((item, index) => {
              return (
                <>
                  <Box className="summary-details" key={index}>
                    <Typography className="details-text light minute">
                      {item?.time}'
                    </Typography>
                    <Typography className="details-text dark players-wrap">
                      <Box className="player-icon-wrap">
                        <span>
                          {item?.incidentType == "goal" ? (
                            <RLGoal />
                          ) : item?.incidentType == "substitution" ? (
                            <PlayerIn />
                          ) : item?.incidentType == "card" &&
                            item?.incidentClass == "yellow" ? (
                            <YellowCards />
                          ) : item?.incidentType == "card" &&
                            item?.incidentClass == "red" ? (
                            <RedCard />
                          ) : (
                            <></>
                          )}
                        </span>{" "}
                        <span className="details-text dark">
                          {" "}
                          {item?.playerIn?.name}
                        </span>
                      </Box>
                      <Box className="player-icon-wrap">
                        <span>
                          {item?.incidentType == "substitution" ? (
                            <PlayerOut />
                          ) : (
                            <></>
                          )}
                        </span>{" "}
                        <span className="details-text dark">
                          {" "}
                          {item?.playerOut?.name}
                        </span>
                      </Box>
                    </Typography>
                    <Typography className="details-text dark score-wrap">
                      {item?.incidentType == "goal"
                        ? item?.homeScore + " - " + item?.awayScore
                        : ""}
                    </Typography>
                  </Box>
                </>
              );
            })}
          </Box>
        </>
      ) : (
        <NoDataComp />
      )}
    </>
  );
};
export default InfoSummary;
