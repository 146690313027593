import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  withStyles,
  makeStyles,
  Tooltip,
  Collapse,
  ClickAwayListener
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Config } from "../../../../../helpers/context/config";
import { IntlContext } from "src/App";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useParams } from "react-router";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { ReactComponent as RightBlack } from "../../../../../assets/images/icons/rightblack.svg";
import { ReactComponent as PlusBlack } from "../../../../../assets/images/icons/plusblack.svg";
import { ReactComponent as BlackbookRight } from "../../../../../assets/images/icons/blackbookRight.svg";
import { ReactComponent as BlackbookPlus } from "../../../../../assets/images/icons/blackbookPlus.svg";
import { ReactComponent as TooltipArrow } from "../../../../../assets/images/icons/tooltip_arrow.svg";
import { ReactComponent as AddBookIcon } from "../../../../../assets/images/add-blackbook.svg";
import { ReactComponent as SeeAllRightArrow } from "../../../../../assets/images/icons/seeall-right-arrow.svg";
import SmartBookModal from "src/views/component/UI/smartbookModal";
import DefaultImg from "src/assets/images/smartb_default.png";
import { release } from "src/helpers/context/release";

const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 98,
    minWidth: "338px",
    width: "338px",
    backgroundColor: "#ffffff",
    boxShadow: "inset -2px 0px 6px 0px #ebebeb",
    "@media(max-width: 1090px)": {
      minWidth: "300px",
      width: "300px"
    },
    "@media(max-width: 479px)": {
      minWidth: "200px",
      width: "200px"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 90,
    minWidth: "338px",
    width: "338px",
    backgroundColor: "#ffffff",
    boxShadow: "inset -2px 0px 6px 0px #ebebeb",
    "@media(max-width: 1090px)": {
      minWidth: "300px",
      width: "300px"
    },
    "@media(max-width: 479px)": {
      minWidth: "200px",
      width: "200px"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const InfoOddsComparison = ({
  pageHeadingData,
  individualTeamData,
  MarketName,
  BookkeeperData,
  fetchIndividualMarket
}) => {
  const classes = useStyles();
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const dispatch = useDispatch();
  const BetslipData = useSelector(state => state?.reduxData?.BetSlipData);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [MobileBestOpenHome, setMobileBestOpenHome] = useState(-1);
  const [MobileBestOpenAway, setMobileBestOpenAway] = useState(-1);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [clickToolTipTeamId, setClickToolTipTeamId] = useState(null);
  const [SBopen, setSBOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedSportId, setSelectedSportId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [scrollX, setscrollX] = useState("left");

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const renderTableCell = (BookKeeperId, fullData) => {
    return (
      <>
        <TableCell className={classes.head}>
          {oddsicon(BookKeeperId, "header", null, fullData)}
        </TableCell>
      </>
    );
  };

  const handleAnchorTagClick = (e, url) => {
    window.open(url, "_blank");
    e.stopPropagation();
  };

  const oddsicon = (BookKeeperId, type, item, fullData, oddsType) => {
    const Identifiers =
      params?.type === "cricket"
        ? fullData?.CricketIdentifiers
        : params?.type === "basketball"
        ? fullData?.NBAIdentifiers
        : params?.type === "americanfootball"
        ? fullData?.AFLIdentifiers
        : params?.type === "australianrules"
        ? fullData?.ARIdentifiers
        : params?.type === "golf"
        ? fullData?.GolfIdentifiers
        : params?.type === "tennis"
        ? fullData?.TennisIdentifiers
        : params?.type === "baseball"
        ? fullData?.BaseballIdentifiers
        : params?.type === "icehockey"
        ? fullData?.IceHockeyIdentifiers
        : params?.type === "boxing"
        ? fullData?.BoxingIdentifiers
        : params?.type === "mma"
        ? fullData?.MMAIdentifiers
        : params?.type === "soccer"
        ? fullData?.SoccerIdentifiers
        : fullData?.RLIdentifiers;
    const newData = Identifiers?.map(obj => {
      const BookkeeperObj = obj?.ApiProvider?.BookKeeperProviders?.filter(
        item => {
          return item?.BookKeeperId === BookKeeperId;
        }
      );
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null
      };
    });
    const filteredData = newData
      ?.filter?.(obj => obj?.BookKeeperId === BookKeeperId)
      ?.filter(obj => obj?.ApiProviderId !== 17);

    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    let imageUrl = iconData?.long_logo;
    if (BookKeeperId === 8) {
      const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.filter(
        ele => ele?.providerMarketId && ele?.providerParticipantId
      );
      const urlLink =
        Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
          ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
          : `${iconData?.affiliate_link}`;
      return (
        <Box className="current-best-odds-icon">
          <Box className="odd-img">
            <img
              src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
              onClick={e => {
                handleBookkeeperCounter(BookKeeperId, type, e);
                handleAnchorTagClick(e, urlLink);
              }}
              alt="Odds Icon"
            />
          </Box>
        </Box>
      );
    } else {
      const urlLink =
        filteredData?.length > 0 && filteredData?.[0]?.url
          ? filteredData?.[0]?.url + `?Referrer=SmartB`
          : iconData?.affiliate_link;
      return (
        <Box
          // href={
          //   filteredData?.length > 0 && filteredData?.[0]?.url
          //     ? filteredData?.[0]?.url + `?Referrer=SmartB`
          //     : iconData?.affiliate_link
          // }
          // target="_blank"
          // rel="noopener noreferrer"
          className="current-best-odds-icon"
        >
          <Box className="odd-img">
            <img
              src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
              onClick={e => {
                handleBookkeeperCounter(BookKeeperId, type, e);
                handleAnchorTagClick(e, urlLink);
              }}
              alt="Odds Icon"
            />
          </Box>
        </Box>
      );
    }
  };

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Brisbane} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Brisbane} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Melbourne} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Melbourne} alt="Odds Icon" />;
      }
    }
  };

  const fetchSeeAllTeamOddsvalue = (data, ProviderId, team, type) => {
    let allTeamOdds = data?.SoccerBetOffers?.[0]?.SoccerOdds;
    let HomeTeamOdds = allTeamOdds?.filter(item => {
      return item?.SoccerTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter(item => {
      return item?.SoccerTeamId == data?.awayTeamId;
    });
    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;
    let teamData = MarketName?.toLowerCase()?.includes("total")
      ? allTeamOdds
      : teamInfo;

    if (MarketName?.toLowerCase()?.includes("total")) {
      let OverData = allTeamOdds?.filter(ele => {
        let LabelName = ele?.SoccerOddLabel?.displayName
          ? ele?.SoccerOddLabel?.displayName
          : ele?.SoccerOddLabel?.name;
        let name = LabelName ? LabelName : ele?.label;
        return (
          name?.toLowerCase()?.includes("over") ||
          name?.toLowerCase()?.includes("totalover")
        );
      });
      let UnderData = allTeamOdds?.filter(ele => {
        let LabelName = ele?.SoccerOddLabel?.displayName
          ? ele?.SoccerOddLabel?.displayName
          : ele?.SoccerOddLabel?.name;
        let name = LabelName ? LabelName : ele?.label;
        return (
          name?.toLowerCase()?.includes("under") ||
          name?.toLowerCase()?.includes("totalunder")
        );
      });
      teamData = team === "over" ? OverData : UnderData;
    }
    if (type === "odds") {
      if (teamData) {
        let odds = teamData
          ?.map(obj => {
            if (obj?.BookKeeperId === ProviderId) {
              return { odds: obj?.odd, bookkeeper: obj?.BookKeeperId };
            }
          })
          ?.filter(x => x !== undefined);
        if (odds?.length > 0) {
          return fetchClickableOdds(
            odds?.[0]?.odds,
            odds?.[0]?.bookkeeper,
            "header",
            teamData,
            data,
            "betslip"
          );
          // return odds?.[0]?.odds;
        } else {
          return (
            <Tooltip
              title="No odds available"
              className="odds-tooltip"
              placement="top"
            >
              <span className="odds-btn">
                <span className="no-odds">NOA</span>
              </span>
            </Tooltip>
          );
        }
      } else {
        return (
          <Tooltip
            title="No odds available"
            className="odds-tooltip"
            placement="top"
          >
            <span className="odds-btn">
              <span className="no-odds">NOA</span>
            </span>
          </Tooltip>
        );
      }
    } else if (type === "spreadpoints") {
      if (teamData) {
        let odds = teamData
          ?.map(obj => {
            if (obj?.BookKeeperId === ProviderId) {
              let LabelName = obj?.SoccerOddLabel?.displayName
                ? obj?.SoccerOddLabel?.displayName
                : obj?.SoccerOddLabel?.name;
              let name = LabelName ? LabelName : obj?.label;
              return obj?.point
                ? MarketName?.toLowerCase()?.includes("total")
                  ? name + " " + obj?.point
                  : obj?.point
                : "";
            }
          })
          ?.filter(x => x !== undefined);
        if (odds?.length > 0) {
          return odds?.[0];
        } else {
        }
      } else {
      }
    }
  };

  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    Isscore
  ) => {
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    const oddsInfo = BookKeeperData?.[0];
    let isAdded = BetslipData?.some(el => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      )
        return true;
      else return false;
    });

    return (
      <>
        {odds || odds == 0 ? (
          <Box className="odds-box-style">
            <Box
              className={`odds-style  ${isAdded ? "active-odd-style" : ""}`}
              onClick={e => {
                IsBetslip === "nobetslip"
                  ? handleBookkeeperCounter(BookKeeperId, type, e)
                  : isAdded
                  ? handleDeleteBetslip(BookKeeperId, item, fulldata, e)
                  : handleAddToBetSlip(
                      BookKeeperId,
                      type,
                      item,
                      fulldata,
                      Isscore,
                      e
                    );
              }}
            >
              {IsBetslip === "betslip" && !Isscore ? (
                <Tooltip title="Bet" className="bet-tooltip" placement="top">
                  <Typography className="odds bet-now">
                    {" "}
                    {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
                  </Typography>
                </Tooltip>
              ) : (
                <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
              )}
            </Box>
          </Box>
        ) : null}
      </>
    );
  };

  const handleBookkeeperCounter = async (BookKeeperId, type) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: 8
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleDeleteBetslip = async (BookKeeperId, item, fulldata) => {
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    const oddsInfo = BookKeeperData?.[0];
    let delslip = BetslipData?.filter(el => {
      return (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      );
    })?.[0];

    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        let chageData = BetslipData?.filter(obj => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };

  const handleAddToBetSlip = async (
    BookKeeperId,
    type,
    item,
    fulldata,
    Isscore
  ) => {
    handleBookkeeperCounter(BookKeeperId, type);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    if (isLogin) {
      if (!Isscore) {
        const BookKeeperData = item?.filter(
          obj => obj?.BookKeeperId === BookKeeperId
        );
        const oddsInfo = BookKeeperData?.[0];

        let passTeamId = oddsInfo?.SoccerTeamId
          ? oddsInfo?.SoccerTeamId
          : oddsInfo?.SoccerOddLabelId
          ? oddsInfo?.SoccerOddLabelId
          : oddsInfo?.SoccerPlayerId;
        let payload = {
          sportId: fulldata?.SportId,
          eventId: fulldata?.id,
          teamId: passTeamId,
          bookKeeperId: BookKeeperId,
          betOfferId: oddsInfo?.id
        };
        try {
          const { status, data } = await axiosInstance.post(
            `betSlipCard/BetSlipCard`,
            payload
          );
          if (status === 200) {
            toast.success(data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored"
            });

            const addedData = [...BetslipData, data?.result];
            dispatch(fetchBetSlipData(addedData));
            dispatch(fetchBetSlipCount(addedData?.length));
          } else {
          }
        } catch (err) {
          console.log("error", err);
        }
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };
  const SlideTable = direction => {
    setscrollX(direction);
    var container = document.getElementById("individual-team-table");
    var scrollCompleted = 0;
    var slideVar = setInterval(function() {
      if (direction == "left") {
        container.scrollLeft -= 10;
      } else {
        container.scrollLeft += 10;
      }
      scrollCompleted += 10;
      if (scrollCompleted >= 600) {
        window.clearInterval(slideVar);
      }
    }, 10);
  };
  const handleModal = () => {
    setSBOpen(true);
  };

  const handleClose = () => {
    setSBOpen(false);
  };
  const handleBlackBookModal = (title, id, type, item) => {
    setSelectedTitle(title);
    setSelectedType(type);
    setSelectedId(id);
    setSelectedSportId(item?.SportId);
    handleModal();
  };

  const handleTooltipContentClick = (event, teamId, objindex) => {
    event.stopPropagation();
    setTooltipOpen(!tooltipOpen);
    setHoveredIndex(objindex);
    setClickToolTipTeamId(teamId);
  };
  const handleOutsideClick = (item, teamId, index) => {
    if (hoveredIndex === index && clickToolTipTeamId === teamId) {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    }
  };

  const handleOpenTooltip = (item, teamId, index, teamType, score) => {
    let tournamentName =
      item?.SportId === 4 && item?.CricketTournament?.name
        ? item?.CricketTournament?.name
        : item?.SportId === 10 && item?.NBATournament?.name
        ? item?.NBATournament?.name
        : item?.SportId === 15 && item?.AFLTournament?.name
        ? item?.AFLTournament?.name
        : item?.SportId === 9 && item?.ARTournament?.name
        ? item?.ARTournament?.name
        : item?.SportId === 16 && item?.GolfTournament?.name
        ? item?.GolfTournament?.name
        : item?.SportId === 7 && item?.TennisTournament?.name
        ? item?.TennisTournament?.name
        : item?.SportId === 11 && item?.BaseballTournament?.name
        ? item?.BaseballTournament?.name
        : item?.SportId === 17 && item?.IceHockeyTournament?.name
        ? item?.IceHockeyTournament?.name
        : item?.SportId === 6 && item?.BoxingTournament?.name
        ? item?.BoxingTournament?.name
        : item?.SportId === 5 && item?.MMATournament?.name
        ? item?.MMATournament?.name
        : item?.SportId === 8 && item?.SoccerTournament?.name
        ? item?.SoccerTournament?.name
        : item?.RLTournament?.name;

    let tournamentId =
      item?.SportId === 4 && item?.CricketTournamentId
        ? item?.CricketTournamentId
        : item?.SportId === 10 && item?.NBATournamentId
        ? item?.NBATournamentId
        : item?.SportId === 15 && item?.AFLTournamentId
        ? item?.AFLTournamentId
        : item?.SportId === 9 && item?.ARTournamentId
        ? item?.ARTournamentId
        : item?.SportId === 16 && item?.GolfTournamentId
        ? item?.GolfTournamentId
        : item?.SportId === 7 && item?.TennisTournamentId
        ? item?.TennisTournamentId
        : item?.SportId === 11 && item?.BaseballTournamentId
        ? item?.BaseballTournamentId
        : item?.SportId === 17 && item?.IceHockeyTournamentId
        ? item?.IceHockeyTournamentId
        : item?.SportId === 6 && item?.BoxingTournamentId
        ? item?.BoxingTournamentId
        : item?.SportId === 5 && item?.MMATournamentId
        ? item?.MMATournamentId
        : item?.SportId === 8 && item?.SoccerTournamentId
        ? item?.SoccerTournamentId
        : item?.RLTournamentId;

    return (
      <Box
        className="custom-tooltip"
        onClick={e => handleTooltipContentClick(e, teamId, index)}
      >
        <Box
          // className={showOdds ? "smartboook-icon-box" : "smartboook-icon-box"}
          className="smartboook-icon-box"
        >
          {(teamType === "hometeam"
            ? item?.isSmartBookHomeTeam
            : item?.isSmartBookAwayTeam) || item?.isSmartBookTournament ? (
            <BlackbookRight className="book-icon" />
          ) : (
            <BlackbookPlus className="book-icon" />
          )}
        </Box>

        {hoveredIndex === index && clickToolTipTeamId === teamId ? (
          tooltipOpen && (
            <Box className="tooltip">
              <TooltipArrow className="tooltip-arrow" />
              <Typography className="add-smartbook">
                <AddBookIcon /> Add To My Smartbook
              </Typography>
              <Box className="black-details">
                <Box className="tooltip-details">
                  <Typography
                    className="tooltip-txt"
                    onClick={() =>
                      handleBlackBookModal(
                        teamType === "hometeam"
                          ? item?.homeTeam?.name
                          : item?.awayTeam?.name,
                        teamType === "hometeam"
                          ? item?.homeTeam?.id
                          : item?.awayTeam?.id,
                        "Team",
                        item
                      )
                    }
                  >
                    <span>
                      {" "}
                      Team:{" "}
                      {teamType === "hometeam"
                        ? item?.homeTeam?.name
                        : item?.awayTeam?.name}
                    </span>
                    {(teamType === "hometeam" ? (
                      item?.isSmartBookHomeTeam
                    ) : (
                      item?.isSmartBookAwayTeam
                    )) ? (
                      <RightBlack />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )}
                    {/* {(teamType === "hometeam" &&
                      item?.isSmartBookAwayTeam === true) ||
                    (teamType === "awayteam" &&
                      item?.isSmartBookHomeTeam === true) ? (
                      <RightBlack className="icon-blackbook" />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )} */}
                  </Typography>
                </Box>

                <Box className="tooltip-details">
                  <Typography
                    className="tooltip-txt"
                    onClick={() =>
                      handleBlackBookModal(
                        tournamentName,
                        tournamentId,
                        "Tournament",
                        item
                      )
                    }
                  >
                    <span>
                      League: {tournamentName ? tournamentName : "N/A"}
                    </span>
                    {item?.isSmartBookTournament === true ? (
                      <RightBlack />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  };
  return (
    <>
      <Box className="teamsports-seeall-tablewrap">
        <Box className="table-header-wrap">
          <Box className="table-indicator-wrap">
            <Box className="team-sport-table-indicator">
              <Box>
                <span className="start-time-date">
                  {fetchDayName(individualTeamData?.startTime)}{" "}
                  {moment
                    .utc(individualTeamData?.startTime)
                    .local()
                    .format("DD/MM/YYYY")}{" "}
                  |{" "}
                  {moment
                    .utc(individualTeamData?.startTime)
                    .local()
                    .format("hh:mm A")}{" "}
                  |
                </span>
              </Box>
            </Box>

            {release[Config.release]?.oddsCheck ? (
              <span className="markets">
                {" "}
                {`${
                  individualTeamData?.marketLength
                    ? individualTeamData?.marketLength
                    : 0
                } Markets`}
              </span>
            ) : (
              <></>
            )}
          </Box>
          {screenWidth > 799 && (
            <Box className="currentbest-seeall">
              <Box className="seeall-arrow-wrap">
                <span
                  className={`seeall-arrow left-arrow ${
                    scrollX == "right" ? "" : "disable"
                  }`}
                  onClick={() => SlideTable("left")}
                >
                  <SeeAllRightArrow />
                </span>

                <span
                  className={`seeall-arrow right-arrow ${
                    scrollX == "left" ? "" : "disable"
                  }`}
                  onClick={() => SlideTable("right")}
                >
                  <SeeAllRightArrow />
                </span>
              </Box>
            </Box>
          )}
        </Box>
        <Box className="responsive-table-wrap">
          <TableContainer
            className="table-seeall-container"
            id="individual-team-table"
          >
            <Table className="individual-table">
              <TableHead className="individual-team-head">
                <TableRow>
                  <StickyTableCell
                    className={`${classes.head} ${
                      tooltipOpen ? "bg-transparent" : ""
                    }`}
                  >
                    {screenWidth > 799 && (
                      <Box className="mobile-teamsport-left">
                        {((individualTeamData?.SportId === 4 ||
                          individualTeamData?.SportId === 8) &&
                          individualTeamData?.ScoreBoard?.Epr === 1) ||
                        (individualTeamData?.SoccerScores?.length > 0 &&
                          individualTeamData?.status &&
                          individualTeamData?.status === "inprogress") ? (
                          <span className="live-indicator">LIVE</span>
                        ) : (
                          <></>
                        )}
                        <span className="sport-indicator-btn">
                          {individualTeamData?.SoccerTournament?.name}
                        </span>
                      </Box>
                    )}
                  </StickyTableCell>
                  {pageHeadingData?.map(id =>
                    renderTableCell(id?.BookKeeperId, individualTeamData)
                  )}
                </TableRow>
              </TableHead>
              {individualTeamData ? (
                <TableBody className="individual-team-body">
                  {/* <TableRow className="individual-team-row">
                    <StickyTableCell  className={`${classes.cell} body-runner-name`}>
                    <Box className="team-wrap">
                                  <span className="team-img-wrap">
                                    {fetchTeamlogo(
                                      individualTeamData,
                                      "hometeam"
                                    )}
                                  </span>
                                  <span className="team-name">
                                    {" "}
                                    {individualTeamData?.homeTeam?.name
                                      ? individualTeamData?.homeTeam?.name
                                      : ""}
                                  </span>
                                  <ClickAwayListener
                                    onClickAway={() =>
                                      handleOutsideClick(
                                        individualTeamData,
                                        individualTeamData?.homeTeamId,
                                        individualTeamData?.id
                                      )
                                    }
                                  >
                                    <Box className="blackbook-icon">
                                      {handleOpenTooltip(
                                        individualTeamData,
                                        individualTeamData?.homeTeamId,
                                        individualTeamData?.id,
                                        "hometeam",
                                        ""
                                      )}
                                    </Box>
                                  </ClickAwayListener>
                                </Box>
                    </StickyTableCell>
                  </TableRow> */}
                  {/* {!isMultipleTeam ? (
                    <> */}
                  <TableRow className="individual-team-row">
                    <StickyTableCell
                      className={`${classes.cell} body-runner-name ${
                        tooltipOpen ? "bg-transparent" : ""
                      }`}
                    >
                      <Box className="team-wrap">
                        <span className="team-img-wrap">
                          {fetchTeamlogo(individualTeamData, "hometeam")}
                        </span>
                        <span className="team-name">
                          {" "}
                          {individualTeamData?.homeTeam?.name
                            ? individualTeamData?.homeTeam?.name
                            : ""}
                        </span>
                        <ClickAwayListener
                          onClickAway={() =>
                            handleOutsideClick(
                              individualTeamData,
                              individualTeamData?.homeTeamId,
                              individualTeamData?.id
                            )
                          }
                        >
                          <Box className="blackbook-icon">
                            {handleOpenTooltip(
                              individualTeamData,
                              individualTeamData?.homeTeamId,
                              individualTeamData?.id,
                              "hometeam",
                              ""
                            )}
                          </Box>
                        </ClickAwayListener>
                      </Box>
                    </StickyTableCell>
                    {pageHeadingData?.length > 0 ? (
                      pageHeadingData?.map((id, index) => (
                        <TableCell
                          className={`${classes.cell} individual-tablecell`}
                          key={index}
                        >
                          <Box className="odds-point">
                            {fetchSeeAllTeamOddsvalue(
                              individualTeamData,
                              id?.BookKeeperId,
                              MarketName?.toLowerCase()?.includes("total")
                                ? "over"
                                : "hometeam",
                              "spreadpoints"
                            )}
                          </Box>
                          <span className="odds">
                            {fetchSeeAllTeamOddsvalue(
                              individualTeamData,
                              id?.BookKeeperId,
                              MarketName?.toLowerCase()?.includes("total")
                                ? "over"
                                : "hometeam",
                              "odds"
                            )}
                          </span>
                        </TableCell>
                      ))
                    ) : (
                      <TableCell
                        className={`${classes.cell} individual-tablecell`}
                      >
                        <span className="odds">
                          <span className="odds-btn">NOA</span>
                        </span>
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow className="individual-team-row">
                    <StickyTableCell
                      className={`${classes.cell} body-runner-name ${
                        tooltipOpen ? "bg-transparent" : ""
                      }`}
                    >
                      <Box className="team-wrap">
                        <span className="team-img-wrap">
                          {fetchTeamlogo(individualTeamData, "awayteam")}
                        </span>
                        <span className="team-name">
                          {" "}
                          {individualTeamData?.awayTeam?.name
                            ? individualTeamData?.awayTeam?.name
                            : ""}
                        </span>
                        <ClickAwayListener
                          onClickAway={() =>
                            handleOutsideClick(
                              individualTeamData,
                              individualTeamData?.awayTeamId,
                              individualTeamData?.id
                            )
                          }
                        >
                          <Box className="blackbook-icon">
                            {handleOpenTooltip(
                              individualTeamData,
                              individualTeamData?.awayTeamId,
                              individualTeamData?.id,
                              "awayteam",
                              ""
                            )}
                          </Box>
                        </ClickAwayListener>
                      </Box>
                    </StickyTableCell>
                    {pageHeadingData?.length > 0 ? (
                      pageHeadingData?.map((id, index) => (
                        <TableCell
                          className={`${classes.cell} individual-tablecell`}
                          key={index}
                        >
                          <Box className="odds-point">
                            {fetchSeeAllTeamOddsvalue(
                              individualTeamData,
                              id?.BookKeeperId,
                              MarketName?.toLowerCase()?.includes("total")
                                ? "under"
                                : "awayteam",
                              "spreadpoints"
                            )}
                          </Box>
                          <span className="odds">
                            {fetchSeeAllTeamOddsvalue(
                              individualTeamData,
                              id?.BookKeeperId,
                              MarketName?.toLowerCase()?.includes("total")
                                ? "under"
                                : "awayteam",
                              "odds"
                            )}
                          </span>
                        </TableCell>
                      ))
                    ) : (
                      <TableCell
                        className={`${classes.cell} individual-tablecell`}
                      >
                        <span className="odds">
                          <span className="odds-btn">NOA</span>
                        </span>
                      </TableCell>
                    )}
                  </TableRow>
                  {/* </>
                  ) : (
                    fetchSeeAllMultipleTeamData(individualTeamData)
                  )} */}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={100}>
                      <Box style={{ textAlign: "center" }}>
                        {" "}
                        No Data Available
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <SmartBookModal
        open={SBopen}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
        dataId={selectedId}
        sportId={selectedSportId}
        listingFunction={() => fetchIndividualMarket()}
      />
    </>
  );
};

export default InfoOddsComparison;
