import React, { useState } from "react";
import {
  Box,
  Typography,
  TableRow,
  TableHead,
  Table,
  Button,
  TableCell,
  TableBody
} from "@material-ui/core";
import { ReactComponent as PreviousIcon } from "src/assets/images/eventcalendar/previous.svg";
import { ReactComponent as NextIcon } from "src/assets/images/eventcalendar/next.svg";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "src/components/Loader";
import moment from "moment-timezone";
import NoDataComp from "../../UI/NoData";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const EventDay = ({
  eventData,
  eventListData,
  limit,
  currentPage,
  setCurrentPage,
  eventLoader,
  fetchEventData,
  selectedTeams,
  selectedVenues,
  selectedOrg,
  selectedSports,
  searchValue,
  selectedStartDateDay,
  setselectedStartDateDay
}) => {
  const navigate = useNavigate();
  const params = useParams();
  // const [currentPage, setCurrentPage] = useState(1);
  // const [tabledata, setTabledata] = useState([
  //   {},
  //   {},
  //   {},
  //   {},
  //   {},
  //   {},
  //   {},
  //   {},
  //   {},
  //   {}
  // ]);

  const handlePaginationClick = (event, page) => {
    setCurrentPage(Number(page) - 1);
    fetchEventData(
      Number(page) - 1,
      searchValue,
      selectedTeams,
      selectedVenues,
      selectedOrg,
      selectedStartDateDay,
      "",
      selectedSports
    );
    // setoffset((Number(page) - 1) * rowPerPage);
    // fetchNewsCategoryData(
    //   params?.categoryId === "100" ? 0 : params?.categoryId,
    //   (Number(page) - 1) * rowPerPage,
    //   searchValue
    // );
  };

  const previousDay = () => {
    var preDay = moment(selectedStartDateDay)
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    setselectedStartDateDay(moment(preDay).format("YYYY-MM-DD"));
    fetchEventData(
      currentPage,
      searchValue,
      selectedTeams,
      selectedVenues,
      selectedOrg,
      preDay,
      "",
      selectedSports
    );
  };
  const nextDay = () => {
    var nextDay = moment(selectedStartDateDay)
      .add(1, "days")
      .format("YYYY-MM-DD");
    setselectedStartDateDay(
      moment(nextDay)
        .tz(timezone)
        .format("YYYY-MM-DD")
    );
    fetchEventData(
      currentPage,
      searchValue,
      selectedTeams,
      selectedVenues,
      selectedOrg,
      nextDay,
      "",
      selectedSports
    );
  };
  return (
    <Box className="event-date-time">
      <Box className="event-date-header">
        <Typography className="date-text">
          {selectedStartDateDay !== "Invalid date" &&
          selectedStartDateDay !== null
            ? moment(selectedStartDateDay).format("DD/MM/YYYY")
            : ""}
        </Typography>
        <Box className="button-section">
          <Button
            startIcon={<PreviousIcon />}
            className="previous-button"
            onClick={previousDay}
          >
            Previous Day
          </Button>
          <Button
            endIcon={<NextIcon />}
            className="next-button"
            onClick={nextDay}
          >
            Next Day
          </Button>
        </Box>
      </Box>
      {eventLoader ? (
        <Box style={{ textAlign: "center" }}>
          <Loader />
        </Box>
      ) : eventData && eventData.length > 0 ? (
        <>
          <Box className="event-calendar-table-design">
            <Table aria-label="simple table" className="event-table">
              <TableHead className="event-table-head">
                <TableRow className="event-table-row">
                  <TableCell className="event-table-cell">Event</TableCell>
                  <TableCell className="event-table-cell">time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="event-table-body">
                {eventData &&
                  eventData.length > 0 &&
                  eventData.map(item => {
                    return (
                      <>
                        {/* <TableRow className="event-table-row">
                    <TableCell className="event-table-cell">
                      <Typography className="cell-text">
                        Brisbane Lions V/s{" "}
                      </Typography>
                      <Typography className="cell-text">Melbourne</Typography>
                      <Typography className="cell-text">
                        Venue - Sunshine Coast Stadium
                      </Typography>
                    </TableCell>
                    <TableCell className="event-table-cell">
                      <Typography className="cell-text">
                        07:50 PM - 10:00 PM
                      </Typography>
                    </TableCell>
                  </TableRow> */}
                        <TableRow className="event-table-row">
                          <TableCell className="event-table-cell">
                            <Typography
                              className="cell-text cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `/${params?.sportType}/event-calendar/details/${item?.eventId}/${item?.sportId}/${item?.tournamentId}`
                                )
                              }
                            >
                              {item?.eventName}
                            </Typography>
                            {item?.venues ? (
                              <Typography className="cell-text">
                                {"Venue -" + item?.venues}
                              </Typography>
                            ) : (
                              <Typography className="cell-text"></Typography>
                            )}
                          </TableCell>
                          <TableCell className="event-table-cell">
                            {item?.eventDate && (
                              <Typography className="cell-text">
                                {moment(item?.eventDate)
                                  .tz(timezone)
                                  .format("h:mm A")}
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
          <Box className="news-Pagination">
            <Pagination
              // hideNextButton
              // hidePrevButton
              // disabled={categoryCount / rowPerPage > 1 ? false : true}
              // count={pageNumbers[pageNumbers?.length - 1]}
              // count={20}
              disabled={
                eventListData && eventListData?.count / limit > 1 ? false : true
              }
              page={currentPage + 1}
              onChange={(e, value) => handlePaginationClick(e, value)}
              count={
                eventListData &&
                eventListData?.count &&
                Math.ceil(eventListData?.count / limit)
              }
              siblingCount={2}
              boundaryCount={1}
              // count={10}
              size="small"
            />
          </Box>
        </>
      ) : (
        <>
          <Box style={{ textAlign: "center" }}>
            {" "}
            <Typography className="no-data-available">
              <NoDataComp />
            </Typography>{" "}
          </Box>
        </>
      )}
    </Box>
  );
};

export default EventDay;
